import { UpperCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertCreditDebitTable',
})
export class ConvertCreditDebitTablePipe implements PipeTransform {
  transform(el: any, account_name: any) {
    
    const upper = new UpperCasePipe();

    let result =
      // Transações que devem entrar como Crédito na conta
      el?.transactionType == 'CASH_IN_PIX'
        ? 'credit'
        : el?.transactionType == 'CASH_IN_TED'
        ? 'credit'
        : el?.transactionType == 'CASH_IN_REFUND_TED'
        ? 'credit'
        : el?.transactionType == 'CASH_IN_SLIP'
        ? 'credit'
        : el?.transactionType == 'PIX_ROLLBACK'
        ? 'credit'
        : el?.transactionType == 'CASH_IN_REFUND_PIX'
        ? 'credit'
        : // Tipo de transações que devem entrar como Débito na conta
        el?.transactionType == 'INTERNAL'
        ? 'debit'
        : el?.transactionType == 'PIX'
        ? 'debit'
        : el?.transactionType == 'TED' && 'debit';

    if (el?.transactionType == 'INTERNAL_PIX') {
      // Se o campo transactions.transactionUuid.sourceName for igual ao account.name, essa transação vai ser um débito
      // Se o campo transactions.transactionUuid.sourceName for diferente do account.name, essa transação vai ser um débito
      if (upper.transform(el.sourceName).trim() == upper.transform(account_name).trim()) {
        result = 'debit';
      } else {
        result = 'credit';
      }
    }

    return result;
  }
}

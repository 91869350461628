import { Component } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';

@Component({
  selector: 'modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.scss']
})

export class ModalMessageComponent {

  constructor(public modalRef: MDBModalRef) { }

  action: Subject<any> = new Subject();
  text: string = "";
  title: string = "";
  modeScreen: any;

  type = 'text'

  close() {
    this.action.next('no')
    this.modalRef.hide();
  }
}

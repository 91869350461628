import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MDBModalRef } from 'angular-bootstrap-md';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service'
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  constructor(
    private storage: StorageService,
    private modalRef: MDBModalRef,
    private userService: UserService

  ) { }

  action: Subject<any> = new Subject();
  title: string = "";
  text: string = "";
  button: string = "";
  modeScreen: any;
  user: User = {} as User;

  ngOnInit(): void {
    this.storage.watchTheme().subscribe((data) => {
      this.modeScreen = this.storage.checkTheme();
    });
    this.modeScreen = this.storage.checkTheme();

  }
  close() {
    this.modalRef.hide();
    this.action.next('no');
  }
  next() {
    this.modalRef.hide();
    this.action.next('yes');
  }


  editUser() {
    this.userService.editUser(this.user, this.user.company.id).subscribe((data) => {
      this.next();
      this.user = data;
    })
  }

}

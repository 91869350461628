<main [ngClass]="{'darkMode': modeScreen.theme == 'dark', 'lightMode': modeScreen.theme == 'light' }">
  <span class="text" [style.color]="modeScreen.color">Olá, {{ user.name && user.name }} </span>
  <p class="sub_text" [style.color]="modeScreen.color" [style.color]="modeScreen.color">Admin</p>

  <div class="line_initial">
    <div class="card_title">
      <h2 class="font25" [style.color]="modeScreen.color">Dashboard Employer</h2>
    </div>

    <button mat-fab class="btn_refresh" aria-label="Botão para atualizar" (click)="downloadDashboardQuantityPaymnt()" matTooltip="Download">
      <mat-icon [style.color]="modeScreen.fill" style="cursor: pointer;">launch</mat-icon>  
      <span *ngIf="loading_refresh" class="spinner-border spinner-border-gg" role="status" aria-hidden="true">
      </span>
    </button>

    <a download="file.xlsx" target="_blank" href="" style="display: none;" #downloadLink id="downloadLink"></a>

    <button mat-fab class="btn_refresh" aria-label="Botão para atualizar" (click)="getDashboard(page_atual)"
      matTooltip="Recarregar">
      <svg *ngIf="!loading_refresh" height="30" width="30" viewBox="0 0 48 48">
        <path [style.fill]="modeScreen.color2"
          d="M23.85 41.25q-7.15 0-12.2-5.05Q6.6 31.15 6.6 24q0-7.15 5.05-12.225Q16.7 6.7 23.85 6.7q4.55 0 8.025 1.825 3.475 1.825 5.975 5.125V6.7h3.6v14.55h-14.6V17.7h8.2q-1.95-2.8-4.7-4.525-2.75-1.725-6.5-1.725-5.25 0-8.9 3.65-3.65 3.65-3.65 8.9t3.65 8.9q3.65 3.65 8.9 3.65 3.9 0 7.225-2.25 3.325-2.25 4.725-5.95h4.85q-1.5 5.75-6.175 9.325Q29.8 41.25 23.85 41.25Z" />
      </svg>

      <span *ngIf="loading_refresh" class="spinner-border spinner-border-gg" role="status" aria-hidden="true">
      </span>
    </button>
  </div>

  <div class="line_top">
    <h3 [style.color]="modeScreen.color">Selecione um período</h3>
    <div class="card_filter">
      <div class="card_date">
        <mat-form-field appearance="fill" (click)="picker.open()">
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate [(ngModel)]="date_from" [style.color]="'black'" readonly>
            <input matEndDate (dateInput)="getDashboard(1)" [(ngModel)]="date_to" [style.color]="'black'" readonly>
          </mat-date-range-input>

          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div *ngIf="loading" class="card_loading">
    <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
  </div>

  <div class="card_table" *ngIf="!loading">
    <h2 [style.color]="modeScreen.color">Pagamentos (Qtde)</h2>
    <table aria-describedby="table" mat-table [dataSource]="data_response"
      [ngClass]="{'darkMode2': modeScreen.theme == 'dark', 'lightMode2': modeScreen.theme == 'light' }">

      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Empresa
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          <span class="break_line"> {{ element.account__name || '-' }} </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="all">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Todos
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.TOTAL || 0 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="news">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Novos
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.NEW || 0 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="approved">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Aprovados
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.APPROVED || 0 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="cancelled">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Cancelados
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.CANCELED || 0 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="waiting_reprocessing">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Aguardando reprocessamento
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.WAITING_REPROCESSING || 0 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="process">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Em processamento
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.PROCESSING || 0 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="successes">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Sucessos
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.SUCCESS || 0 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="errors">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Erros
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.ERROR || 0 }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="12" style="text-align: center" [style.color]="modeScreen.color">
          Nenhum resultado encontrado.
        </td>
      </tr>
    </table>

    <table aria-describedby="table" mat-table *ngIf="data_response.length > 0"
      [ngClass]="{'darkMode2': modeScreen.theme == 'dark', 'lightMode2': modeScreen.theme == 'light' }">

      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Total
        </th>
      </ng-container>

      <ng-container matColumnDef="all">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          {{ getTotal('TOTAL') }}
        </th>
      </ng-container>

      <ng-container matColumnDef="news">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          {{ getTotal('NEW') }}
        </th>
      </ng-container>

      <ng-container matColumnDef="approved">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          {{ getTotal('APPROVED') }}
        </th>
      </ng-container>

      <ng-container matColumnDef="cancelled">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          {{ getTotal('CANCELED') }}
        </th>
      </ng-container>

      <ng-container matColumnDef="waiting_reprocessing">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          {{ getTotal('WAITING_REPROCESSING') }}
        </th>
      </ng-container>

      <ng-container matColumnDef="process">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          {{ getTotal('PROCESSING') }}
        </th>
      </ng-container>

      <ng-container matColumnDef="successes">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          {{ getTotal('SUCCESS') }}
        </th>
      </ng-container>

      <ng-container matColumnDef="errors">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          {{ getTotal('ERROR') }}
        </th>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="12ahhh" style="text-align: center" [style.color]="modeScreen.color">
          Nenhum resultado encontrado.
        </td>
      </tr>
    </table>
  </div>

  <div class="card_table" *ngIf="!loading">
    <h2 [style.color]="modeScreen.color">Pagamentos (Valor R$)</h2>

    <table aria-describedby="table" mat-table [dataSource]="data_response"
      [ngClass]="{'darkMode2': modeScreen.theme == 'dark', 'lightMode2': modeScreen.theme == 'light' }">

      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Empresa
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          <span class="break_line"> {{ element.account__name || '-' }} </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="all">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Todos
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.TOTAL_VALUE ? (element.TOTAL_VALUE | convertMoney) : 0 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="news">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Novos
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.NEW_VALUE ? (element.NEW_VALUE | convertMoney) : 0 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="approved">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Aprovados
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.APPROVED_VALUE ? (element.APPROVED_VALUE | convertMoney) : 0 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="cancelled">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Cancelados
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.CANCELED_VALUE ? (element.CANCELED_VALUE | convertMoney) : 0 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="waiting_reprocessing">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Aguardando reprocessamento
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.WAITING_REPROCESSING_VALUE ? (element.WAITING_REPROCESSING_VALUE | convertMoney) : 0 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="process">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Em processamento
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.PROCESSING_VALUE ? (element.PROCESSING_VALUE | convertMoney) : 0 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="successes">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Sucessos
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.SUCCESS_VALUE ? (element.SUCCESS_VALUE | convertMoney) : 0 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="errors">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Erros
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.ERROR_VALUE ? (element.ERROR_VALUE | convertMoney) : 0 }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="12" style="text-align: center" [style.color]="modeScreen.color">
          Nenhum resultado encontrado.
        </td>
      </tr>
    </table>

    <table aria-describedby="table" mat-table *ngIf="data_response.length > 0"
      [ngClass]="{'darkMode2': modeScreen.theme == 'dark', 'lightMode2': modeScreen.theme == 'light' }">

      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Total
        </th>
      </ng-container>

      <ng-container matColumnDef="all">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          {{ getTotal('TOTAL_VALUE') | convertMoney }}
        </th>
      </ng-container>

      <ng-container matColumnDef="news">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          {{ getTotal('NEW_VALUE') | convertMoney }}
        </th>
      </ng-container>

      <ng-container matColumnDef="approved">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          {{ getTotal('APPROVED_VALUE') | convertMoney }}
        </th>
      </ng-container>

      <ng-container matColumnDef="cancelled">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          {{ getTotal('CANCELED_VALUE') | convertMoney }}
        </th>
      </ng-container>

      <ng-container matColumnDef="waiting_reprocessing">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          {{ getTotal('WAITING_REPROCESSING_VALUE') | convertMoney }}
        </th>
      </ng-container>

      <ng-container matColumnDef="process">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          {{ getTotal('PROCESSING_VALUE') | convertMoney }}
        </th>
      </ng-container>


      <ng-container matColumnDef="successes">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          {{ getTotal('SUCCESS_VALUE') | convertMoney }}
        </th>
      </ng-container>

      <ng-container matColumnDef="errors">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          {{ getTotal('ERROR_VALUE') | convertMoney }}
        </th>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="12" style="text-align: center" [style.color]="modeScreen.color">
          Nenhum resultado encontrado.
        </td>
      </tr>
    </table>
  </div>

</main>
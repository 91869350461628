<main [ngClass]="{'darkMode': modeScreen.theme == 'dark', 'lightMode': modeScreen.theme == 'light' }">
  <span class="text" [style.color]="modeScreen.color">Olá, {{ name_user && name_user }} </span>
  <p class="sub_text" [style.color]="modeScreen.color" [style.color]="modeScreen.color">Admin</p>

  <div class="line_top">
    <div class="card_title">
      <h2 class="font25" [style.color]="modeScreen.color">Tipos de Usuários</h2>
      <!-- <button mat-button
        [ngClass]="{'btn_white': modeScreen.theme == 'dark', 'btn_brown': modeScreen.theme == 'light' }"> Adicionar
      </button> -->
    </div>

    <!-- <div class="card_search">
      <input class="input" matInput [style.color]="modeScreen.color"
        [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }" type="text"
        placeholder="Pesquisa" autocomplete="off">
      <svg class="icon" id="Group_364" data-name="Group 364" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
        viewBox="0 0 24 24" [style.fill]="modeScreen.icon">
        <path id="Icon_material-search" data-name="Icon material-search"
          d="M17.806,16.209h-.841l-.3-.287a6.929,6.929,0,1,0-.745.745l.287.3v.841l5.322,5.312,1.586-1.586Zm-6.387,0a4.79,4.79,0,1,1,4.79-4.79A4.784,4.784,0,0,1,11.419,16.209Z"
          transform="translate(-1.5 -1.5)" />
        <rect id="Rectangle_2285" data-name="Rectangle 2285" width="24" height="24" fill="none" />
      </svg>
    </div> -->
  </div>
</main>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { headersAuthFile } from '../models/headers';
import { Transfer } from '../models/transfer';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(private http: HttpClient) {}

  getInvoiceId(id: number): Observable<any> {
    const options = {
      headers: headersAuthFile(),
      params: new HttpParams().set(`invoice_id`, id),
    };

    return this.http.get<Transfer>(
      environment.path + `core/get-invoice-by-id-from-company/`,
      options
    ).pipe(retry(1));
  }
}

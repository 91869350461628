<div class="modal-content"
  [ngClass]="{'darkModal': modeScreen.theme == 'dark', 'lightModal': modeScreen.theme == 'light' }">

  <svg (click)="close()" class="icons_close" width="25px" height="25px" viewBox="0 0 24 24"
    [style.fill]="modeScreen.color">
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path [style.fill]="modeScreen.color"
      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
  </svg>


  <div class="modal-header">

    <h1 class="modal-title" [style.color]="modeScreen.color">
      <b> {{title}} </b>
    </h1>

  </div>

  <div *ngIf="type === 'text'">
    <div class="modal-body" *ngIf="text != null && text != ''" [style.color]="modeScreen.color">
      {{text}}
    </div>
  </div>
  
  <div *ngIf="type === 'json'" class="teste">
    <ngx-json-viewer [json]="text" [expanded]="true"></ngx-json-viewer>
  </div>

</div>

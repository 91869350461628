import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Md5 } from 'md5-typescript';
import { Observable, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { headersAuthFile, headersFile } from '../models/headers';
import { Login, User } from './../models/user';
import { StorageService } from './storage.service';
import { ILogin } from '../models/login';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private storage: StorageService) {}

  login(login: ILogin): Observable<Login> {
    const passwordMD5 = Md5.init(login.password).toUpperCase();

    const body = new HttpParams()
      .set(`username`, login.email)
      .set(`password`, passwordMD5)
      .set(`mfa_token`, login.mfa_token);

    return this.http
      .post<Login>(environment.path + 'core/auth-admin/', body.toString(), {
        headers: headersFile(),
      })
      .pipe(retry(1));
  }

  getMe(): Observable<User> {
    return this.http
      .get<User>(environment.path + 'core/get-user-admin/', {
        headers: headersAuthFile(),
      })
      .pipe(retry(1));
  }
}

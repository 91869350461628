import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-types-of-users',
  templateUrl: './types-of-users.component.html',
  styleUrls: ['./types-of-users.component.scss']
})
export class TypesOfUsersComponent implements OnInit {

  constructor(
    private storage: StorageService,
  ) { }

  modeScreen: any
  name_user: string = ''

  ngOnInit(): void {
    this.modeScreen = this.storage.checkTheme();
    this.name_user = this.storage.getUser().name

    this.storage.watchTheme().subscribe(data => {
      this.modeScreen = this.storage.checkTheme();
    })

    this.storage.watchUser().subscribe(user => {
      this.name_user = this.storage.getUser().name
    })
  }

}

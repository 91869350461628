export const modalConfig = {
  keyboard: true,
  focus: true,
  show: true,
  class: 'modal-dialog-centered modal-dialog modal-lg',
  containerClass: '',
  ignoreBackdropClick: false,
  animated: true,
}

export type IModalAction = 'yes' | 'no' | 'message' | 'loading' | 'create'

export type IModalEvent = {
  event: 'yes' | 'no' | 'message' | 'loading',
  res: any
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Transfer } from '../models/transfer';
import { CustomEncoder } from './custom-encoder';
import { StorageService } from './storage.service';
import { headersAuthFile } from '../models/headers';

@Injectable({
  providedIn: 'root',
})
export class TransferService {
  constructor(private http: HttpClient, private storage: StorageService) {}

  getTransfer(
    page: number,
    transfer: Transfer,
    id: any,
    page_size: string,
    search: string,
    first: string,
    last: string
  ): Observable<any> {
    let options = {
      headers: headersAuthFile(),
      params: new HttpParams()
        .set(`page_size`, page_size)
        .set(`page`, page)
        .set(`id`, id),
    };

    if (transfer.status != 'ALL') {
      options.params = options.params.append(`status`, transfer.status);
    }

    if (search) options.params = options.params.set(`name`, search);

    if (first != null && first != '' && last != null && last != '') {
      options.params = options.params.append(`date_from`, first);
      options.params = options.params.append(`date_to`, last);
    }

    return this.http
      .get<Transfer>(
        environment.path + `finance/list-transactions-super-admin/`,
        options
      )
      .pipe(retry(1));
  }

  getTransferId(id: number): Observable<any> {
    const options = {
      headers: headersAuthFile(),
      params: new HttpParams().set(`id`, id),
    };

    return this.http
      .get<Transfer>(
        environment.path + `finance/get-transaction-by-id-super-admin/`,
        options
      )
      .pipe(retry(1));
  }

  getClientId(id_cliente: number): Observable<any> {
    const options = {
      headers: headersAuthFile(),
      params: new HttpParams().set(`id`, id_cliente),
    };

    return this.http
      .get<Transfer>(
        environment.path + `finance/get-client-by-id-super-admin/`,
        options
      )
      .pipe(retry(1));
  }

  getClientNoPagination(): Observable<any> {
    const options = {
      params: new HttpParams(),
      headers: headersAuthFile(),
    };

    return this.http
      .get<any>(
        environment.path + 'finance/get-client-no-pagination-super-admin/',
        options
      )
      .pipe(retry(1));
  }

  reprocessTransaction(array: any) {
    let body = new HttpParams({ encoder: new CustomEncoder() });

    return this.http
      .post<any>(
        environment.path +
          `finance/reprocess-transaction-by-array-id-super-admin/?array_id=${array}`,
        body,
        { headers: headersAuthFile() }
      )
      .pipe(retry(1));
  }

  editErrorTransaction(id: any, new_erro_descriptor: string) {
    let body = new HttpParams().set('new_erro_descriptor', new_erro_descriptor);

    return this.http
      .post<any>(
        environment.path +
          `finance/edit-transaction-error-super-admin/?id=${id}`,
        body,
        { headers: headersAuthFile() }
      )
      .pipe(retry(1));
  }

  editErrorLog(id: any, new_erro_descriptor: string) {
    let body = new HttpParams().set('new_erro_descriptor', new_erro_descriptor);

    return this.http
      .post<any>(
        environment.path + `finance/edit-log-error-super-admin/?id=${id}`,
        body,
        { headers: headersAuthFile() }
      )
      .pipe(retry(1));
  }

  getDashboardQuantityPaymntByStatus(
    first: string,
    last: string
  ): Observable<any> {
    let options = {
      headers: headersAuthFile(),
      params: new HttpParams(),
    };

    if (first != null && first != '' && last != null && last != '') {
      options.params = options.params.append(`date_from`, first);
      options.params = options.params.append(`date_to`, last);
    }

    return this.http
      .get<Transfer>(
        environment.path + `finance/dashboard-quantitiy-payment-by-status/`,
        options
      )
      .pipe(retry(1));
  }

  downloadDashboardQuantityPaymnt(
    first: string,
    last: string
  ): Observable<any> {
    const options: any = {
      headers: headersAuthFile(),
      responseType: 'blob' as 'json',
      observe: 'response',
      params: new HttpParams(),
    };

    if (first != null && first != '' && last != null && last != '') {
      options.params = options.params.append(`date_from`, first);
      options.params = options.params.append(`date_to`, last);
    }

    return this.http.get<any>(
      environment.path +
        `finance/export-csv-dashboard-quantitiy-payment-by-status/`,
      options
    ).pipe(retry(1));
  }
}

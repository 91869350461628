import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertStatusPix'
})
export class ConvertStatusPixPipe implements PipeTransform {

  transform(status: string): string {
    let result =
      // Transações que devem entrar como Crédito na conta
      status == "CASH_IN_PIX" ? "Recebimento Pix" :
      status == "PIX" ? "Envio Pix Demais Bancos" :
        status == "CASH_IN_TED" ? "Recebimento TED" :
          status == "CASH_IN_REFUND_TED" ? "Estorno TED" :
            status == "CASH_IN_SLIP" ? "Recebimento TED" :
              status == "PIX_ROLLBACK" ? "Estorno Pix" :
                status == "CASH_IN_REFUND_PIX" ? "Reembolso Pix" :
                  status == "INTERNAL" ? "Transferência Interna SaQ" :
                    status == "INTERNAL_PIX" ? "Envio Pix Interno SaQ" :
                        status == "TED" ? "Pagamento TED" : '-'


    return result
  }
}

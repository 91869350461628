import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Bank } from '../models/bank';
import { headersAuthFile } from '../models/headers';
import { ValidatorUtils } from '../utils/validator.utils';
import { CustomEncoder } from './custom-encoder';

@Injectable({
  providedIn: 'root',
})
export class BaasService {
  constructor(private http: HttpClient) {}

  validator = new ValidatorUtils();

  getBanks(page: number, bank: Bank, name: string): Observable<any> {
    const options = {
      params: new HttpParams()
        .set(`page_size`, environment.pageSize)
        .set(`page`, page)
        .set(`name`, name),

      headers: headersAuthFile(),
    };

    return this.http
      .get<any>(environment.path + 'finance/list-banks-admin/', options)
      .pipe(retry(1));
  }

  getBankId(id: any): Observable<Bank> {
    const options = {
      params: new HttpParams(),
      headers: headersAuthFile(),
    };

    return this.http
      .get<Bank>(
        environment.path + `finance/get-bank-by-id-admin/?id=${id}`,
        options
      )
      .pipe(retry(1));
  }

  postBank(bank: Bank, company: string): Observable<Bank> {
    const body = new HttpParams({ encoder: new CustomEncoder() })
      .set(`name`, bank.name)
      .set(`number`, bank.number)
      .set(`company`, company);

    return this.http
      .post<Bank>(
        environment.path + '/finance/create-bank-admin/',
        body.toString(),
        { headers: headersAuthFile() }
      )
      .pipe(retry(1));
  }

  editBank(bank: Bank, company: any): Observable<Bank> {
    const body = new HttpParams()
      .set(`name`, bank.name)
      .set(`number`, bank.number)
      .set(`company`, company);

    return this.http
      .patch<Bank>(
        environment.path + `finance/update-bank-admin/?id=${bank.id}`,
        body.toString(),
        { headers: headersAuthFile() }
      )
      .pipe(retry(1));
  }

  deleteBank(id: number): Observable<any> {
    let options = {
      headers: headersAuthFile(),
      params: new HttpParams(),
    };

    return this.http
      .delete<any>(
        environment.path + `finance/delete-bank-admin/?id=${id}`,
        options
      )
      .pipe(retry(1));
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-modal-delete',
  templateUrl: './modal-delete.component.html',
  styleUrls: ['./modal-delete.component.scss']
})
export class ModalDeleteComponent implements OnInit {

  constructor(
    public modalRef: MDBModalRef,
    private storage: StorageService,
    private Route: ActivatedRoute
  ) { }

  action: Subject<any> = new Subject();
  title: string = "";
  text: string = "";
  button: string = "";
  modeScreen: any;

  ngOnInit(): void {
    this.storage.watchTheme().subscribe((data) => {
      this.modeScreen = this.storage.checkTheme();
    });
  }

  close() {
    this.modalRef.hide();
    this.action.next('no');
  }

  next() {
    this.modalRef.hide();
    this.action.next('yes');
  }
}

<main [ngClass]="{'darkMode': modeScreen.theme == 'dark', 'lightMode': modeScreen.theme == 'light' }">
  <span class="text" [style.color]="modeScreen.color">Olá, {{ user.name && user.name }} </span>
  <p class="sub_text" [style.color]="modeScreen.color" [style.color]="modeScreen.color">Admin</p>

  <div class="line_top">
    <h2 class="font25" [style.color]="modeScreen.color">Logs</h2>

    <button mat-fab class="btn_refresh" aria-label="Botão para atualizar" (click)="getLogsS3()">
      <svg *ngIf="!loading_refresh" height="30" width="30" viewBox="0 0 48 48">
        <path [style.fill]="modeScreen.color2"
          d="M23.85 41.25q-7.15 0-12.2-5.05Q6.6 31.15 6.6 24q0-7.15 5.05-12.225Q16.7 6.7 23.85 6.7q4.55 0 8.025 1.825 3.475 1.825 5.975 5.125V6.7h3.6v14.55h-14.6V17.7h8.2q-1.95-2.8-4.7-4.525-2.75-1.725-6.5-1.725-5.25 0-8.9 3.65-3.65 3.65-3.65 8.9t3.65 8.9q3.65 3.65 8.9 3.65 3.9 0 7.225-2.25 3.325-2.25 4.725-5.95h4.85q-1.5 5.75-6.175 9.325Q29.8 41.25 23.85 41.25Z" />
      </svg>

      <span *ngIf="loading_refresh" class="spinner-border spinner-border-gg" role="status" aria-hidden="true">
      </span>
    </button>
  </div>

  <div class="line_top">
    <div class="card_search">
      <input class="input" matInput [style.color]="modeScreen.color"
        [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
        [(ngModel)]="id_transaction" type="text" placeholder="Pesquisa" autocomplete="off" (keyup.enter)="getLogsS3()">

      <svg (click)="getLogsS3()" class="icon" id="Group_364" data-name="Group 364" xmlns="http://www.w3.org/2000/svg"
        width="24" height="24" viewBox="0 0 24 24" [style.fill]="modeScreen.icon">
        <path id="Icon_material-search" data-name="Icon material-search"
          d="M17.806,16.209h-.841l-.3-.287a6.929,6.929,0,1,0-.745.745l.287.3v.841l5.322,5.312,1.586-1.586Zm-6.387,0a4.79,4.79,0,1,1,4.79-4.79A4.784,4.784,0,0,1,11.419,16.209Z"
          transform="translate(-1.5 -1.5)" />
        <rect id="Rectangle_2285" data-name="Rectangle 2285" width="24" height="24" fill="none" />
      </svg>
    </div>
  </div>

  <div *ngIf="loading_refresh" class="card_loading">
    <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
  </div>

  <div *ngIf="logs && !loading_refresh">
    <h2 class="font18" [style.color]="modeScreen.color">Logs S3</h2>

    <div class="card_table" *ngIf="!loading">
      <table aria-describedby="table" mat-table [dataSource]="logs?.logs_s3"
        [ngClass]="{'darkMode2': modeScreen.theme == 'dark', 'lightMode2': modeScreen.theme == 'light' }">

        <ng-container matColumnDef="transaction">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Transacão
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line"> {{ element.transaction || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="request_url">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            URL
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color"
            (click)="openMessage('URL', element.request_url)">
            <span class="break_line"> {{ element.request_url || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="send_headers_data">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Cabeçalho
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color"
            (click)="openMessage('Cabeçalho', element.send_headers_data, 'json')">
            <span class="break_line"> {{ element.send_headers_data || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="send_request_data">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Solicitação
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color"
            (click)="openMessage('Solicitação', element.send_request_data, 'json')">
            <span class="break_line"> {{ element.send_request_data || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="response_data">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Resposta
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color"
            (click)="openMessage('Resposta', element.response_data, 'json')">
            <span class="break_line"> {{ element.response_data || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="response_status_code">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Status
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line">{{ element.response_status_code || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Criado em
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line"> {{ element.created_at | date: 'dd/MM/yyyy HH:mm' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="updated_at">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Atualizado em
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line"> {{ element.updated_at | date: 'dd/MM/yyyy HH:mm' }} </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="column_logs_s3"></tr>
        <tr mat-row *matRowDef="let row; columns: column_logs_s3"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="11" style="text-align: center" [style.color]="modeScreen.color">
            <span> Nenhum resultado encontrado. </span>
          </td>
        </tr>
      </table>
    </div>

    <h2 class="font18" [style.color]="modeScreen.color">Logs Transactions</h2>

    <div class="card_table" *ngIf="!loading">
      <table aria-describedby="table" mat-table [dataSource]="logs?.logs_transactions"
        [ngClass]="{'darkMode2': modeScreen.theme == 'dark', 'lightMode2': modeScreen.theme == 'light' }">

        <ng-container matColumnDef="transaction">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Transacão
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line"> {{ element.transaction || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="user_name">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Nome do usuário
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line"> {{ element.user_name || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="erro_descriptor">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Erro
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color"
            (click)="openMessage('Erro', element.erro_descriptor)">
            <span class="break_line"> {{ element.erro_descriptor || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="new_erro_descriptor">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Novo erro
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color"
            (click)="openMessage('Novo erro', element.new_erro_descriptor)">
            <span class="break_line"> {{ element.new_erro_descriptor || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Status
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line_status"> {{ element.status ? (element.status | convertStatusLead) : '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Criado em
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line"> {{ element.created_at | date: 'dd/MM/yyyy HH:mm' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="updated_at">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Atualizado em
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line"> {{ element.updated_at ? (element.updated_at | date: 'dd/MM/yyyy HH:mm') : '-' }} </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="column_logs_transactions"></tr>
        <tr mat-row *matRowDef="let row; columns: column_logs_transactions"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="11" style="text-align: center" [style.color]="modeScreen.color">
            <span> Nenhum resultado encontrado. </span>
          </td>
        </tr>
      </table>
    </div>

    <h2 class="font18" [style.color]="modeScreen.color">Transactions</h2>

    <div class="card_table" *ngIf="!loading">
      <table aria-describedby="table" mat-table [dataSource]="logs?.transaction"
        [ngClass]="{'darkMode2': modeScreen.theme == 'dark', 'lightMode2': modeScreen.theme == 'light' }">

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Status
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line"> {{ element.status || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="recipient_name">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Nome
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line"> {{ element.recipient_name || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="recipient_account_id">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Conta
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line"> {{ element.recipient_account_id || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="recipient_branch_id">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Agência
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line"> {{ element.recipient_branch_id || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="recipient_instution">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            ISPB
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line"> {{ element.recipient_instution || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="recipient_legal_id">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Documento
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line"> {{ element.recipient_legal_id || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="key">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Chave pix
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line"> {{ element.key || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Valor
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line"> {{ element.amount ? (element.amount | convertMoney) : '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="to_account_type">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Tipo de conta
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line"> {{ element.to_account_type || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="transfer_type">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Tipo de transferência
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line"> {{ element.transfer_type || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="type_transaction">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Tipo de transação
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line_status"> {{ element.type_transaction || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="updated_at">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Última atualização
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line"> {{ element.updated_at | date: 'dd/MM/yyyy HH:mm' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="uuid">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            UUID
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
            <span class="break_line" (click)="openMessage('UUID', element.uuid)"> {{ element.uuid || '-' }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="request_data_json">
          <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
            Body enviado
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color"
            (click)="openMessage('Body enviado', element.request_data_json, 'json')">
            <span class="break_line"> {{ element.request_data_json || '-' }} </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="column_transaction"></tr>
        <tr mat-row *matRowDef="let row; columns: column_transaction"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="11" style="text-align: center" [style.color]="modeScreen.color">
            <span> Nenhum resultado encontrado. </span>
          </td>
        </tr>
      </table>
    </div>
  </div>

</main>
<main [ngClass]="{'darkMode': modeScreen.theme == 'dark', 'lightMode': modeScreen.theme == 'light' }">
  <span class="text" [style.color]="modeScreen.color">Olá, {{ name_user && name_user }} </span>
  <p class="sub_text" [style.color]="modeScreen.color" [style.color]="modeScreen.color">Admin</p>

  <div class="line_top">
    <div class="card_title">
      <h2 class="font25" [style.color]="modeScreen.color">Tarifas</h2>
      <button mat-button (click)="addTariff('new')"
        [ngClass]="{'btn_white': modeScreen.theme == 'dark', 'btn_brown': modeScreen.theme == 'light' }"> Adicionar
      </button>
    </div>

    <div class="card_search">
      <input class="input" matInput [style.color]="modeScreen.color"
        [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
        [(ngModel)]="input_search" type="text" placeholder="Pesquisa" (keyup.enter)="getTariffs(1)" autocomplete="off">

      <svg (click)="getTariffs(1)" class="icon" id="Group_364" data-name="Group 364" xmlns="http://www.w3.org/2000/svg"
        width="24" height="24" viewBox="0 0 24 24" [style.fill]="modeScreen.icon">
        <path id="Icon_material-search" data-name="Icon material-search"
          d="M17.806,16.209h-.841l-.3-.287a6.929,6.929,0,1,0-.745.745l.287.3v.841l5.322,5.312,1.586-1.586Zm-6.387,0a4.79,4.79,0,1,1,4.79-4.79A4.784,4.784,0,0,1,11.419,16.209Z"
          transform="translate(-1.5 -1.5)" />
        <rect id="Rectangle_2285" data-name="Rectangle 2285" width="24" height="24" fill="none" />
      </svg>

    </div>
  </div>

  <div *ngIf="loading" class="card_loading">
    <span [style.color]="modeScreen.color" class="spinner-border spinner-border" role="status"
      aria-hidden="true"></span>
  </div>

  <div class="card_table" *ngIf="!loading">
    <table aria-describedby="table" mat-table class="tb-1100" [dataSource]="dataSource"
      [ngClass]="{'darkMode2': modeScreen.theme == 'dark', 'lightMode2': modeScreen.theme == 'light' }">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Id tarifa
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element?.id != null ? element.id : '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Tipo da tarifa
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          <span class="break_line" matTooltip="{{element.name}}" matTooltipPosition="right"> {{ element?.name != null ? element.name : '-' }} </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Nome da tarifa
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          <span class="break_line" matTooltip="{{element.description_tariff}}" matTooltipPosition="right"> {{ element?.description_tariff ? (element.description_tariff) : '-' }} </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="validity">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Valor
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element?.value != null ? (element.value | convertMoney) : '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="config">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Configuração
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element?.type_tariff ? (element.type_tariff | uppercase) : '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Tipo
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element?.name === 'Customizado' ? 'MANUAL' : 'DINÂMICO' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="buttons" class="size_auto">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          <div class="buttons">
            <mat-icon matTooltip="Editar" style="cursor: pointer;" matTooltipPosition="above"
              (click)="addTariff('edit', element)">create</mat-icon>
            <mat-icon matTooltip="Deletar" style="cursor: pointer;" matTooltipPosition="above"
              (click)="deleteTariff(element)">delete</mat-icon>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="11" *ngIf="!loading" style="text-align: center" [style.color]="modeScreen.color">
          Nenhum resultado encontrado.
        </td>
      </tr>
    </table>
  </div>

  <div class="table_paginator" *ngIf="!loading">
    <div class="paginator_button" matmatTooltip="Página anterior" (click)="backPage()">
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
        <g id="Group_428" data-name="Group 428" transform="translate(-1688 -1004)">
          <g id="Rectangle_2304" data-name="Rectangle 2304" transform="translate(1688 1004)"
            [style.fill]="modeScreen.fill" [style.stroke]="modeScreen.stroke" stroke-width="1">
            <rect width="27" height="27" rx="5" stroke="none" />
            <rect x="0.5" y="0.5" width="26" height="26" rx="4.5" fill="none" />
          </g>
          <g id="expand" transform="translate(1712.5 1006.719) rotate(90)">
            <path id="Path_7" data-name="Path 7" d="M21.563,21.563H0V0H21.563Z" fill="none" opacity="0.87" />
            <path id="Path_8" data-name="Path 8" d="M9.515,0,5.391,4.115,1.267,0,0,1.267,5.391,6.658l5.391-5.391Z"
              transform="translate(5.391 7.718)" [style.fill]="modeScreen.arrow" />
          </g>
        </g>
      </svg>
    </div>

    <span class="text_paginator" [style.color]="modeScreen.color">
      {{page_atual}} de {{count_page}}
    </span>

    <div class="paginator_button" matmatTooltip="Próxima página" (click)="nextPage()">
      <svg id="Group_347" data-name="Group 347" xmlns="http://www.w3.org/2000/svg" width="27" height="27"
        viewBox="0 0 27 27">
        <g id="Rectangle_2305" data-name="Rectangle 2305" [style.fill]="modeScreen.fill"
          [style.stroke]="modeScreen.stroke" stroke-width="1">
          <rect width="27" height="27" rx="5" stroke="none" />
          <rect x="0.5" y="0.5" width="26" height="26" rx="4.5" fill="none" />
        </g>
        <g id="expand" transform="translate(2.719 24.281) rotate(-90)">
          <path id="Path_7" data-name="Path 7" d="M21.563,21.563H0V0H21.563Z" fill="none" opacity="0.87" />
          <path id="Path_8" data-name="Path 8" d="M9.515,0,5.391,4.115,1.267,0,0,1.267,5.391,6.658l5.391-5.391Z"
            transform="translate(5.391 7.718)" [style.fill]="modeScreen.arrow" />
        </g>
      </svg>
    </div>

  </div>
</main>

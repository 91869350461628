<main [ngClass]="{'darkMode': modeScreen.theme == 'dark', 'lightMode': modeScreen.theme == 'light' }">
  <span class="text" [style.color]="modeScreen.color">Olá, {{ name_user && name_user }} </span>
  <p class="sub_text" [style.color]="modeScreen.color" [style.color]="modeScreen.color">Admin</p>

  <div class="cards">

    <div class="card_router" routerLink="/companies"
      [ngClass]="{'darkMode2': modeScreen.theme == 'dark', 'lightMode2': modeScreen.theme == 'light' }">

      <svg id="apartment_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
        [style.fill]="modeScreen.icon">
        <g id="Group_430" data-name="Group 430">
          <rect id="Rectangle_2766" data-name="Rectangle 2766" width="24" height="24" fill="none" />
        </g>
        <g id="Group_431" data-name="Group 431" transform="translate(2.981 2.981)">
          <path id="Path_908" data-name="Path 908"
            d="M16.912,10.95V3H6.975V6.975H3V20.887h7.95V16.912h1.987v3.975h7.95V10.95ZM6.975,18.9H4.987V16.912H6.975Zm0-3.975H4.987V12.937H6.975Zm0-3.975H4.987V8.962H6.975Zm3.975,3.975H8.962V12.937H10.95Zm0-3.975H8.962V8.962H10.95Zm0-3.975H8.962V4.987H10.95Zm3.975,7.95H12.937V12.937h1.987Zm0-3.975H12.937V8.962h1.987Zm0-3.975H12.937V4.987h1.987ZM18.9,18.9H16.912V16.912H18.9Zm0-3.975H16.912V12.937H18.9Z"
            transform="translate(-3 -3)" />
        </g>
      </svg>

      <h3 [style.color]="modeScreen.color">Empresas</h3>
      <p [style.color]="modeScreen.color">Gerenciamento de informações e atividades relacionadas a empresas e tarifas.
      </p>
    </div>

    <div class="card_router" routerLink="/users"
      [ngClass]="{'darkMode2': modeScreen.theme == 'dark', 'lightMode2': modeScreen.theme == 'light' }">

      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" [style.fill]="modeScreen.icon">
        <g id="people_black_24dp" transform="translate(0)">
          <path id="Path_909" data-name="Path 909" d="M0,0H24V24H0Z" transform="translate(0)" fill="none" />
          <path id="Path_910" data-name="Path 910"
            d="M15.988,10.964a2.982,2.982,0,1,0-3-2.982A2.977,2.977,0,0,0,15.988,10.964Zm-7.994,0A2.982,2.982,0,1,0,5,7.982,2.977,2.977,0,0,0,7.995,10.964Zm0,1.988C5.666,12.951,1,14.114,1,16.43v2.485H14.989V16.43C14.989,14.114,10.323,12.951,7.995,12.951Zm7.994,0c-.29,0-.62.02-.969.05a4.19,4.19,0,0,1,1.968,3.429v2.485h6V16.43C22.983,14.114,18.317,12.951,15.988,12.951Z"
            transform="translate(0.009 0.043)" />
        </g>
      </svg>

      <h3 [style.color]="modeScreen.color">Usuários</h3>
      <p [style.color]="modeScreen.color">Administração de perfis de usuários e contas de acesso.</p>
    </div>


    <div class="card_router" routerLink="/baas"
      [ngClass]="{'darkMode2': modeScreen.theme == 'dark', 'lightMode2': modeScreen.theme == 'light' }">

      <svg id="account_balance_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
        [style.fill]="modeScreen.icon">
        <g id="Group_456" data-name="Group 456">
          <rect id="Rectangle_2780" data-name="Rectangle 2780" width="24" height="24" fill="none" />
        </g>
        <g id="Group_458" data-name="Group 458">
          <g id="Group_457" data-name="Group 457">
            <rect id="Rectangle_2781" data-name="Rectangle 2781" width="3" height="7" transform="translate(4 10)" />
            <rect id="Rectangle_2782" data-name="Rectangle 2782" width="3" height="7" transform="translate(10.5 10)" />
            <rect id="Rectangle_2783" data-name="Rectangle 2783" width="20" height="3" transform="translate(2 19)" />
            <rect id="Rectangle_2784" data-name="Rectangle 2784" width="3" height="7" transform="translate(17 10)" />
            <path id="Path_915" data-name="Path 915" d="M12,1,2,6V8H22V6Z" />
          </g>
        </g>
      </svg>

      <h3 [style.color]="modeScreen.color">BaaS</h3>
      <p [style.color]="modeScreen.color">Gerenciamento de soluções de Banking as a Service.</p>
    </div>

    <div class="card_router" routerLink="/transfer"
      [ngClass]="{'darkMode2': modeScreen.theme == 'dark', 'lightMode2': modeScreen.theme == 'light' }">

      <mat-icon> attach_money</mat-icon>
      <h3 [style.color]="modeScreen.color">Transferências</h3>
      <p [style.color]="modeScreen.color">Gerenciamento de transferências para maior controle das operações.</p>
    </div>

    <div class="card_router" routerLink="/dashboard-employer"
      [ngClass]="{'darkMode2': modeScreen.theme == 'dark', 'lightMode2': modeScreen.theme == 'light' }">

      <mat-icon> bar_chart </mat-icon>
      <h3 [style.color]="modeScreen.color">Dashboard Employer</h3>
      <p [style.color]="modeScreen.color">Visualizações de pagamentos em quantidade e valor para análise de dados.</p>
    </div>


    <div class="card_router" routerLink="/current-account"
      [ngClass]="{'darkMode2': modeScreen.theme == 'dark', 'lightMode2': modeScreen.theme == 'light' }">

      <svg id="account_balance_wallet_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
        viewBox="0 0 24 24" [style.fill]="modeScreen.icon">
        <path id="Path_833" data-name="Path 833" d="M0,0H24V24H0Z" fill="none" />
        <path id="Path_834" data-name="Path 834"
          d="M21,18v1a2.006,2.006,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H19a2.006,2.006,0,0,1,2,2V6H12a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2Zm-9-2H22V8H12Zm4-2.5A1.5,1.5,0,1,1,17.5,12,1.5,1.5,0,0,1,16,13.5Z" />
      </svg>
      <h3 [style.color]="modeScreen.color">Conta corrente</h3>
      <p [style.color]="modeScreen.color">Consulta dos extratos das transações e o saldo da conta</p>
    </div>

    <div class="card_router" routerLink="/logs"
      [ngClass]="{'darkMode2': modeScreen.theme == 'dark', 'lightMode2': modeScreen.theme == 'light' }">
      <mat-icon> import_export </mat-icon>
      <h3 [style.color]="modeScreen.color">Logs</h3>
      <p [style.color]="modeScreen.color">Consulta dos logs das requisições dos bancos</p>
    </div>

    <div class="card_empty"></div>
    <div class="card_empty"></div>

  </div>

</main>
import { LogsService } from './../../services/logs.service';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalMessageComponent } from '../../modals/modal-message/modal-message.component';
import { Transfer } from '../../models/transfer';
import { User } from '../../models/user';
import { StorageService } from '../../services/storage.service';
import { TransferService } from '../../services/transfer.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
})
export class LogsComponent implements OnInit {
  constructor(
    private storage: StorageService,
    private modalService: MDBModalService,
    private _snackBar: MatSnackBar,
    private logsService: LogsService
  ) {}

  modalRef: any = MDBModalRef;
  modalRefMessage: MDBModalRef = new MDBModalRef();

  loading_refresh: boolean = false;

  modeScreen: any;
  loading: boolean = false;
  user: User = {} as User;
  id_transaction!: number;
  type_status: string = 'NEW';

  transfer: Transfer = {} as Transfer;
  data_response: any;

  column_logs_s3: string[] = [
    'transaction',
    'request_url',
    'send_headers_data',
    'send_request_data',
    'response_data',
    'response_status_code',
    'created_at',
    'updated_at',
  ];

  column_logs_transactions: string[] = [
    'transaction',
    'user_name',
    'erro_descriptor',
    'new_erro_descriptor',
    'status',
    'created_at',
    'updated_at',
  ];

  column_transaction: string[] = [
    'status',
    'recipient_name',
    'recipient_account_id',
    'recipient_branch_id',
    'recipient_instution',
    'recipient_legal_id',
    'key',
    'amount',
    'to_account_type',
    'transfer_type',
    'type_transaction',
    'updated_at',
    'uuid',
    'request_data_json',
  ];

  logs: any;

  ngOnInit(): void {
    this.modeScreen = this.storage.checkTheme();
    this.user = this.storage.getUser();

    this.storage.watchTheme().subscribe((data) => {
      this.modeScreen = this.storage.checkTheme();
    });

    this.storage.watchUser().subscribe((user) => {
      this.user = this.storage.getUser();
    });
  }

  getLogsS3() {
    this.logs = null;

    this.loading_refresh = true;
    this.logsService.getLogsS3(this.id_transaction).subscribe({
      next: (data) => {
        this.loading_refresh = false;
        this.logs = data;
      },
      error: () => {
        this.loading_refresh = false;
        this.openSnackBar(
          'Não foi possível carregar os Logs disponíveis',
          'Fechar'
        );
      },
    });
  }

  convertDateSendApi(data: string) {
    if (data == null) {
      return null;
    } else {
      let d = new Date(data),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      let aux_data = [year, month, day].join('-');

      return aux_data;
    }
  }

  openMessage(title: string, text: string, type: string = 'text') {
    this.modalRefMessage = this.modalService.show(ModalMessageComponent, {
      class: 'modal-dialog-centered modal-dialog modal-lg',
      containerClass: '',
      animated: false,
      data: {
        modeScreen: this.modeScreen,
        title,
        text: type === 'json' ? this.formatJson(text) : text,
        type: type,
      },
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 10000,
    });
  }

  formatJson(text: string) {
    let json = text.replace(/'/g, '"');
    json = json.replace(/None/g, 'null');
    json = json.replace(/False/g, 'false');
    json = json.replace(/True/g, 'true');

    return JSON.parse(json);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InvoiceService } from 'src/app/services/invoice.service';
import { StorageService } from 'src/app/services/storage.service';
import { Converters } from 'src/app/utils/converters';
import { ValidatorUtils } from 'src/app/utils/validator.utils';

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss'],
})
export class InvoiceDetailComponent implements OnInit {

  constructor(
    private storage: StorageService,
    private invoiceService: InvoiceService,
    private route: ActivatedRoute,
  ) {
    this.companies_id = this.route.snapshot.paramMap.get('id');
    this.invoice_id = this.route.snapshot.paramMap.get('invoice_id');
  }

  panelOpenState = false;

  companies_id: any;
  invoice_id: any;
  panelOpen = false;

  invoice: any = null;
  invoicesColumns: string[] = ["day", "quantity", "value", "discount", "total_value"];
  invoicesNullColumns: string[] = ["type_tariff", "value", "discount", "total_value", "created_at"];

  converters = new Converters()

  modeScreen: any;

  loading: boolean = false;
  warningMap = new Map();
  validator = new ValidatorUtils()

  ngOnInit() {
    this.storage.watchTheme().subscribe(data => {
      this.modeScreen = this.storage.checkTheme();
    })
    this.modeScreen = this.storage.checkTheme();

    this.getInvoiceId()
  }

  getInvoiceId() {
    this.loading = true;
    this.invoiceService.getInvoiceId(this.invoice_id).subscribe(data => {
      this.invoice = data;
      this.loading = false;
    },
      error => {
        this.loading = false;
      })
  }

  transformTariffDataSource(tariff: any) {
    let tariffDataSource = [
      {
        type_tariff: tariff.type_tariff,
        value: tariff.value,
        discount: tariff.discount,
        total_value: tariff.total_value,
        created_at: tariff.created_at
      },
    ]

    return tariffDataSource
  }
}

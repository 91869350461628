import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MDBModalRef } from 'angular-bootstrap-md';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service'
import { User } from 'src/app/models/user';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-modal-change-password',
  templateUrl: './modal-change-password.component.html',
  styleUrls: ['./modal-change-password.component.scss']
})
export class ModalChangePasswordComponent implements OnInit {

  constructor(
    private storage: StorageService,
    private modalRef: MDBModalRef,
    private userService: UserService,
    private _snackBar: MatSnackBar,
  ) { }

  loading: boolean = false

  action: Subject<any> = new Subject()
  title: string = ""
  user: User = {} as User
  modeScreen: any;

  button: string = "";
  warningMap = new Map()
  validator = new ValidatorUtils()

  ngOnInit(): void {
    this.storage.watchTheme().subscribe((data) => {
      this.modeScreen = this.storage.checkTheme();
    });
    this.modeScreen = this.storage.checkTheme();
  }

  close() {
    this.modalRef.hide();
    this.action.next('no');
  }

  next() {
    this.modalRef.hide();
    this.action.next('yes');
  }

  checkInput() {
    this.warningMap = new Map();

    if (this.user.password == "" || this.user.password == null) {
      this.warningMap.set('password', true);
    }
    if (this.user.confirm_password == "" || this.user.confirm_password == null) {
      this.warningMap.set('confirm_password', true);
    }
    if (this.user.password != this.user.confirm_password) {
      this.warningMap.set('password', true);
      this.warningMap.set('confirm_password', true);
    }

    if (this.warningMap.size == 0) {
      this.changePassword()
    }
  }

  checkInputMessage(value: string, type: string, value2?: string) {
    return this.validator.checkInputMessage(value, type, value2)
  }


  changePassword() {
    this.loading = true;
    this.userService.changePassword(this.user).subscribe(data => {
      this.openSnackBar("Senha Alterada!", "Fechar");
      this.loading = false;
      this.user.password = '';
      this.user.confirm_password = '';

      this.next()
    }, error => {
      this.loading = false;
    })
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 10000,
    });
  }
}

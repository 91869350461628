import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpParams,
  HttpClient,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take, finalize } from 'rxjs/operators';
import { IAuthToken } from 'src/app/models/user';
import { environment } from 'src/environments/environment';
import { headersFile } from 'src/app/models/headers';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';

@Injectable()
export class HttpStatusInterceptor implements HttpInterceptor {
  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private router: Router
  ) {}

  // Variável para controlar o status da renovação do token
  private isRefreshing = false;

  // BehaviorSubject para controlar o fluxo da renovação do token
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  // Intercepta as solicitações HTTP
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('core/auth-admin/')) {
      // Se corresponder a uma das URLs, simplesmente continue com a solicitação sem fazer nada
      return next.handle(request);
    }
    // Obtém o token de acesso do armazenamento
    const token = this.storage.getToken();
    // Define o cabeçalho da solicitação com o token de acesso
    const headers = request.headers.set('Authorization', `Bearer ${token}`);
    // Cria uma nova solicitação com os cabeçalhos atualizados
    const modifiedRequest = request.clone({ headers });

    // Manipula o fluxo da solicitação através do pipeline
    return next.handle(modifiedRequest).pipe(
      catchError((error) => {
        // Verifica se o erro ocorreu durante a renovação do token
        if (error.url.includes('token-refresh')) {
          this.storage.logout(); // Desloga o usuário
        }

        if (error.status === 401) {
          if (!this.isRefreshing) {
            // Inicia o processo de renovação do token
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            // Renova o token e continua com a solicitação atual
            return this.refreshToken().pipe(
              switchMap((newToken) => {
                // Armazena o novo token de acesso
                this.storage.setAccessToken(newToken.access);
                // Atualiza os cabeçalhos da solicitação com o novo token
                const updatedHeaders = request.headers.set(
                  'Authorization',
                  `Bearer ${newToken.access}`
                );
                const updatedRequest = request.clone({
                  headers: updatedHeaders,
                });

                // Sinaliza que a renovação do token foi concluída
                this.refreshTokenSubject.next(newToken.access);

                // Continua com a solicitação original
                return next.handle(updatedRequest);
              }),
              catchError((error) => {
                return throwError(() => new Error(error));
              }),
              finalize(() => {
                this.isRefreshing = false; // Conclui o processo de renovação
              })
            );
          } else {
            // Aguarda a conclusão da renovação do token e continua com a solicitação
            return this.refreshTokenSubject.pipe(
              filter((token) => token !== null),
              take(1),
              switchMap((updatedToken) => {
                const updatedHeaders = request.headers.set(
                  'Authorization',
                  `Bearer ${updatedToken}`
                );
                const updatedRequest = request.clone({
                  headers: updatedHeaders,
                });
                return next.handle(updatedRequest);
              })
            );
          }
        } else {
          return next.handle(modifiedRequest);
        }
      })
    );
  }

  /**
   * Função para atualizar o token de acesso
   * @param { string } refresh refreshToken que foi salvo no login
   * @return Observable de IAuthToken
   */
  refreshToken(): Observable<IAuthToken> {
    const body = new HttpParams().set(
      `refresh`,
      this.storage.getRefreshToken()
    );

    // Envia uma solicitação POST para renovar o token
    return this.http.post<IAuthToken>(
      environment.path + 'core/token-refresh/',
      body.toString(),
      { headers: headersFile() }
    );
  }
}

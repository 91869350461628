import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { NgxMaskModule } from 'ngx-mask';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
  RECAPTCHA_SETTINGS,
} from 'ng-recaptcha';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ModalDeleteComponent } from './modals/modal-delete/modal-delete.component';
import { EditUserComponent } from './modals/edit-user/edit-user.component';
import { ModalChangePasswordComponent } from './modals/modal-change-password/modal-change-password.component';
import { ConvertStatusLeadPipe } from './pipes/convert-status-lead.pipe';
import { ConvertMoneyPipe } from './pipes/convert-money.pipe';
import { ModalChoiceComponent } from './modals/modal-choice/modal-choice.component';
import { ModalMessageComponent } from './modals/modal-message/modal-message.component';

import { DatePipe, registerLocaleData } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { TariffsComponent } from './pages/tariffs/tariffs.component';
import { ModalRegisterTariffComponent } from './modals/modal-register-tariff/modal-register-tariff.component';
import { LoginComponent } from './pages/login/login.component';
import { UsersComponent } from './pages/users/users.component';
import { DetailsUserComponent } from './pages/users/details-user/details-user.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { TransferComponent } from './pages/transfer/transfer.component';
import { DetailsTransferComponent } from './pages/transfer/details-transfer/details-transfer.component';
import { TypesOfUsersComponent } from './pages/types-of-users/types-of-users.component';
import { HomeComponent } from './pages/home/home.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { BusinessGroupComponent } from './pages/business-group/business-group.component';
import { BaasComponent } from './pages/baas/baas.component';
import { DetailsBaasComponent } from './pages/baas/details-baas/details-baas.component';
import { AccessGroupsComponent } from './pages/access-groups/access-groups.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { DetailCompaniesComponent } from './pages/companies/detail-companies/detail-companies.component';
import { DatailTariffComponent } from './pages/companies/datail-tariff/datail-tariff.component';
import { InvoiceDetailComponent } from './pages/companies/invoice-detail/invoice-detail.component';
import { IconDarkLightComponent } from './components/icon-dark-light/icon-dark-light.component';
import { DashboardEmployerComponent } from './pages/dashboard-employer/dashboard-employer.component';
import { ConvertPercentPipe } from './pipes/convert-percent.pipe';
import { ConvertDateApiToInputPipe } from './pipes/convert-date-api-to-input.pipe';
import { ConvertDateInputToApiPipe } from './pipes/convert-date-input-to-api.pipe';
import { environment } from 'src/environments/environment';
import { HttpStatusInterceptor } from './services/http-status.interceptor.service';
import { CurrentAccountComponent } from './pages/current-account/current-account.component';
import { ConvertCreditDebitTablePipe } from './pipes/convert-credit-debit-table.pipe';
import { DetailsExtractComponent } from './modals/details-extract/details-extract.component';
import { ModalSelectDownloadComponent } from './modals/modal-select-download/modal-select-download.component';
import { ConvertStatusPixPipe } from './pipes/convert-status-pix.pipe';
import {
  CURRENCY_MASK_CONFIG,
  CurrencyMaskConfig,
  CurrencyMaskModule,
} from 'ng2-currency-mask';
import { LogsComponent } from './pages/logs/logs.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

registerLocaleData(localePt);

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CompaniesComponent,
    TypesOfUsersComponent,
    BusinessGroupComponent,
    BaasComponent,
    DetailsBaasComponent,
    AccessGroupsComponent,
    TransferComponent,
    ProfileComponent,
    LoginComponent,
    NavbarComponent,
    UsersComponent,
    ModalDeleteComponent,
    EditUserComponent,
    DetailsUserComponent,
    DetailsTransferComponent,
    ModalChangePasswordComponent,
    ModalChoiceComponent,
    ModalMessageComponent,
    ConvertStatusLeadPipe,
    ConvertMoneyPipe,
    TariffsComponent,
    DetailCompaniesComponent,
    ModalRegisterTariffComponent,
    DatailTariffComponent,
    InvoiceDetailComponent,
    IconDarkLightComponent,
    DashboardEmployerComponent,
    ConvertPercentPipe,
    ConvertDateApiToInputPipe,
    ConvertDateInputToApiPipe,
    CurrentAccountComponent,
    ConvertCreditDebitTablePipe,
    DetailsExtractComponent,
    ConvertStatusPixPipe,
    ModalSelectDownloadComponent,
    LogsComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatSidenavModule,
    MatInputModule,
    MatToolbarModule,
    MatIconModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatTableModule,
    MatSelectModule,
    HttpClientModule,
    MatButtonModule,
    MatChipsModule,
    MatRadioModule,
    MatExpansionModule,
    MatMenuModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatSnackBarModule,
    NgxMaskModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    RecaptchaFormsModule,
    RecaptchaModule,
    CurrencyMaskModule,
    NgxJsonViewerModule
  ],
  providers: [
    DatePipe,
    MatFormFieldModule,
    MatDatepickerModule,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha,
      } as RecaptchaSettings,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpStatusInterceptor,
      multi: true,
    },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

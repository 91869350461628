import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { IModalAction } from 'src/app/models/modal.model';
import { IExtract } from 'src/app/models/extract';

@Component({
  selector: 'app-details-extract',
  templateUrl: './details-extract.component.html',
  styleUrls: ['./details-extract.component.scss']
})
export class DetailsExtractComponent implements OnInit {

  constructor(
    public modalRef: MDBModalRef,
    private storage: StorageService) { }

  modeScreen = this.storage.checkTheme();

  action: Subject<IModalAction> = new Subject();
  text: string = "";
  title: string = "";
  extract: IExtract = {} as IExtract;

  ngOnInit(): void {
    this.storage.watchTheme().subscribe({
      next: (data) => {
        this.modeScreen = this.storage.checkTheme();
      },
    });
  }

  close() {
    this.modalRef.hide();
  }
}





<main [ngClass]="{'darkMode': modeScreen.theme == 'dark', 'lightMode': modeScreen.theme == 'light' }">
  <span class="text" [style.color]="modeScreen.color">Olá, {{ user.name ? user.name : '' }} </span>
  <p class="sub_text" [style.color]="modeScreen.color" [style.color]="modeScreen.color">Editar perfil</p>

  <div class="card_info">
    <div class="item" [ngClass]="{'darkMode2': modeScreen.theme == 'dark', 'lightMode2': modeScreen.theme == 'light' }">

      <section class="card_img">

        <img *ngIf="user.profile_image == null || user.profile_image == ''" class="circle bg_circle"
          src="../../assets/images/person_black.svg" alt="Imagem de perfil">

        <img *ngIf="user.profile_image != null && user.profile_image != ''" class="circle" [src]=user.profile_image_url
          alt="Imagem de perfil">

        <div (click)="fileImage.click()" class="update_img">
          <span> Alterar imagem </span>
        </div>
        <input type="file" #fileImage style="display: none;" accept="image/*" (change)="onFileChanged($event)">
      </section>

      <section class="input">
        <div class="left">
          <div class="card_input">
            <span class="font14" [style.color]="modeScreen.color">Nome</span>
            <input class="input" matInput [(ngModel)]="user.name" [style.color]="modeScreen.color"
              [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
              type="text" placeholder="Nome" autocomplete="off"
              [ngStyle]="{'border-color': warningMap.get('name')? 'red' : '#DBDBDB'}">
          </div>

          <div class="card_input">
            <span class="font14" [style.color]="modeScreen.color">Email</span>
            <input class="input" matInput [(ngModel)]="user.email" disabled readonly [style.color]="modeScreen.color"
              [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
              type="email" placeholder="Email" autocomplete="off">
          </div>

          <div class="card_input">
            <span class="font14" [style.color]="modeScreen.color">Empresa</span>
            <input class="input" matInput disabled readonly
              [style.color]="modeScreen.color"
              [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
              type="text" placeholder="Empresa" autocomplete="off">
          </div>
        </div>

        <div class="right">
          <div class="card_input">
            <span class="font14" [style.color]="modeScreen.color">Data de Nacimento</span>
            <input class="input" matInput mask="00/00/0000"
              [dropSpecialCharacters]="false" [style.color]="modeScreen.color"
              [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
              type="text" placeholder="Data de nascimento" autocomplete="off">
          </div>

          <div class="card_input">
            <span class="font14" [style.color]="modeScreen.color">Telefone</span>
            <input class="input" matInput [(ngModel)]="user.cellphone" mask="(00) 00000-0000"
              [style.color]="modeScreen.color"
              [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
              type="text" placeholder="Telefone" autocomplete="off">
          </div>

          <button mat-button (click)="checkInput()" class="btn"
            [ngClass]="{'btn_white': modeScreen.theme == 'dark', 'btn_brown': modeScreen.theme == 'light' }"> Salvar
          </button>
        </div>
      </section>
    </div>
  </div>

</main>

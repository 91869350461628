import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-icon-dark-light',
  templateUrl: './icon-dark-light.component.html',
  styleUrls: ['./icon-dark-light.component.scss']
})
export class IconDarkLightComponent implements OnInit {

  constructor() { }

  toggle: boolean = false;

  ngOnInit(){
    let variable = document.getElementsByTagName('html')

    if (localStorage.getItem('userTheme') == "light"){
      variable.item(0)?.classList.add('ligth');
      this.toggle = true;
    }
    else if(localStorage.getItem('userTheme') == "dark"){
      variable.item(0)?.classList.remove('ligth');
      this.toggle = false;
    }
  }

  changeTheme(){
    let variable = document.getElementsByTagName('html')

    if (localStorage.getItem('userTheme') == "light"){
      variable.item(0)?.classList.remove('ligth');
      localStorage.setItem('userTheme', 'dark');
      this.toggle = false;
    }
    else{
      variable.item(0)?.classList.add('ligth');
      localStorage.setItem('userTheme', 'light')
      this.toggle = true;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalRegisterTariffComponent } from '../../modals/modal-register-tariff/modal-register-tariff.component';
import { Tariff, Tariff_Return } from '../../models/tariff';
import { User } from '../../models/user';
import { AuthService } from '../../services/auth.service';
import { StorageService } from '../../services/storage.service';
import { TariffService } from '../../services/tariff.service';
import { ModalDeleteComponent } from './../../modals/modal-delete/modal-delete.component';

@Component({
  selector: 'app-tariffs',
  templateUrl: './tariffs.component.html',
  styleUrls: ['./tariffs.component.scss'],
})
export class TariffsComponent implements OnInit {
  constructor(
    private storage: StorageService,
    private modalService: MDBModalService,
    private tariffService: TariffService,
    private auth: AuthService,
    private _snackBar: MatSnackBar
  ) {}

  modeScreen = this.storage.checkTheme();
  loading: boolean = true;
  modalRef: any = MDBModalRef;
  modalEdit: any = MDBModalRef;

  user: User = {} as User;
  name_user: string = '';

  page_atual: number = 1;
  count_page: number = 1;
  page_size = 10;

  displayedColumns: string[] = [
    'id',
    'name',
    'value',
    'validity',
    'config',
    'type',
    'buttons',
  ];

  input_search: any = '';
  list_users: any = [];
  user_response: any;

  dataSource = new MatTableDataSource<Tariff>([]);
  tariff_response: Tariff_Return = {} as Tariff_Return;
  list_tariffs: Tariff[] = [];

  ngOnInit(): void {
    this.getMe();
    this.getTariffs(1);

    this.modeScreen = this.storage.checkTheme();
    this.name_user = this.storage.getUser().name;

    this.storage.watchTheme().subscribe((data) => {
      this.modeScreen = this.storage.checkTheme();
    });

    this.storage.watchUser().subscribe((user) => {
      this.name_user = this.storage.getUser().name;
    });
  }

  getMe() {
    this.loading = true;
    this.auth.getMe().subscribe(
      (data) => {
        this.user = data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  getTariffs(page: number) {
    this.loading = true;

    this.tariffService
      .getTariffs(this.page_size, page, this.input_search)
      .subscribe(
        (data) => {
          this.tariff_response = data;
          this.list_tariffs = data.results;
          this.dataSource = new MatTableDataSource(this.list_tariffs);

          this.page_atual = page;
          this.count_page = Math.ceil(data.count / this.page_size);

          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  backPage() {
    if (this.tariff_response.previous) {
      this.getTariffs(this.page_atual - 1);
    }
  }

  nextPage() {
    if (this.tariff_response.next) {
      this.getTariffs(this.page_atual + 1);
    }
  }

  addTariff(type: any, obj?: any) {
    let _aux = Object.assign({}, obj);

    this.modalRef = this.modalService.show(ModalRegisterTariffComponent, {
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-lg',
      containerClass: '',
      animated: false,
      data: {
        modeScreen: this.modeScreen,
        type: type,
        tariff: _aux,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result.action == 'yes') {
        this.getTariffs(1);
      }
    });
  }

  deleteTariff(obj?: any) {
    this.modalRef = this.modalService.show(ModalDeleteComponent, {
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
      data: {
        modeScreen: this.modeScreen,
        title: 'Deletar tarifa',
        text: 'Deseja deletar a tarifa?',
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == 'yes') {
        this.loading = true;
        this.tariffService.deleteTariff(obj).subscribe(
          (data) => {
            this.openSnackBar('Tarifa deletada com sucesso', 'Fechar');
            this.loading = false;
          },
          (error) => {
            this.openSnackBar('Não foi possível deletar a tarifa', 'Fechar');
            this.loading = false;
          }
        );
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 10000,
    });
  }
}

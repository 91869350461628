<div class="modal-content"
  [ngClass]="{'darkModal': modeScreen.theme == 'dark', 'lightModal': modeScreen.theme == 'light' }">

  <div class="modal-header">

    <h1 class="modal-title" [style.color]="modeScreen.color">
      <b> {{ title }} </b>
    </h1>

  </div>

  <div class="modal-body" [style.color]="modeScreen.color">

    {{ text }}

    <div class="card_buttons">
      <button mat-raised-button class="btn-modal" (click)="close()" [style.background]="modeScreen.color"
        [style.color]="modeScreen.color2">
        NÃO
      </button>

      <button mat-raised-button class="btn-modal" (click)="next()" [style.background]="modeScreen.color"
        [style.color]="modeScreen.color2">
        SIM
      </button>
    </div>

  </div>

</div>

<div class="modal-content"
  [ngClass]="{'darkModal': modeScreen.theme == 'dark', 'lightModal': modeScreen.theme == 'light' }">

  <svg (click)="close('no')" class="icons_close" width="25px" height="25px" viewBox="0 0 24 24"
    [style.fill]="modeScreen.color">
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path [style.fill]="modeScreen.color"
      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
  </svg>

  <div class="modal-header">
    <h1 class="modal-title" [style.color]="modeScreen.color">
      <b> {{type == 'edit' ? 'Editar tarifa' : 'Cadastrar tarifa'}} </b>
    </h1>
  </div>

  <div class="modal-body" [style.color]="modeScreen.color">
    <div class="card_input" [style.color]="modeScreen.color" *ngIf="type != 'edit'">
      <label class="font14">Tipo de tarifa</label>
      <mat-form-field [ngClass]="{'border-error': warningMap.get('name')}">
        <mat-select [(ngModel)]="tariff.name">
          <mat-option *ngFor="let type of list_tariff" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="card_input">
      <label class="font14" [style.color]="modeScreen.color">Nome da tarifa</label>
      <input matInput type="text" placeholder="Nome da tarifa" [(ngModel)]="tariff.description_tariff"
        [ngClass]="{'border-error': warningMap.get('description_tariff')}">
    </div>

    <div class="card_input">
      <label class="font14" [style.color]="modeScreen.color">Valor</label>
      <input matInput type="text" placeholder="Valor" [(ngModel)]="tariff.value" mask="separator.2"
        [thousandSeparator]="'.'" [decimalMarker]="','" prefix="R$ "
        [ngClass]="{'border-error': warningMap.get('value')}">
    </div>

    <div class="line_bottom">
      <div class="card_input" [style.color]="modeScreen.color" *ngIf="type != 'edit'">
        <label class="font14">Configuração da tarifa</label>
        <mat-form-field [ngClass]="{'border-error': warningMap.get('type_tariff')}">
          <mat-select [(ngModel)]="tariff.type_tariff">
            <mat-option *ngFor="let type of list_type" [value]="type">
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="btns">
        <button mat-raised-button class="btn-modal" (click)="close('no')" [style.background]="modeScreen.color"
          [style.color]="modeScreen.color2"> Voltar </button>

        <button mat-raised-button class="btn-modal" (click)="checkInput()" [style.background]="modeScreen.color"
          [style.color]="modeScreen.color2"> {{ type == 'edit' ? 'Salvar' : 'Criar'}} </button>
      </div>
    </div>
  </div>
</div>

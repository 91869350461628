<main [ngClass]="{'darkMode': modeScreen.theme == 'dark', 'lightMode': modeScreen.theme == 'light' }">

  <header>
    <div class="card_info">
      <span class="text" [style.color]="modeScreen.color">Olá, {{ user?.name }} </span>
      <p class="sub_text" [style.color]="modeScreen.color">Conta corrente</p>
    </div>

    <!-- Refresh -->
    <button mat-fab class="btn_refresh" aria-label="Botão para atualizar" (click)="refresh()">
      <svg *ngIf="!loading_refresh" height="30" width="30" viewBox="0 0 48 48">
        <path [style.fill]="'var(--primary-text)'"
          d="M23.85 41.25q-7.15 0-12.2-5.05Q6.6 31.15 6.6 24q0-7.15 5.05-12.225Q16.7 6.7 23.85 6.7q4.55 0 8.025 1.825 3.475 1.825 5.975 5.125V6.7h3.6v14.55h-14.6V17.7h8.2q-1.95-2.8-4.7-4.525-2.75-1.725-6.5-1.725-5.25 0-8.9 3.65-3.65 3.65-3.65 8.9t3.65 8.9q3.65 3.65 8.9 3.65 3.9 0 7.225-2.25 3.325-2.25 4.725-5.95h4.85q-1.5 5.75-6.175 9.325Q29.8 41.25 23.85 41.25Z" />
      </svg>

      <span *ngIf="loading_refresh" class="spinner-border spinner-border-gg" role="status" aria-hidden="true">
      </span>
    </button>
  </header>

  <div class="line_top">
    <div class="select">
      <span class="font25" [style.color]="'var(--main-color)'">Conta</span>

      <div class="line line_filter_top">
        <mat-form-field>
          <mat-label style="color: black;"> Cliente <span *ngIf="loading_client" style="margin-left: 10px;"
              class="spinner-border spinner-border-xsm" role="status" aria-hidden="true"></span> </mat-label>
          <mat-select [(ngModel)]="client">
            <mat-option *ngFor="let item of list_clients" [value]="item.id" (click)="getClientId(item)">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label style="color: black;"> Conta <span *ngIf="loading_account" style="margin-left: 10px;"
              class="spinner-border spinner-border-xsm" role="status" aria-hidden="true"></span></mat-label>
          <mat-select [(ngModel)]="account">
            <mat-option *ngFor="let item of list_accounts" [value]="item.id" (click)="loadAccountAmount(item)">
              {{ item.name }} - <span class="uuid"> {{ item.uuid }} </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="line line_filter_bottom">
        <div class="data_main">
          <mat-form-field appearance="fill" (click)="picker.open()">
            <mat-date-range-input [rangePicker]="picker">
              <input title="Data inicial" matStartDate [(ngModel)]="date_from" [style.color]="'black'" readonly>
              <input title="Data final" matEndDate [(ngModel)]="date_to" (dateInput)="selectDates()" [style.color]="'black'" readonly>
            </mat-date-range-input>

            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>

        <button class="btn_white" mat-button (click)="filterExtract(7)"> Últimos 7 dias </button>
        <button class="btn_white" mat-button (click)="filterExtract(15)"> Últimos 15 dias </button>
        <button class="btn_white" mat-button (click)="filterExtract(30)"> Últimos 30 dias </button>
        <img (click)="downloadExtract()" matTooltip="Download"
          [src]="modeScreen.theme == 'dark' ? '../../assets/images/btn_download_white.png' : '../../assets/images/btn_download_black.png'"
          alt="Botão de download">

        <a download="Extrato.xlsx" [href]="file_url_csv" style="display: none;" #downloadCSV id="downloadCSV"></a>
        <a download="Extrato.ofx" [href]="file_url_ofx" style="display: none;" #downloadOFX id="downloadOFX"></a>
        <a download="Extrato.pdf" [href]="file_url_pdf" style="display: none;" #downloadPDF id="downloadPDF"></a>
        <a [download]="cnab240_name" [href]="file_url_ret" style="display: none;" #downloaRet id="downloadRet"></a>
      </div>
    </div>

    <div class="card_balance"
      [ngClass]="{'darkMode2': modeScreen.theme == 'dark', 'lightMode2': modeScreen.theme == 'light' }">
      <div class="line_view">
        <span class="font25" [style.color]="modeScreen.color">Saldo</span>
        <mat-icon [style.color]="modeScreen.color" (click)="visibility = !visibility"> {{ visibility ? 'visibility' :
          'visibility_off' }} </mat-icon>
        <mat-icon [style.color]="modeScreen.color"
          matTooltip="Valores referentes aos tipos de pagamentos Pix, Ted e Boletos."> help_outline </mat-icon>
      </div>

      <p [style.color]="modeScreen.color"> {{ balance_account != null && visibility ?
        (balance_account |
        convertMoney) : '•••' }} </p>

      <div class="limits" *ngIf="list_limits != null" [style.color]="modeScreen.color">

        <div class="limit_item">
          <div style="width: 170px;">
            <p [style.color]="modeScreen.color" class="limit">
              <img class="circle_value" src="assets/images/circle_yellow.png" alt="Círculo green"> Limite diário
            </p>
            <p [style.color]="modeScreen.color"> {{ visibility ? (list_limits.limit_day_pix | convertMoney) : '•••' }}
            </p>
          </div>
          <span [style.background]="modeScreen.color" class="line_vertical"></span>
          <div style="width: 190px; margin-left: 20px;">
            <p [style.color]="modeScreen.color" class="limit"> <img class="circle_value"
                src="assets/images/circle_green.png" alt="Círculo green"> Limite disponível: </p>
            <p [style.color]="modeScreen.color">
              {{ visibility ? ((list_limits.limit_day_pix || 0 -
              (list_limits.value_of_transactions_today || 0)) | convertMoney) : '•••' }} </p>
          </div>
        </div>

        <div class="limit_item">
          <div style="width: 170px;">
            <p [style.color]="modeScreen.color" class="limit"> <img class="circle_value"
                src="assets/images/circle_yellow.png" alt="Círculo green"> Limite mensal </p>
            <p [style.color]="modeScreen.color"> {{ visibility ? (list_limits.limit_month_pix || 0 | convertMoney) : '•••'
              }}
            </p>
          </div>
          <span [style.background]="modeScreen.color" class="line_vertical"></span>
          <div style="width: 190px; margin-left: 20px;">
            <p [style.color]="modeScreen.color" class="limit"> <img class="circle_value"
                src="assets/images/circle_green.png" alt="Círculo green"> Limite disponível: </p>
            <p [style.color]="modeScreen.color"> {{ visibility ? ((list_limits.limit_month_pix || 0 -
              list_limits.value_of_transactions_month || 0) | convertMoney) : '•••' }} </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card_loading" *ngIf="loading_balance">
    <span class="spinner-border spinner-border-gg" role="status" aria-hidden="true"></span>
  </div>

  <div class="line_center" *ngIf="filter_data && !loading_balance">
    <div class="pendencies">
      <div class="top">
        <span class="font25" [style.color]="'var(--main-color)'">Extrato de transações</span>
      </div>

      <p style="margin-top: 25px;" *ngIf="dataSource == null || dataSource.length == 0"> Não há nenhum extrato de
        transação para o banco selecionado </p>

      <section>
        <mat-accordion class="accordion" *ngFor="let info of dataSource; let idx = index">
          <mat-expansion-panel [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Data: {{ info.date | date: 'dd/MM/yyyy' }}
              </mat-panel-title>
              <mat-panel-description>
                <span *ngIf="dataSource.length == (idx+1)"> Saldo inicial: {{ valueInitial | convertMoney }} </span>
                <span *ngIf="dataSource.length != (idx+1)"> Saldo inicial: {{ dataSource[idx+1].balance | convertMoney
                  }} </span>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="card_table">
              <table aria-describedby="table" class="tb_1200" mat-table [dataSource]="info.transactions">

                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef class="font14">
                    Taxa de lançamento
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.transactionType != null && element.transactionType != '' ? (element.transactionType |
                    convertStatusPix) : '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="destinationName">
                  <th mat-header-cell *matHeaderCellDef class="font14">
                    Destinatário
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.destinationName ? element.destinationName : '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="entrance">
                  <th mat-header-cell *matHeaderCellDef class="font14">
                    Entrada (R$)
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ (element | convertCreditDebitTable: account_name ) === 'credit' ? (element.value | convertMoney) : '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="exit">
                  <th mat-header-cell *matHeaderCellDef class="font14">
                    Saída (R$)
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span class="debit"> {{ (element | convertCreditDebitTable: account_name) === 'debit' ? '-' + (element.value |
                      convertMoney) : '-' }} </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="execution">
                  <th mat-header-cell *matHeaderCellDef class="font14">
                    Data de execução
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.executionDate != null ? (element.executionDate | date: 'dd/MM/yyyy HH:mm') : '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="buttons" style="margin-left: 90px">
                  <th mat-header-cell *matHeaderCellDef class="font14">
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="buttons">
                      <mat-icon (click)="viewExtract(element)" matTooltip="Visualizar" matTooltipPosition="above">
                        visibility
                      </mat-icon>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="11" style="text-align: center">
                    Nenhum resultado encontrado.
                  </td>
                </tr>
              </table>
            </div>

            <div class="description_final">
              <p> Saldo final do dia: {{ info.balance | convertMoney }}</p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </section>
    </div>
  </div>
</main>

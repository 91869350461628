import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { StorageService } from '../../services/storage.service';
import { ValidatorUtils } from '../../utils/validator.utils';
import { ILogin } from 'src/app/models/login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private router: Router
  ) {}

  warningMap = new Map();
  validator = new ValidatorUtils();
  loading: boolean = false;
  msg_error_login: string = '';

  captcha: boolean = false;
  localhost: boolean = false;

  login: ILogin = {} as ILogin;

  checked: boolean = false;
  view_password: boolean = false;
  token: string | undefined = undefined;

  ngOnInit(): void {
    if (window.location.hostname == 'localhost') {
      this.localhost = true;
    }
  }

  checkInput() {
    this.warningMap = new Map();
    this.msg_error_login = '';

    !this.login.email && this.warningMap.set('email', true);
    !this.login.password && this.warningMap.set('password', true);
    !this.login.mfa_token && this.warningMap.set('mfa_token', true);

    if (this.warningMap.size > 1) {
      this.msg_error_login = 'Preencha os campos acima';
    } else if (!this.login.email) {
      this.warningMap.set('email', true);
      this.msg_error_login = 'Preencha o campo de email para acessar';
    } else if (!this.login.password) {
      this.warningMap.set('password', true);
      this.msg_error_login = 'Preencha o campo de senha para acessar';
    } else if (!this.login.mfa_token) {
      this.warningMap.set('mfa_token', true);
      this.msg_error_login = 'Preencha o campo de código MFA para acessar';
    } else if (!this.captcha && !this.localhost) {
      this.warningMap.set('captcha', true);
      this.msg_error_login = 'Por favor, confirme que não é um robô';
    }

    if (this.warningMap.size == 0 && !this.loading) {
      this.loading = true;
      this.loginUser();
    }
  }

  loginUser() {
    this.authService.login(this.login).subscribe({
      next: (data) => {
        this.storageService.setAccessToken(data.access);
        this.storageService.setRefreshToken(data.refresh);
        this.storageService.changeUser();

        this.loading = false;
        this.router.navigate(['/home']);
      },
      error: (error) => {
        this.loading = false;

        if (error.error.detail === 'MFA inválido') {
          this.msg_error_login =
            'O MFA fornecido é inválido. Tente novamente';
        } else {
          this.msg_error_login =
            'Não foi possível fazer login, por favor verifique as informações inseridas.';
        }
      },
    });
  }

  onResolved(token: string) {
    this.captcha = true;
  }

  onExpired(response: any) {
    this.captcha = false;
  }

  onError(error: any) {
    this.captcha = false;
  }
}

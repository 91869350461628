import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { colorTheme } from '../utils/theme.utils';
import { User } from './../models/user';

@Injectable({
  providedIn: 'root'
})

export class StorageService {

  constructor(
    private cookieService: CookieService,
  ) {}

  ThemeSubject = new Subject<any>();
  colorTheme = colorTheme;

  user: User = {} as User
  UserSubject = new Subject<any>();

  watchTheme(): Observable<any> {
    return this.ThemeSubject.asObservable();
  }

  changeTheme(): void {
    this.ThemeSubject.next('changed');
  }

  checkTheme() {
    if (localStorage.getItem('userTheme') == null) {
      localStorage.setItem('userTheme', 'dark')
      return this.colorTheme[0];
    }
    else {
      if (localStorage.getItem('userTheme') == 'dark') {
        return this.colorTheme[0];
      }
      else if (localStorage.getItem('userTheme') == 'light') {
        return this.colorTheme[1];
      }
      else {
        return this.colorTheme[0];
      }
    }
  }

  watchUser(): Observable<any> {
    return this.UserSubject.asObservable();
  }

  changeUser(): void {
    this.UserSubject.next('changed');
  }

  setUser(user: User): void {
    this.user = user
  }

  getUser(): User {
    return this.user
  }

  setAccessToken(acessToken: string) {
    this.cookieService.set(
      'access', // Nome do cookie
      acessToken, // Valor do cookie
      undefined, // Tempo de expiração do cookie (em minutos)
      '/', // Caminho do cookie (normalmente definido como '/' para ser acessível em todo o domínio)
      undefined, // Domínio do cookie (normalmente não especificado)
      this.ssl, // Indica se o cookie deve ser configurado como seguro (HTTPS)
      'Strict' // Modo de segurança do cookie (no caso, 'Strict')
    );
  }

  setRefreshToken(refreshToken: string) {
    this.cookieService.set(
      'refresh', // Nome do cookie
      refreshToken, // Valor do cookie
      undefined, // Tempo de expiração do cookie (em minutos)
      '/', // Caminho do cookie (normalmente definido como '/' para ser acessível em todo o domínio)
      undefined, // Domínio do cookie (normalmente não especificado)
      this.ssl, // Indica se o cookie deve ser configurado como seguro (HTTPS)
      'Strict' // Modo de segurança do cookie (no caso, 'Strict')
    );
  }

  getToken(): string {
    return this.cookieService.get('access');
  }

  getRefreshToken(): string {
    return this.cookieService.get('refresh');
  }
  
  logout(): void {
    this.cookieService.deleteAll('/', undefined, this.ssl, 'Strict');
  }

  /**
   * Esta propriedade verifica se a página está sendo servida através do protocolo HTTPS.
   *
   * @returns boolean - Retorna true se a página estiver usando HTTPS e false se estiver usando HTTP.
   * @author Washington Ribeiro
   */
  get ssl() {
    // Verifica se o protocolo da página é 'https:'
    return location.protocol === 'https:';
  }
 
}

import { ValidatorUtils } from './../../../utils/validator.utils';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaasService } from 'src/app/services/baas.service';
import { User } from '../../../models/user';
import { StorageService } from '../../../services/storage.service';
import { MatTableDataSource } from "@angular/material/table";
import { Bank } from 'src/app/models/bank';
// import { BaasComponent } from '../baas.component';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalDeleteComponent } from 'src/app/modals/modal-delete/modal-delete.component';

@Component({
  selector: 'app-details-baas',
  templateUrl: './details-baas.component.html',
  styleUrls: ['./details-baas.component.scss'],
})
export class DetailsBaasComponent implements OnInit {
  constructor(
    private storage: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    private baasService: BaasService,
    // private baasComponent: BaasComponent,
    private modalService: MDBModalService,
  ) {
    this.bank_id = this.route.snapshot.params['id'];
  }

  warningMap = new Map();
  modeScreen: any;
  loading: boolean = false;
  loading_button: boolean = false;
  bank: Bank = {} as Bank;
  bank_id: any;
  page: "new" | "edit" = this.route.snapshot.params['id'] == "new-baas" ? "new" : "edit";
  isEdit: boolean = false;
  user: User = {} as User;
  modalRef: any = MDBModalRef;
  modalEdit: any = MDBModalRef;

  validator = new ValidatorUtils;
  ngOnInit(): void {
    if (this.bank_id != null && this.bank_id != 'new-baas') {
      this.isEdit = true;
      this.getBankId()
    }
    else {
      this.loading = false;
    }
    this.modeScreen = this.storage.checkTheme();
    this.user = this.storage.getUser();

    this.storage.watchTheme().subscribe((data) => {
      this.modeScreen = this.storage.checkTheme();
      this.user = this.storage.getUser();
    });
  }

  getBankId() {
    this.loading = true;

    this.baasService.getBankId(this.bank_id).subscribe(
      (data) => {
        this.bank_id = data.id;
        this.bank = data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.router.navigate(['/baas']);
      }
    )
  }

  postBank() {
    setTimeout(() => {
    this.loading_button = true;
    this.bank.id = this.bank_id;

    this.baasService.postBank(this.bank, this.bank.company).subscribe(data => {
      this.router.navigate(['/baas'])
      this.loading_button = false
    }, error => {
      this.loading_button = false
    })
  }, 100);
  }

  editBank() {
    this.loading = true;

    this.baasService.editBank(this.bank, this.bank.company).subscribe(data => {
      this.getBankId()
      this.bank = data;
      this.bank_id = data.id;
      this.loading = false
      this.router.navigate(['/baas'])
    }, error => {
      this.loading = false;
    })
  }

  checkInput() {

    this.warningMap = new Map();

    if (this.validator.isEmptyOrNull(this.bank.name)) {
      this.warningMap.set('name', true);
    }
    if (this.validator.isEmptyOrNull(this.bank.number)) {
      this.warningMap.set('number', true);
    }
    if (this.validator.isEmptyOrNull(this.bank.company)) {
      this.warningMap.set('company', true);
    }
    // if (!this.bank.host || !this.bank.host.trim()) {
    //   this.warningMap.set('host', true);
    // }

    if(this.warningMap.size == 0){
      if (this.bank_id != null && this.bank_id != 'new-baas') {
        this.editBank();
      }
      else {
        this.postBank();
      }
    }
  }

deleteBankId(id: number) {
  this.baasService.deleteBank(id).subscribe(data => {
      this.router.navigate(['/baas'])
    });
}

deleteBankPopup(id: any) {
  this.modalRef = this.modalService.show(ModalDeleteComponent, {
    keyboard: true,
    focus: true,
    show: true,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered modal",
    containerClass: "",
    animated: false,
    data: {
      title: "Deletar Banco",
      text: "Deseja deletar Banco?",
      modeScreen: this.modeScreen,
    },
  });
  this.modalRef.content.action.subscribe((result: any) => {
    if (result == "yes") {
      this.deleteBankId(id);
    }
  });
}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { headersAuthFile } from '../models/headers';

@Injectable({
  providedIn: 'root',
})
export class LogsService {
  constructor(private http: HttpClient) {}

  getLogsS3(id: number): Observable<any> {
    return this.http
      .get<any>(environment.path + `/finance/get-logs-s3-from-transaction/?id=${id}`, {
        headers: headersAuthFile(),
      })
      .pipe(retry(1));
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Md5 } from 'md5-typescript';
import { Observable, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { headersAuthFile } from '../models/headers';
import { User } from '../models/user';
import { ValidatorUtils } from '../utils/validator.utils';
import { CustomEncoder } from './custom-encoder';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  validator = new ValidatorUtils();

  // LOAD USERS
  getUsers(): Observable<any> {
    return this.http
      .get<any>(environment.path + 'core/get-user/', {
        headers: headersAuthFile(),
      })
      .pipe(retry(1));
  }

  // LOAD USER BY ID
  getUserId(id: any): Observable<User> {
    return this.http
      .get<User>(environment.path + `core/get-user-admin-by-id/?id=${id}`, {
        headers: headersAuthFile(),
      })
      .pipe(retry(1));
  }

  getUsersAll(page: number, input_search: string): Observable<any> {
    let options = {
      params: new HttpParams()
        .set(`page_size`, environment.pageSize)
        .set(`page`, page)
        .set(`name`, input_search),
      headers: headersAuthFile(),
    };

    return this.http
      .get<any>(environment.path + 'core/get-users-admin/', options)
      .pipe(retry(1));
  }

  getPermissions(page: number, search: string): Observable<any> {
    let options = {
      params: new HttpParams().set(`page_size`, environment.pageSize),
      headers: headersAuthFile(),
    };

    if (page != null) options.params = options.params.set(`page`, page);

    if (search != null) options.params = options.params.set(`name`, search);

    return this.http
      .get<any>(environment.path + 'core/list-all-type-of-user/', options)
      .pipe(retry(1));
  }

  postUser(user: User, company: string): Observable<User> {
    const passwordMD5 = Md5.init(user.password).toUpperCase();

    const body = new HttpParams({ encoder: new CustomEncoder() })
      .set(`name`, user.name)
      .set(`email`, user.email)
      .set(`company`, company)
      .set(`type_user`, user.type_user)
      .set(`password`, passwordMD5);

    return this.http
      .post<User>(
        environment.path + `core/create-user-by-admin/`,
        body.toString(),
        { headers: headersAuthFile() }
      )
      .pipe(retry(1));
  }

  editUser(user: User, company: string): Observable<User> {
    let options = {
      headers: headersAuthFile(),
      params: new HttpParams().set(`id`, user.id),
    };
    const body = new HttpParams({ encoder: new CustomEncoder() })
      .set(`name`, user.name)
      .set(`email`, user.email)
      .set(`company`, company)
      .set(`type_user`, user.type_user);

    return this.http
      .patch<User>(
        environment.path + `core/update-user-admin/`,
        body.toString(),
        options
      )
      .pipe(retry(1));
  }

  deleteUser(id: number): Observable<any> {
    let options = {
      headers: headersAuthFile(),
      params: new HttpParams().set(`id`, id),
    };

    return this.http
      .delete<any>(environment.path + `core/del-user-admin/`, options)
      .pipe(retry(1));
  }

  changePassword(user: User): Observable<User> {
    const passwordMD5 = Md5.init(user.password).toUpperCase();

    let options = {
      headers: headersAuthFile(),
      params: new HttpParams().set(`id`, user.id),
    };
    
    const body = new HttpParams({ encoder: new CustomEncoder() }).set(
      `password`,
      passwordMD5
    );

    return this.http.put<User>(
      environment.path + `core/change-password-by-admin/`,
      body.toString(),
      options
    ).pipe(retry(1));
  }

  deleteMfaUser(id: number): Observable<any> {
    let options = {
      headers: headersAuthFile(),
      params: new HttpParams().set(`id`, id),
    };

    return this.http
      .delete<any>(environment.path + `core/delete-mfa-token/`, options)
      .pipe(retry(1));
  }
}

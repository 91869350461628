import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import * as ExcelJS from 'exceljs';
import autoTable from 'jspdf-autotable';
import { Buffer } from 'buffer';
import { StorageService } from 'src/app/services/storage.service';
import { User } from 'src/app/models/user';
import { BankService } from 'src/app/services/bank.service';
import { ClientFinanceService } from 'src/app/services/client-finance.service';
// import { NotifierService } from 'angular-notifier';
import { IClient } from 'src/app/models/client';
import { IAccount, IAccountReturn } from 'src/app/models/account';
import { ILimits } from 'src/app/models/limit';
import { IFilterExtract } from 'src/app/models/filter-extract';
import { ConvertCreditDebitTablePipe } from 'src/app/pipes/convert-credit-debit-table.pipe';
import { Bank } from 'src/app/models/bank';
import { DetailsExtractComponent } from 'src/app/modals/details-extract/details-extract.component';
import { modalConfig } from '../../models/modal.model';
import { ModalSelectDownloadComponent } from 'src/app/modals/modal-select-download/modal-select-download.component';

const doc = new jsPDF({
  orientation: 'landscape',
  unit: 'mm',
  format: 'a4',
});

@Component({
  selector: 'app-current-account',
  templateUrl: './current-account.component.html',
  styleUrls: ['./current-account.component.scss'],
})
export class CurrentAccountComponent implements OnInit {
  constructor(
    private storage: StorageService,
    private bankService: BankService,
    private clientFinanceService: ClientFinanceService,
    private modalService: MDBModalService,
    private sanitizer: DomSanitizer,
    // private notifierService: NotifierService,
    ) {}

  loading_download: boolean = false;

  file_url_csv: any;
  file_url_ofx: any;
  file_url_pdf: any;
  file_url_ret: any;

  cnab240_name: string = ''

  modeScreen = this.storage.checkTheme();

  loading_refresh: boolean = false;
  loading_client: boolean = true;
  filter_data: boolean = false;
  loading_account: boolean = false;
  loading_balance: boolean = false;

  user: User = {} as User;
  list_clients: IClient[] = [];
  client: IClient = {} as IClient;
  account: IAccount = {} as IAccount;

  list_accounts: IAccount[] = [];
  list_limits: ILimits = {
    limit_day_pix: 0,
    limit_month_pix: 0,
    value_of_transactions_month: 0,
    value_of_transactions_today: 0,
  } as ILimits;

  isBank: any;

  balance_account: number = 0;

  modalRefMessage: MDBModalRef = new MDBModalRef();
  convertCreditDebitTable = new ConvertCreditDebitTablePipe();

  date_to: string = '';
  date_from: string = '';

  dataSource: IAccountReturn[] = [];
  objectFinalExtract: IAccountReturn = {} as IAccountReturn;

  valueInitial: number = 0;

  displayedColumns: string[] = [
    'type',
    'destinationName',
    'entrance',
    'exit',
    'execution',
    'buttons',
  ];

  page_atual: number = 1;
  count_page: number = 1;
  filter_extract: IFilterExtract = {} as IFilterExtract;
  visibility: boolean = false;

  account_name: string = ''

  ngOnInit(): void {
    this.user = this.storage.getUser();

    this.storage.watchTheme().subscribe((data) => {
      this.modeScreen = this.storage.checkTheme();
    });

    this.storage.watchUser().subscribe((user) => {
      this.user = this.storage.getUser();
    });

    this.getClientNoPagination();
  }

  refresh() {
    if (this.isBank != null) {
      this.loading_refresh = true;
      this.loadAccountAmount(this.isBank);
    }
  }

  getClientNoPagination() {
    this.bankService.getClientNoPagination().subscribe(
      (data) => {
        this.list_clients = data;
        this.loading_client = false;
      },
      (error) => {
        this.loading_client = false;
      }
    );
  }

  getClientId(client: any) {
    this.loading_account = true;
    this.loading_balance = true;

    this.clientFinanceService.getClientId(client).subscribe(
      (data) => {
        this.list_accounts = data.accounts;
        this.loading_account = false;
        this.loading_balance = false;
      },
      (error) => {
        this.loading_account = false;
        this.loading_balance = false;
      }
    );
  }

  filterExtract(days: number = 0) {
    this.filter_extract.filter_type = 'lastDays';
    this.filter_extract.filter_days = days;

    this.date_from = '';
    this.date_to = 'null';
    this.filter_extract.filter_start_date = '';
    this.filter_extract.filter_end_date = '';

    this.getBankStatement();
  }

  convertDateSendApi(data: string) {
    if (data == null) {
      return null;
    } else {
      var d = new Date(data),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      var aux_data = [year, month, day].join('-');

      return aux_data;
    }
  }

  getLimitAccount(bank: Bank) {
    this.loading_account = true;
    this.bankService.getLimitAccount(bank).subscribe(
      (data) => {
        this.list_limits = data;
        this.loading_account = false;
      },
      (error) => {
        this.loading_account = false;
      }
    );
  }

  getBankStatement() {
    if (
      this.filter_extract.filter_days == null &&
      this.filter_extract.filter_start_date == null &&
      this.filter_extract.filter_end_date == null
    ) {
      this.filter_extract.filter_type = 'lastDays';
      this.filter_extract.filter_days = 7;
    }

    this.loading_balance = true;

    this.bankService.getBankStatement(this.filter_extract).subscribe((data) => {
      this.dataSource = data;
      this.filter_data = true;

      this.objectFinalExtract = data[data.length - 1];
      this.calculateObjectFinal();
    });
  }

  calculateObjectFinal() {
    this.valueInitial = this.objectFinalExtract?.balance;
    this.objectFinalExtract?.transactions.forEach((element: any) => {
      let type = this.convertCreditDebitTable.transform(element, this.account_name);

      // Pela lógica, tenho que somar o negativo e subtrair o positivo
      if (type == 'credit')
        this.valueInitial = this.valueInitial - element.value;
      if (type == 'debit')
        this.valueInitial = this.valueInitial + element.value;
    });

    this.loading_balance = false;
  }

  viewExtract(item: any) {
    this.modalRefMessage = this.modalService.show(DetailsExtractComponent, {
      ...modalConfig,
      data: {
        modeScreen: this.modeScreen,
        extract: item,
      },
    });
  }

  loadAccountAmount(bank: any) {
    this.account_name = bank.name
    this.filter_extract.id = bank.id;
    this.isBank = bank;
    this.loading_balance = true;
    this.bankService.loadAccountAmount(bank.id).subscribe(
      (data) => {
        this.balance_account = data.worked;
        this.loading_balance = false;
        this.loading_refresh = false;

        this.getBankStatement();
        this.getLimitAccount(bank);
      },
      (error) => {
        this.loading_balance = false;
        this.loading_refresh = false;
      }
    );
  }

  selectDates() {
    let first = this.convertDateSendApi(this.date_from);
    let last = this.convertDateSendApi(this.date_to);

    if (first != null && first != '' && last != null && last != '') {
      this.filter_extract.filter_days = -1;
      this.filter_extract.filter_type = 'startDate_endDate';
      this.filter_extract.filter_start_date = first;
      this.filter_extract.filter_end_date = last;
    }

    this.getBankStatement();
  }

  downloadExtract() {
    this.loading_balance = true;

    if (
      this.filter_extract.filter_days == null &&
      this.filter_extract.filter_start_date == null &&
      this.filter_extract.filter_end_date == null
    ) {
      // this.notifierService.notify('error', 'Para fazer download selecione uma data ou a quantidade de dias');
    } else {
      this.selectTypeDownload();
    }
  }

  selectTypeDownload() {
    this.modalRefMessage = this.modalService.show(
      ModalSelectDownloadComponent,
      {
        ...modalConfig,
        class: 'modal-dialog-centered modal-dialog',
      }
    );
    this.modalRefMessage.content.action.subscribe((result: any) => {
      if (result == 'csv') this.downloadCSV();
      if (result == 'ofx') this.downloadOFX();
      if (result == 'pdf') this.downloadPDF();
      if (result == 'cnab240') this.downloadCNAB240();
    });
  }

  downloadCSV() {
    this.bankService.getExtractCSV(this.filter_extract).subscribe(
      (data) => {
        let blob = data.body.slice(0, data.body.size, 'text/xlsx');
        this.file_url_csv = this.sanitizer.bypassSecurityTrustResourceUrl(
          window.URL.createObjectURL(blob)
        );

        setTimeout(() => {
          this.loading_download = false;
          this.loading_balance = false;
          let downloadCSV = document.getElementById('downloadCSV') as HTMLDivElement;
          downloadCSV.click();
        }, 500);

        this.loading_download = false;
      },
      (error) => {
        this.loading_download = false;
        this.loading_balance = false;
        // this.notifierService.notify('error', 'Não há dados para exportar');
      }
    );
  }

  downloadOFX() {
    this.bankService.getExtractOFX(this.filter_extract).subscribe(
      (data) => {
        let blob = data.body.slice(0, data.body.size, 'text/ofx');
        this.file_url_ofx = this.sanitizer.bypassSecurityTrustResourceUrl(
          window.URL.createObjectURL(blob)
        );

        setTimeout(() => {
          this.loading_download = false;
          this.loading_balance = false;
          let downloadOFX = document.getElementById('downloadOFX') as HTMLDivElement;
          downloadOFX.click();
        }, 500);
        this.loading_download = false;
      },
      (error) => {
        this.loading_download = false;
        this.loading_balance = false;
        // this.notifierService.notify('error', 'Não há dados para exportar');
      }
    );
  }

  downloadPDF() {
    const getSheetValues = (worksheet: any) => {
      const rows = worksheet._rows;
      const sheetValues = [];

      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const values = [];
        for (let j = 1; j < row._cells.length + 1; j++) {
          const cell = row.getCell(j);
          if (cell) {
            const value =
              cell.value !== undefined || cell.value !== null ? cell.value : '';
            values.push(value || '');
          } else {
            values.push('');
          }
        }
        sheetValues.push(values);
      }
      return sheetValues;
    };

    const convertBlobToBase64 = (blob: Blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          const dataUrl: any = reader.result;
          const base64 = dataUrl.split(',')[1];
          resolve(base64);
        };
        reader.onerror = () => {
          reject('Erro ao converter o Blob para Base64');
        };
      });
    };

    this.bankService.getExtractCSV(this.filter_extract).subscribe(
      (data) => {
        const blob = new Blob([data.body], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        convertBlobToBase64(blob).then(async (base64) => {
          async function convertBase64XlsxToPdf(base64Xlsx: any) {
            const bytes = Buffer.from(base64Xlsx, 'base64');
            const workbook: any = new ExcelJS.Workbook();
            await workbook.xlsx.load(bytes);
            const worksheet = workbook._worksheets[1];
            const sheets = getSheetValues(worksheet);

            sheets.forEach((sheet) => {
              if (sheet.length < 7) {
                if (sheet.length === 6) {
                  sheet.push('');
                  return;
                }

                if (sheet.length === 5) {
                  sheet.push('', '');
                  return;
                }
                if (sheet.length === 4) {
                  sheet.push('', '', '');
                  return;
                }

                if (sheet.length === 3) {
                  sheet.push('', '', '', '');
                  return;
                }

                if (sheet.length === 2) {
                  sheet.push('', '', '', '', '');
                  return;
                }

                if (sheet.length === 1) {
                  sheet.push('', '', '', '', '', '');
                  return;
                }
              }
            });

            doc.setFillColor(255, 255, 255);
            doc.rect(10, 10, 190, 40, 'F');
            doc.addImage(
              '../../../assets/images/logo-saq-bank.png',
              'PNG',
              200,
              10,
              50,
              40
            );
            doc.setFontSize(15);

            const text = 'Extrato Consolidado';
            const textWidth = doc.getTextWidth(text);

            const docWidth = doc.internal.pageSize.getWidth();
            const centerX = (docWidth - textWidth) / 2;

            doc.text(text, centerX, 10);
            doc.setFontSize(10);
            doc.text(sheets[2][0], 10, 25);
            doc.setFontSize(10);
            doc.text(sheets[4][0], 10, 35);

            doc.setFontSize(10);
            doc.text(new Date().toLocaleDateString('pt-br'), 250, 35);

            const date = new Date();
            const options: any = {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            };

            let hour;
            hour = date.toLocaleString('pt-BR', options);

            doc.setFontSize(10);
            doc.text(hour, 250, 45);
            hour = '';
            autoTable(doc, {
              head: [],
              body: sheets.slice(7),
              startY: 50,
              didParseCell: function (data) {
                if (data.row.index === 0) {
                  data.cell.styles.fontStyle = 'bold';
                }

                if (data.column.index === 4 && data.cell.raw != 'Saída (R$)') {
                  data.cell.styles.textColor = [255, 0, 0];
                }
              },
            });

            doc.save(`Extrato-${new Date().getTime()}.pdf`);
          }

          await convertBase64XlsxToPdf(base64);

          this.loading_balance = false;
          this.loading_refresh = false;
        });
      },
      (error) => {
        this.loading_balance = false;
        this.loading_refresh = false;
        // this.notifierService.notify('error', 'Não há dados para exportar');
      }
    );
  }

  downloadCNAB240() {
    // Obtém a data atual
    const currentDate = new Date();

    // Obtém o dia, mês e ano da data atual
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // O mês começa a partir do zero
    const year = currentDate.getFullYear().toString();

    // Formata a data no formato desejado (DD-MM-YYYY)
    const formattedDate = `${day}-${month}-${year}.ret`;

    this.cnab240_name = formattedDate;

    this.bankService.getExtractCNAB240(this.filter_extract).subscribe(
      (data: any) => {
        let blob = data.body.slice(0, data.body.size, 'text/ret');
        this.file_url_ret = this.sanitizer.bypassSecurityTrustResourceUrl(
          window.URL.createObjectURL(blob)
        );

        setTimeout(() => {
          this.loading_download = false;
          this.loading_balance = false;
          let obj_download = document.getElementById('downloadRet') as HTMLDivElement
          obj_download.click();
        }, 500);
        this.loading_download = false;
      },
      () => {
        this.loading_download = false;
        this.loading_balance = false;
        // this.notifierService.notify('error', 'Não há dados para exportar');
      }
    );
  }

  convertXlsxToPdf(xlsxFile: File) {
    const fileReader = new FileReader();
    const pdf = new jsPDF();

    fileReader.onload = () => {
      const arrayBuffer = fileReader.result as ArrayBuffer;
      const data = new Uint8Array(arrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const html = XLSX.utils.sheet_to_html(worksheet);

      pdf.html(html, {
        callback: () => {
          pdf.save('Extrato.pdf');
        },
      });
    };

    fileReader.readAsArrayBuffer(xlsxFile);
  }
}

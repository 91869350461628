import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { headersAuthFile } from '../models/headers';
import { Tariff, Tariff_Return } from '../models/tariff';

@Injectable({
  providedIn: 'root',
})
export class TariffService {
  constructor(private http: HttpClient) {}

  // Carregar tarifas
  getTariffs(
    page_size: number,
    page: number,
    name: string
  ): Observable<Tariff_Return> {
    const options = {
      params: new HttpParams()
        .set(`page_size`, page_size)
        .set(`page`, page)
        .set(`name`, name),

      headers: headersAuthFile(),
    };

    return this.http
      .get<Tariff_Return>(environment.path + 'core/list-tariffs/', options)
      .pipe(retry(1));
  }

  // Criar tarifa
  postTariff(tariff: Tariff): Observable<Tariff> {
    const body = new HttpParams()
      .set(`name`, tariff.name)
      .set(`value`, tariff.value)
      .set(`type_tariff`, tariff.type_tariff)
      .set(`description_tariff`, tariff.description_tariff);

    return this.http
      .post<Tariff>(environment.path + 'core/create-tariff/', body.toString(), {
        headers: headersAuthFile(),
      })
      .pipe(retry(1));
  }

  // Editar tarifa
  editTariff(tariff: Tariff): Observable<Tariff> {
    const body = new HttpParams()
      .set(`id`, tariff.id)
      .set(`value`, tariff.value)
      .set(`description_tariff`, tariff.description_tariff);

    return this.http
      .patch<Tariff>(
        environment.path + `core/update-tariff/`,
        body.toString(),
        { headers: headersAuthFile() }
      )
      .pipe(retry(1));
  }

  // Deletar tarifa
  deleteTariff(tariff: Tariff): Observable<Tariff> {
    let options = {
      headers: headersAuthFile(),
      params: new HttpParams(),
    };

    return this.http
      .delete<any>(
        environment.path + `core/delete-tariff/?id=${tariff.id}`,
        options
      )
      .pipe(retry(1));
  }

  // Criar tarifa na empresa
  postTariffInCompany(company_id: any, tariff_company: any): Observable<any> {
    let _start_date: any = tariff_company?.start_date
      ? new Date(tariff_company.start_date).toISOString().split('T')[0]
      : null;
    let _end_date: any = tariff_company?.end_date
      ? new Date(tariff_company.end_date).toISOString().split('T')[0]
      : null;

    console.log(tariff_company);

    let body = new HttpParams()
      .set(`company_id`, company_id)
      .set(`tariff_id`, tariff_company.tariff_type)
      .set(`discount`, tariff_company.value_discount);

    if (_start_date != null) body = body.set(`start_date`, _start_date);
    if (_end_date != null) body = body.set(`end_date`, _end_date);

    return this.http
      .post<any>(
        environment.path + 'core/add-tariff-in-company/',
        body.toString(),
        { headers: headersAuthFile() }
      )
      .pipe(retry(1));
  }

  // Atualizar tarifa na empresa
  updateTariffInCompany(tariff: any): Observable<any> {
    let _start_date: any = tariff?.start_date
      ? new Date(tariff.start_date).toISOString().split('T')[0]
      : '';
    let _end_date: any = tariff?.end_date
      ? new Date(tariff.end_date).toISOString().split('T')[0]
      : '';

    console.log(tariff.value_discount);

    let body = new HttpParams()
      .set(`company_tariff_id`, tariff.id)
      .set(`discount`, tariff.value_discount)
      .set(`start_date`, _start_date)
      .set(`end_date`, _end_date);

    return this.http
      .patch<any>(
        environment.path + 'core/update-tariff-in-company/',
        body.toString(),
        { headers: headersAuthFile() }
      )
      .pipe(retry(1));
  }

  // Deletar tarifa na empresa
  deleteTariffInCompany(tariff: Tariff): Observable<Tariff> {
    let options = {
      headers: headersAuthFile(),
      params: new HttpParams(),
    };

    return this.http
      .delete<any>(
        environment.path + `core/delete-tariff-in-company/?id=${tariff.id}`,
        options
      )
      .pipe(retry(1));
  }

  // Deletar fatura na empresa
  deleteInvoice(id: number): Observable<Tariff> {
    let options = {
      headers: headersAuthFile(),
      params: new HttpParams(),
    };

    return this.http
      .delete<any>(
        environment.path + `core/delete-invoice/?id=${id}`,
        options
      )
      .pipe(retry(1));
  }
}

<div class="modal-content">

  <main [ngClass]="{'darkMode': modeScreen.theme == 'dark', 'lightMode': modeScreen.theme == 'light' }">

    <header>
      <div class="title" [style.color]="modeScreen.color"> Detalhes do Extrato </div>
      <svg (click)="close()" class="icons_close" width="25px" height="25px" viewBox="0 0 24 24"
      [style.fill]="modeScreen.color">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path [style.fill]="modeScreen.color"
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
    </svg>
    </header>

    <section class="card_info">
      <div class="row_info">
        <h3 [style.color]="modeScreen.color"> Nome do remetente : </h3>
        <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
        <p [style.color]="modeScreen.color"> {{ extract.sourceName != null ? extract.sourceName : '-' }} </p>
      </div>

      <div class="row_info">
        <h3 [style.color]="modeScreen.color"> Nome do destinatário : </h3>
        <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
        <p [style.color]="modeScreen.color"> {{ extract.destinationName != null ? extract.destinationName : '-' }} </p>
      </div>

      <div class="row_info">
        <h3 [style.color]="modeScreen.color"> Tipo de transação : </h3>
        <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
        <p [style.color]="modeScreen.color"> {{ extract.transactionType != null ? (extract.transactionType | convertStatusPix) : '-' }} </p>
      </div>

      <div class="row_info">
        <h3 [style.color]="modeScreen.color"> Data de execução : </h3>
        <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
        <p [style.color]="modeScreen.color"> {{ extract.executionDate | date:'dd/MM/yyyy HH:mm:ss' }}
        </p>
      </div>

      <div class="row_info">
        <h3 [style.color]="modeScreen.color"> Valor : </h3>
        <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
        <p [style.color]="modeScreen.color"> {{ extract.value != null ? (extract.value | convertMoney) : '-' }} </p>
      </div>
    </section>

  </main>
</div>

<main [ngClass]="{'darkMode': modeScreen.theme == 'dark', 'lightMode': modeScreen.theme == 'light' }">

  <header>
    <div class="card_row">
      <div routerLink="/companies-financial/{{companies_id}}">
        <svg class="icon_back" height="48" width="48">
          <path [style.fill]="modeScreen.color" d="M20 44 0 24 20 4 22.8 6.85 5.65 24 22.8 41.15Z" />
        </svg>
      </div>
      <div class="title" [style.color]="modeScreen.color"> Detalhes da Fatura </div>
    </div>
  </header>

  <div class="card_loading" *ngIf="loading">
    <span style="margin-left: 5px;" [style.color]="modeScreen.color" class="spinner-border spinner-border-sm"
      role="status" aria-hidden="true"></span>
  </div>

  <section *ngIf="!loading" class="card_info">

    <div class="column_info">
      <div class="row_info">
        <h3 [style.color]="modeScreen.color"> Valor : </h3>
        <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
        <p [style.color]="modeScreen.color"> {{ invoice?.value != null ? this.converters.convertMoney(invoice?.value) :
          '-' }} </p>
      </div>

      <div class="row_info">
        <h3 [style.color]="modeScreen.color"> Status : </h3>
        <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
        <p [style.color]="modeScreen.color"> {{ invoice?.status != null ? invoice.status : '-' }} </p>
      </div>

      <div class="row_info">
        <h3 [style.color]="modeScreen.color"> Desconto : </h3>
        <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
        <p [style.color]="modeScreen.color"> {{ invoice?.discount | convertPercent }} </p>
      </div>

      <div class="row_info">
        <h3 [style.color]="modeScreen.color"> Id : </h3>
        <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
        <p [style.color]="modeScreen.color"> {{ invoice?.id != null ? invoice?.id : '-' }} </p>
      </div>

      <div class="row_info">
        <h3 [style.color]="modeScreen.color"> Valor Total : </h3>
        <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
        <p [style.color]="modeScreen.color"> {{ invoice?.total_value != null ?
          this.converters.convertMoney(invoice?.total_value) : '-' }} </p>
      </div>

      <div class="row_info">
        <h3 [style.color]="modeScreen.color"> Data de criação : </h3>
        <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
        <p [style.color]="modeScreen.color"> {{ invoice?.created_at | date: 'dd/MM/yyyy HH:mm' }} </p>
      </div>
    </div>
  </section>

  <section *ngIf="!loading">

    <section *ngFor="let tariff of invoice?.tariffs">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p [style.color]="modeScreen.color">{{ tariff.description_tariff }}</p>
              <p [style.color]="modeScreen.color"> Quantidade: {{ tariff.quantity || 0 }}</p>
              <p [style.color]="modeScreen.color">{{ tariff.total_value | convertMoney }}</p>
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="card_table" *ngIf="tariff.tariff_detail_day.length > 0">
            <table aria-describedby="table" mat-table [dataSource]="tariff.tariff_detail_day">
              <ng-container matColumnDef="day">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Dia
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.day ? (element.day | date: 'dd/MM/yyyy') : '-' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Quantidade
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.quantity }}
                </td>
              </ng-container>

              <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Valor
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ this.converters.convertMoney(element.value) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="discount">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Desconto
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.discount | convertPercent }}
                </td>
              </ng-container>

              <ng-container matColumnDef="total_value">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Valor Total
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ this.converters.convertMoney(element.total_value) }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="invoicesColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: invoicesColumns"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="11" style="text-align: center">
                  Nenhum resultado encontrado.
                </td>
              </tr>
            </table>
          </div>

          <div class="card_table" *ngIf="tariff.tariff_detail_day.length === 0">
            <table aria-describedby="table" mat-table [dataSource]="transformTariffDataSource(tariff)">

              <ng-container matColumnDef="type_tariff">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Configuração
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.type_tariff != null && element.type_tariff != '' ? element.type_tariff : '-' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Valor
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ this.converters.convertMoney(element?.value) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="discount">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Desconto
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.discount | convertMoney }}
                </td>
              </ng-container>

              <ng-container matColumnDef="total_value">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Valor Total
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ this.converters.convertMoney(element?.total_value) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Criado em
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.created_at | date: 'dd/MM/yyyy HH:mm' }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="invoicesNullColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: invoicesNullColumns"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="11" style="text-align: center">
                  Nenhum resultado encontrado.
                </td>
              </tr>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </section>

  </section>
</main>
<div class="modal-content"
  [ngClass]="{'darkModal': modeScreen.theme == 'dark', 'lightModal': modeScreen.theme == 'light' }">

  <div class="modal-header">

    <h1 class="modal-title" [style.color]="modeScreen.color">
      <b> {{title}} </b>
    </h1>

  </div>

  <div class="modal-body">

    <div class="card_input_title">
      <span [style.color]="modeScreen.color"> Senha <span class="msg_erro" *ngIf="warningMap.get('password')"> {{
          checkInputMessage(user.password, 'password') }} </span> </span>
      <input matInput type="password" [style.color]="modeScreen.color" [(ngModel)]="user.password" placeholder="*****"
        [ngStyle]="{'border-color': warningMap.get('password')? 'red' : ''}" autocomplete="false">
    </div>

    <div class="card_input_title">
      <span [style.color]="modeScreen.color"> Confirmar Senha <span class="msg_erro"
          *ngIf="warningMap.get('confirm_password')">
          {{ checkInputMessage(user.confirm_password, 'confirm_password', 'password')}} </span> </span>
      <input matInput type="password" [style.color]="modeScreen.color" [(ngModel)]="user.confirm_password"
        placeholder="*****" [ngStyle]="{'border-color': warningMap.get('confirm_password')? 'red' : ''}"
        autocomplete="false">
    </div>

    <div class="card_buttons">
      <button mat-raised-button class="btn-modal" (click)="close()" [style.background]="modeScreen.color"
        [style.color]="modeScreen.color2">
        Cancelar
      </button>

      <button mat-raised-button class="btn-modal" [style.background]="modeScreen.color" (click)="checkInput()"
        [style.color]="modeScreen.color2">
        Confirmar
        <span *ngIf="loading" style="margin-left: 5px;" [style.color]="modeScreen.color2"
          class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </button>
    </div>

  </div>

</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertStatusLead'
})
export class ConvertStatusLeadPipe implements PipeTransform {

  transform(status: string): unknown {

    let result =
      status == "NEW" ? "Novo" :
        status == "APPROVED" ? "Aprovado" :
          status == "CANCELED" ? "Cancelado" :
            status == "PROCESSING" ? "Em processamento" :
              status == "SUCCESS" ? "Sucesso" :
                status == "WAITING REPROCESSING" ? "Aguardando Reprocessamento" :
                  status == "AWAITING BANK CONFIRMATION" ? "Aguardando Confirmação" :
                    status == "ERROR" ? "Erro" : '-'

    return result
  }
}

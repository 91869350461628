import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { StorageService } from '../../services/storage.service';
import { User } from '../../models/user';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  constructor(
    private router: Router,
    private storage: StorageService,
    private authService: AuthService
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd || ev instanceof NavigationStart) {
        let url_aux = ev.url.split('/');
        this.url = url_aux[1];
      }
    });
  }

  modeScreen: any;
  url: string = '';

  user: User = {} as User;
  open_screen: boolean = false;

  loading: boolean = false;
  panelOpenState = false;

  permissions = {
    checking_account: false,
    payments: false,
    reports: false,
    receivables: false,
    lead_management: false,
  };

  order_permissions: any = [];

  ngOnInit(): void {
    this.modeScreen = this.storage.checkTheme();
    this.userMe();

    this.storage.watchUser().subscribe((data) => {
      this.authService.getMe().subscribe((user) => {
        this.userMe();
      });
    });
  }

  userMe() {
    this.authService.getMe().subscribe((user) => {
      this.user = user;
      this.storage.setUser(user);
      this.loading = false;
    });
  }

  styleMode() {
    if (this.modeScreen.theme == 'dark') {
      this.modeScreen = this.storage.colorTheme[1];
      localStorage.setItem('userTheme', 'light');
    } else {
      this.modeScreen = this.storage.colorTheme[0];
      localStorage.setItem('userTheme', 'dark');
    }
    this.storage.changeTheme();
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}

<main>
  <div class="center">

    <div class="date">
      <div class="card_logo">
        <img src="../assets/images/saQ-digital.png" class="logo" alt="logo do saq digital" />
      </div>

      <div class="card_input">
        <input type="text" placeholder="E-mail" [(ngModel)]="login.email" [ngClass]="{'error': warningMap.get('email')}">

        <input type="password" placeholder="Senha" [(ngModel)]="login.password"
          [ngClass]="{'error': warningMap.get('password')}">

        <input type="string" mask="000000" placeholder="Código MFA" [(ngModel)]="login.mfa_token" (keyup.enter)="checkInput()"
          [ngClass]="{'error': warningMap.get('mfa_token')}">
      </div>

      <div class="checkbox">
        <mat-checkbox [(ngModel)]="checked">
          Manter conectado
        </mat-checkbox>

        <a routerLink="/"> Esqueci minha senha </a>
      </div>

      <div class="captcha">
        <re-captcha (resolved)="onResolved($event)" (expired)="onExpired($event)" (errored)="onError($event)"
          errorMode="handled" id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required
          [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
      </div>

      <div class="card_button">
        <button mat-raised-button (click)="checkInput()" class="btn_brown">
          Acessar <span *ngIf="loading" style="margin-left: 5px;" class="spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span>
        </button>

        <div *ngIf="msg_error_login != ''" class="error_login">
          {{ msg_error_login }}
        </div>
      </div>
    </div>

  </div>
</main>
import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { Tariff } from 'src/app/models/tariff';
import { TariffService } from 'src/app/services/tariff.service';

@Component({
  selector: 'app-modal-register-tariff',
  templateUrl: './modal-register-tariff.component.html',
  styleUrls: ['./modal-register-tariff.component.scss']
})
export class ModalRegisterTariffComponent {

  constructor(
    public modalRef: MDBModalRef,
    private tariffService: TariffService
  ) { }

  action: Subject<any> = new Subject();
  modeScreen: any;
  type: any = null;
  warningMap = new Map();

  tariff: Tariff = {
    value: 0
  } as Tariff

  list_type: string[] = ['Mensal', 'Serviço']
  list_tariff: string[] = ['Mensalidade SAQ Financeiro', 'Mensalidade SAQ Logística', 'Mensalidade SAQ Energia', 'QR Code Pago', 'QR Code Gerado', 'Transação Pix Paga', 'Customizado', 'Mensagens de Whatsapp Enviadas']

  ngOnInit() {
    if (!this.tariff) this.tariff = {} as Tariff;
  }

  close(action: 'yes' | 'no') {
    this.action.next(action);
    this.modalRef.hide();
  }

  checkInput() {
    this.warningMap = new Map();

    if (!this.tariff.name) {
      this.warningMap.set('name', true);
    }

    if (!this.tariff.description_tariff && this.type !== 'edit') {
      this.warningMap.set('description_tariff', true);
    }

    if ((this.tariff.value !== null || this.tariff.value !== '') && this.tariff.value !== 0) {
      this.warningMap.set('value', true);
    }

    if (!this.tariff.type_tariff && this.type !== 'edit') {
      this.warningMap.set('type_tariff', true);
    }

    if (this.warningMap.size == 0) {
      if (this.type == 'edit') {
        this.editTariff()
      }
      else {
        this.createTariff()
      }
    }
  }

  createTariff() {
    this.tariffService.postTariff(this.tariff).subscribe(data => {
      this.action.next({ action: 'yes', tariff: data });
      this.modalRef.hide();
    })
  }

  editTariff() {
    this.tariffService.editTariff(this.tariff).subscribe(data => {
      this.action.next({ action: 'yes', tariff: data });
      this.modalRef.hide();
    })
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertDateInputToApi'
})
export class ConvertDateInputToApiPipe implements PipeTransform {

  transform(data: string): string {
    if (data == null || data == '') return data
    let separations = data.split('/')

    let day = separations[0]
    let month = separations[1]
    let year = separations[2]

    return `${year}-${month}-${day}`
  }
}

<div class="modal-content"
  [ngClass]="{'darkModal': modeScreen.theme == 'dark', 'lightModal': modeScreen.theme == 'light' }">

  <div class="modal-header">

    <h1 class="modal-title" [style.color]="modeScreen.color">
      <b> {{title}} </b>
    </h1>
  </div>

  <div class="modal-body" [style.color]="modeScreen.color">
    <div class="input">
      <label>Name</label>
      <input type="text" class="card_input" [(ngModel)]="user.name" placeholder="nome">
      <label>Celular</label>
      <input type="text" class="card_input" [(ngModel)]="user.cellphone" placeholder="Celular">
    </div>
    {{text}}

    <div clas="card_buttons">
      <button mat-raised-button class="btn-modal" [style.background]="modeScreen.color" (click)="editUser()"
        [style.color]="modeScreen.color2">
        Confirmar
      </button>
      <button mat-raised-button class="btn-modal" (click)="close()" [style.background]="modeScreen.color"
        [style.color]="modeScreen.color2">
        Cancelar
      </button>
    </div>
  </div>
</div>

import { HttpHeaders } from '@angular/common/http';

export interface IReturnAPI<T> {
  count: number;
  next: string
  previous: string;
  results: T[];
}

export interface IReturnMessage {
  detail: string
}

export const headersFile = () => new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

export const headersAuthFile = () => new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
  .set('Authorization', `Bearer ${localStorage.getItem('access')}`);

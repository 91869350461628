import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Bank } from '../../models/bank';
import { BaasService } from '../../services/baas.service';
import { StorageService } from '../../services/storage.service';
import { MatTableDataSource } from "@angular/material/table";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalDeleteComponent } from '../../modals/modal-delete/modal-delete.component';

@Component({
  selector: 'app-baas',
  templateUrl: './baas.component.html',
  styleUrls: ['./baas.component.scss'],
})
export class BaasComponent implements OnInit {
  constructor(
    private storage: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    private baasService: BaasService,
    private modalService: MDBModalService
    ) {
      this.bank.id = this.route.snapshot.params['id'];
    }

  modeScreen = this.storage.checkTheme()
  loading: boolean = false
  bank: Bank = {} as Bank;
  company: any
  name_user: string = ''
  text_input: string = ''
  page_atual: number = 1
  count_page: number = 1
  id: any

  modalRef: any = MDBModalRef
  modalEdit: any = MDBModalRef

   /* pagination related variables */
   campaign_response: any
   page: number = 1
   count_total: number = 0
   page_count1: number = 0
   page_count2: number = 0
   page_size = 15;

  displayedColumns: any[] = [
    'name',
    'number',
    'company',
    'buttons',
  ];

  list_bank: any = []
  list_users: any = []
  data_response: any

  dataSource = new MatTableDataSource<any>()

  ngOnInit(): void {
    this.modeScreen = this.storage.checkTheme()
    this.name_user = this.storage.getUser().name

    this.storage.watchTheme().subscribe(data => {
      this.modeScreen = this.storage.checkTheme()
    })

    this.storage.watchUser().subscribe(user => {
      this.name_user = this.storage.getUser().name
    })

    this.getBanks()
  }

  getBanks() {
    this.loading = true;
    this.baasService.getBanks(this.page, this.bank, this.text_input).subscribe(
      (data) => {
        this.dataSource = new MatTableDataSource(data.results);
        this.loading = false;

        // pagination calc
        this.count_total = data.count;
        this.page_count1 = 1 + (this.page - 1) * this.page_size;
        if (this.page_count1 + (this.page_size - 1) > this.count_total) {
          this.page_count2 = this.count_total;
        } else {
          this.page_count2 = this.page_count1 + (this.page_size - 1);
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  editBank() {
    this.loading = true;
    this.baasService.editBank(this.bank, this.company).subscribe(data => {
      this.router.navigate(['/baas'])
      this.loading = false
    }, error => {
      this.loading = false
    })
  }

  deleteBankId(id: number) {
    this.baasService.deleteBank(id).subscribe(
      (data) => {
        this.getBanks();
      });
  }

  deleteBankPopup(id: any) {
    this.modalRef = this.modalService.show(ModalDeleteComponent, {
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal",
      containerClass: "",
      animated: false,
      data: {
        title: "Deletar Banco",
        text: "Deseja deletar Banco?",
        modeScreen: this.modeScreen,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "yes") {
        this.deleteBankId(id);
      }
    });
  }

  dateFormat(date: string) {
    const [year, month, day] = date.split('-');
    if (year && month && day) {
      return `${day}/${month}/${year}`;
    } else {
      return date;
    }
  }

  backPage() {
    if (this.data_response.previous != null) {
      this.page -= 1;
      this.getBanks();
    }
  }

  nextPage() {
    if (this.data_response.next != null) {
      this.page += 1;
      this.getBanks();
    }
  }
}


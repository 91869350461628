import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IClient, IClientId } from '../models/client';
import { IReturnAPI, headersAuthFile } from '../models/headers';
import { CustomEncoder } from './custom-encoder';

@Injectable({
  providedIn: 'root',
})
export class ClientFinanceService {
  constructor(private http: HttpClient) {}

  // Carregar todos os clientes]
  getClients(name: string): Observable<IReturnAPI<IClient>> {
    const options = {
      headers: headersAuthFile(),
      params: new HttpParams()
        .set(`page_size`, environment.pageSize.toString())
        .set(`name`, name),
    };

    return this.http.get<IReturnAPI<IClient>>(
      environment.path + `finance/get-client/`,
      options
    ).pipe(retry(1));
  }

  // Carregar cliente pelo ID
  getClientId(client: IClient): Observable<IClientId> {
    return this.http.get<IClientId>(
      environment.path + `finance/get-client-by-id-super-admin/?id=${client.id}`,
      { headers: headersAuthFile() }
    ).pipe(retry(1));
  }

  createClient(name: string): Observable<any> {
    let body = new HttpParams({ encoder: new CustomEncoder() }).set(
      `name`,
      name
    );

    return this.http.post<any>(
      environment.path + `finance/create-client/`,
      body,
      { headers: headersAuthFile() }
    ).pipe(retry(1));
  }

  // Carregar chaves pix do cliente pelo ID
  getKeysAccountClient(id: number): Observable<any> {
    return this.http.get<IClient>(
      environment.path + `finance/load-keys-from-account-bank/?id=${id}`,
      { headers: headersAuthFile() }
    ).pipe(retry(1));
  }

  updateCustomName(id: number, custom_name: string): Observable<any> {
    let body = new HttpParams({ encoder: new CustomEncoder() })
      .set(`id`, id)
      .set(`custom_name`, custom_name);

    return this.http.patch<any>(
      environment.path + `finance/update-account-bank/`,
      body.toString(),
      { headers: headersAuthFile() }
    ).pipe(retry(1));
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertPercent'
})
export class ConvertPercentPipe implements PipeTransform {

  transform(value: number): string {
    let valuePercent = value != null ? `${value.toFixed(2)} %` : '-'
    return valuePercent
  }

}

import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private storage: StorageService,
  ) { }

  modeScreen: any
  name_user: string = ''
  loading: boolean = false

  ngOnInit(): void {
    this.modeScreen = this.storage.checkTheme();
    this.name_user = this.storage.getUser().name

    this.storage.watchTheme().subscribe(data => {
      this.modeScreen = this.storage.checkTheme();
    })

    this.storage.watchUser().subscribe(user => {
      this.name_user = this.storage.getUser().name
    })
  }
}

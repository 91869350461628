import { Component } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';

@Component({
  selector: 'modal-choice',
  templateUrl: './modal-choice.component.html',
  styleUrls: ['./modal-choice.component.scss']
})

export class ModalChoiceComponent {

  constructor(public modalRef: MDBModalRef) { }

  action: Subject<any> = new Subject();
  text: string = "";
  title: string = "";
  modeScreen: any;

  closePopup() {
    this.modalRef.hide();
  }

  close(choice: string) {
    this.action.next(choice);
    this.modalRef.hide();
  }

}

<div class="modal-content"
  [ngClass]="{'darkModal': modeScreen.theme == 'dark', 'lightModal': modeScreen.theme == 'light' }">

  <svg (click)="closePopup()" class="icons_close" width="25px" height="25px" viewBox="0 0 24 24"
    [style.fill]="modeScreen.color">
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path [style.fill]="modeScreen.color"
      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
  </svg>


  <div class="modal-header">

    <h1 class="modal-title" [style.color]="modeScreen.color">
      <b> {{title}} </b>
    </h1>

  </div>

  <div class="modal-body" [style.color]="modeScreen.color">

    {{text}}

    <div style="width: 100%; margin-top: 40px; margin-bottom: 5px;">
      <button mat-raised-button class="btn-modal" (click)="close('no')" [style.background]="modeScreen.color"
        [style.color]="modeScreen.color2">
        NÃO
      </button>

      <button mat-raised-button class="btn-modal" (click)="close('yes')" [style.background]="modeScreen.color"
        [style.color]="modeScreen.color2">
        SIM
      </button>
    </div>

  </div>

</div>

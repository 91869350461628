import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalChoiceComponent } from '../../modals/modal-choice/modal-choice.component';
import { ModalMessageComponent } from '../../modals/modal-message/modal-message.component';
import { Transfer } from '../../models/transfer';
import { User } from '../../models/user';
import { ValuesAcceptAll } from '../../models/values-aceptall';
import { StorageService } from '../../services/storage.service';
import { TransferService } from '../../services/transfer.service';
import { Converters } from '../../utils/converters';
import { DetailsTransferComponent } from './details-transfer/details-transfer.component';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss'],
})
export class TransferComponent implements OnInit {
  constructor(
    private storage: StorageService,
    private transferService: TransferService,
    private modalService: MDBModalService,
    private _snackBar: MatSnackBar
  ) {}

  modalRef: any = MDBModalRef;
  modalRefMessage: MDBModalRef = new MDBModalRef();

  loading_refresh: boolean = false;

  modeScreen: any;
  loading: boolean = false;
  user: User = {} as User;
  text_input: string = '';
  type_status: string = 'NEW';

  transfer: Transfer = {} as Transfer;
  data_response: any;

  list_size = ['5', '10', '20', '30', '40'];
  page_size: any = '10';
  page_atual: number = 1;
  count_page: number = 1;

  allComplete: boolean = false;
  valueTotal: number | any = null;
  count_items: number | any = null;

  list_checkbox_select: any = [];

  count = {
    all: 0,
    new: 0,
    approved: 0,
    canceled: 0,
    processing: 0,
    success: 0,
    error: 0,
    waiting_reprocessing: 0,
  };

  displayedColumns: string[] = [
    'checkbox',
    'name',
    'company',
    'status_receipt',
    'status_saq',
    'amount',
    'created_at',
    'updated_at',
    'buttons',
  ];
  dataSourceTransfer: any = [];

  list_clients: any = [];
  client: any;
  loading_client: boolean = true;

  list_accounts: any = [];
  account: any;
  loading_account: boolean = false;

  values_transaction: ValuesAcceptAll = {} as ValuesAcceptAll;
  converters = new Converters();

  status_close: any = [];
  btn_reprocess: boolean = false;

  is_type_error_select: boolean = false;

  date_from: any = new Date();
  date_to: any = new Date();

  ngOnInit(): void {
    this.modeScreen = this.storage.checkTheme();
    this.user = this.storage.getUser();

    localStorage.getItem('size_page_transfer')
      ? (this.page_size = localStorage.getItem('size_page_transfer'))
      : '10';

    this.storage.watchTheme().subscribe((data) => {
      this.modeScreen = this.storage.checkTheme();
    });

    this.storage.watchUser().subscribe((user) => {
      this.user = this.storage.getUser();
    });

    this.tableStatus(1, 'NEW', this.page_size);
    this.getClientNoPagination();
  }

  convertDateSendApi(data: string) {
    if (data == null) {
      return null;
    } else {
      var d = new Date(data),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      var aux_data = [year, month, day].join('-');

      return aux_data;
    }
  }

  getClientNoPagination() {
    this.transferService.getClientNoPagination().subscribe((data) => {
      this.list_clients = data;
      this.loading_client = false;
    });
  }

  getClientId(client: any) {
    this.loading_account = true;

    this.transferService.getClientId(client.id).subscribe((data) => {
      this.list_accounts = data.accounts;
      this.loading_account = false;
    });
  }

  getTransfer(page: number, status: string, page_size: string) {
    this.transfer.status = status;

    if (this.transfer != null && this.account != null) {
      this.loading = true;
      let _date_from: any = this.convertDateSendApi(this.date_from);
      let _date_to: any = this.convertDateSendApi(this.date_to);

      this.transferService
        .getTransfer(
          page,
          this.transfer,
          this.account,
          page_size,
          this.text_input,
          _date_from,
          _date_to
        )
        .subscribe(
          (data) => {
            this.count.all = data.total_transaction;
            this.count.new = data.total_new_status;
            this.count.approved = data.total_approved_status;
            this.count.canceled = data.total_canceled_status;
            this.count.processing = data.total_processing_status;
            this.count.success = data.total_success_status;
            this.count.error = data.total_error_status;
            this.count.waiting_reprocessing =
              data.total_waiting_reprocessing_status;

            data.results.forEach((element: any) => {
              element['checkbox'] = false;
            });

            this.dataSourceTransfer = new MatTableDataSource(data.results);

            this.data_response = data;
            this.page_atual = page;
            this.count_page = Math.ceil(data.count / Number(this.page_size));

            this.loading = false;
            this.loading_refresh = false;
          },
          (error) => {
            this.loading = false;
            this.loading_refresh = false;
          }
        );
    }
  }

  refresh() {
    if (this.page_size != null && this.account != null) {
      this.loading_refresh = true;
      this.selectsize(this.page_size);
    }
  }

  selectsize(item: string) {
    this.page_size = item;
    this.getTransfer(1, this.type_status, item);
    localStorage.setItem('size_page_transfer', item);
  }

  backPage() {
    if (this.data_response.previous) {
      this.tableStatus(this.page_atual - 1, this.type_status, this.page_size);
    }
  }

  nextPage() {
    if (this.data_response.next) {
      this.tableStatus(this.page_atual + 1, this.type_status, this.page_size);
    }
  }

  tableStatus(page: number, type: string, page_size: string) {
    if (type != this.type_status) {
      this.page_atual = 1;
    } else {
      this.page_atual = page;
    }

    this.type_status = type;

    if (this.account != null) {
      this.getTransfer(this.page_atual, type, page_size);
    }
  }

  setAll(checkbox: boolean) {
    this.allComplete = checkbox;
    this.dataSourceTransfer.filteredData.forEach(
      (select: { checkbox: boolean }) => (select.checkbox = checkbox)
    );

    this.dataSourceTransfer.filteredData.forEach((data: any) => {
      if (data.status != 'NEW' && data.status != 'ERROR') {
        data.checkbox = false;
      }
    });

    if (this.allComplete == false) {
      this.valueTotal = null;
      this.count_items = null;
      this.updateAllComplete(null);
    } else {
      this.updateAllComplete(null);
    }
  }

  someComplete() {
    if (this.dataSourceTransfer == null) {
      return false;
    }
    return (
      this.dataSourceTransfer.filteredData.filter(
        (select: { checkbox: any }) => select.checkbox
      ).length > 0 && !this.allComplete
    );
  }

  updateAllComplete(obj: any) {
    if (obj == null) {
      this.allComplete =
        this.dataSourceTransfer != null &&
        this.dataSourceTransfer.filteredData.every(
          (select: { checkbox: any }) => select.checkbox
        );

      let select_new: boolean = false;
      let alert_erro: boolean = false;

      let reduced = this.dataSourceTransfer.filteredData.reduce(
        function (acc: any, item: any) {
          if (item.checkbox) {
            item.status == 'ERROR' ? (alert_erro = true) : (select_new = true);
            acc.someValue += item.amount;
            acc.someCount++;
          }

          return acc;
        },
        { someValue: 0, someCount: 0 }
      );

      this.list_checkbox_select = [];

      this.dataSourceTransfer.filteredData.forEach(
        (data: { checkbox: any }) => {
          if (data.checkbox) {
            this.list_checkbox_select.push(data);
          }
        }
      );

      if (alert_erro && select_new) this.openMessage();

      this.valueTotal = reduced.someValue.toFixed(2);
      this.count_items = reduced.someCount;
    } else {
      this.valueTotal = obj.amount.toFixed(2);
      this.count_items = 1;
    }
  }

  changeStatus(type_status: string, element: any) {
    let list = element != null ? [element] : this.list_checkbox_select;
    this.updateAllComplete(element);

    if (list.length <= 0 && type_status == 'reprocess') {
      this.openSnackBar(
        'Não há nenhuma transferência selecionada para reprocessar',
        'Fechar'
      );
    } else if (list != null && list.length > 0 && type_status == 'reprocess') {
      this.confirmReprocess(list);
    }
  }

  openMessage() {
    this.modalRefMessage = this.modalService.show(ModalMessageComponent, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
      data: {
        modeScreen: this.modeScreen,
        title: 'Ops...',
        text: 'Não é possível selecionar uma transferência com erro e com outro status.',
      },
    });
    this.modalRefMessage.content.action.subscribe((result: any) => {
      this.tableStatus(this.page_atual, this.type_status, this.page_size);
    });
  }

  reprocessTransaction(array: any) {
    let aceept_list: any[] = [];
    array.forEach((el: any) => {
      aceept_list.push(el.id);
    });

    this.loading = true;
    this.transferService.reprocessTransaction(aceept_list).subscribe(
      (data) => {
        this.loading = false;
        this.getTransfer(this.page_atual, this.type_status, this.page_size);
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  confirmReprocess(list: any) {
    var titleText = '';
    var descriptionText = '';

    if (list.length > 1) {
      titleText = 'Reprocessar Transferências';
      descriptionText = `Deseja reprocessar todas as ${
        this.count_items
      } transferências, que totalizam um valor de R$ ${this.converters.convertMoney(
        this.valueTotal
      )} ?`;
    } else {
      titleText = 'Reprocessar Transferência';
      descriptionText = `Deseja reprocessar esta transferência, que totaliza um valor de R$ ${this.converters.convertMoney(
        this.valueTotal
      )} ?`;
    }

    this.modalRefMessage = this.modalService.show(ModalChoiceComponent, {
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-lg',
      containerClass: '',
      animated: false,
      data: {
        modeScreen: this.modeScreen,
        title: titleText,
        text: descriptionText,
      },
    });
    this.modalRefMessage.content.action.subscribe((result: any) => {
      if (result == 'yes') {
        this.reprocessTransaction(list);
      }
    });
  }

  viewTransfer(obj: any) {
    this.modalRef = this.modalService.show(DetailsTransferComponent, {
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-xl',
      containerClass: '',
      animated: false,
      data: {
        transfer: obj,
      },
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 10000,
    });
  }
}

import { TariffCompany } from './../../../models/tariff';
import { ConvertDateApiToInputPipe } from './../../../pipes/convert-date-api-to-input.pipe';
import { ModalDeleteComponent } from 'src/app/modals/modal-delete/modal-delete.component';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { CompanyService } from 'src/app/services/company.service';
import { StorageService } from 'src/app/services/storage.service';
import { Converters } from 'src/app/utils/converters';
import { ValidatorUtils } from 'src/app/utils/validator.utils';

import { EMPTY } from 'rxjs';
import { expand, map } from 'rxjs/operators';
import { CompressorService } from 'src/app/services/compressor.service';
import { DatailTariffComponent } from '../datail-tariff/datail-tariff.component';
import { TariffService } from 'src/app/services/tariff.service';
import { MatSelect } from '@angular/material/select';
import { MatDatepicker } from '@angular/material/datepicker';

import {
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
  DateAdapter,
} from '@angular/material/core';

import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { ListInvoice } from 'src/app/models/list-invoice';
import { DATE_FORMATS } from 'src/app/models/date-formats';

@Component({
  selector: 'app-detail-companies',
  templateUrl: './detail-companies.component.html',
  styleUrls: ['./detail-companies.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-br' }, // alterar o locale conforme necessário
  ],
})
export class DetailCompaniesComponent implements OnInit {
  constructor(
    private storage: StorageService,
    private route: ActivatedRoute,
    private modalService: MDBModalService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private companyService: CompanyService,
    private compressor: CompressorService,
    private tariffService: TariffService,
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.isEdit = this.router.url.indexOf('financial') != -1;
  }

  loading: boolean = false;
  loading_btn: boolean = false;
  panelOpenState: boolean = true;

  id: any = null;
  isEdit: boolean = false;

  warningMap = new Map();
  convertDateInput = new ConvertDateApiToInputPipe();

  company: any = {} as any;
  modeScreen = this.storage.checkTheme();

  dataSourceInvoices: ListInvoice[] = [];
  invoicesColumns: string[] = [
    'status',
    'value',
    'discount',
    'valueTotal',
    'create',
    'buttons',
  ];

  dataSourceTariff: any = [];
  tariffColumns: string[] = [
    'type',
    'name',
    'value',
    'discount',
    'start_date',
    'end_date',
    'create',
    'buttons',
  ];

  tariffColumnsFinalized: string[] = [
    'type',
    'name',
    'value',
    'discount',
    'start_date',
    'end_date',
    'create',
  ];

  validator = new ValidatorUtils();
  modalRef: MDBModalRef = new MDBModalRef();

  panelOpenBank: boolean = true;
  panelOpenBills: boolean = true;
  panelOpenUser: boolean = true;

  compressed_image: string[] = [];
  converters = new Converters();

  openMonths: boolean = false;

  showMonthSelect = false;

  date: any = new Date().toISOString();
  minDate: string = '2019-01-02';
  currentDate: Date = new Date();
  dateFormat = 'YYYY-MM';

  statusInvoice!: string;
  status_invoice: string = '';

  list_meses = [
    { value: '01', name: 'Janeiro' },
    { value: '02', name: 'Fevereiro' },
    { value: '03', name: 'Março' },
    { value: '04', name: 'Abril' },
    { value: '05', name: 'Maio' },
    { value: '06', name: 'Junho' },
    { value: '07', name: 'Julho' },
    { value: '08', name: 'Agosto' },
    { value: '09', name: 'Setembro' },
    { value: '10', name: 'Outubro' },
    { value: '11', name: 'Novembro' },
    { value: '12', name: 'Dezembro' },
  ];

  ngOnInit(): void {
    this.storage.watchTheme().subscribe((data) => {
      this.modeScreen = this.storage.checkTheme();
    });
    this.modeScreen = this.storage.checkTheme();

    const year = this.currentDate.getFullYear();
    const month = this.currentDate.getMonth() + 1;

    this.date = `${year}-${month.toString().padStart(2, '0')}`; // a função padStart adiciona um zero à esquerda se o número do mês tiver apenas um dígito

    this.getCompanyId(); // informações
    this.getListInvoicesCompanyId(); // faturas
  }

  getCompanyId() {
    this.loading = true;
    this.companyService.getCompanyId(this.id).subscribe(
      (data) => {
        this.company = data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  setMonthAndYear(
    normalizedMonthAndYear: string,
    datepicker: MatDatepicker<any>
  ) {
    datepicker.close();

    let month: any = (new Date(normalizedMonthAndYear).getMonth() + 1)
      .toString()
      .padStart(2, '0');
    let year = new Date(normalizedMonthAndYear).getFullYear();
    this.date = `${year}-${month}`;

    this.getListInvoicesCompanyId();
  }

  getListInvoicesCompanyId() {
    this.loading = true;
    this.dataSourceInvoices = [];
    this.companyService.getInvoiceByMonth(this.id, this.date).subscribe(
      (data) => {
        this.statusInvoice = data.status;
        this.dataSourceInvoices = this.convertDataToList(data);

        this.status_invoice = data.status;

        if (data.status !== 'FECHADO') {
          this.getListTariffCompanyId();
        }

        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  getListTariffCompanyId() {
    this.loading = true;
    this.companyService.getListTariffCompanyId(this.id, this.date).subscribe(
      (data) => {
        this.dataSourceTariff = data.results;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  convertDataToList(data: any): ListInvoice[] {
    let tableDataList: ListInvoice[] = [];

    if (data.id) {
      tableDataList = [
        {
          company: data.company,
          created_at: data.created_at,
          discount: data.discount,
          due_date: data.due_date,
          id: data.id,
          status: data.status,
          tariffs: data.tariffs,
          total_value: data.total_value,
          updated_at: data.updated_at,
          value: data.value,
        },
      ];
    }

    return tableDataList;
  }

  onOptionClick(event: Event, select: MatSelect) {
    this.openMonths = true;
    event.stopPropagation(); // impedir que o evento seja propagado para os elementos pai
    select.toggle(); // impedir que o mat-select seja fechado automaticamente
    // Adicione aqui o código que deseja executar ao clicar no mat-option
  }

  editCompany() {
    this.loading = true;
    this.companyService.editCompany(this.company).subscribe(
      (data) => {
        this.openSnackBar('Dados atualizados! ', 'Fechar');
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  async onFileChanged(event: any, variavel: any) {
    if (event.target.files && event.target.files[0]) {
      var target = event.target.files;
      const compress = this.recursiveCompress(target[0], 0, target).pipe(
        expand((res) => {
          return res.index > res.array.length - 1
            ? EMPTY
            : this.recursiveCompress(target[res.index], res.index, target);
        })
      );
      compress.subscribe((res) => {
        if (res.index > res.array.length - 1) {
          this.company[variavel] = this.compressed_image[0];
          this.compressed_image = [];
        }
      });
    }
  }

  recursiveCompress = (image: File, index: number, array: any) => {
    return this.compressor.compress(image).pipe(
      map((response) => {
        this.compressed_image.push(response);
        return {
          data: response,
          index: index + 1,
          array: array,
        };
      })
    );
  };

  datailTariff(type: any, obj?: any) {
    const tariff_edit = obj ? { ...obj } : ({} as TariffCompany);
    console.log(tariff_edit);

    this.modalRef = this.modalService.show(DatailTariffComponent, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
      data: {
        modeScreen: this.modeScreen,
        type: type,
        tariff_company: tariff_edit,
        company: this.company,
        date: this.date,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result.action == 'yes') {
        this.getCompanyId(); // informações
        this.getListTariffCompanyId(); // tarifas
        this.getListInvoicesCompanyId(); // faturas
      }
    });
  }

  deleteTariff(obj?: any) {
    this.modalRef = this.modalService.show(ModalDeleteComponent, {
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal-dialog',
      containerClass: '',
      animated: false,
      data: {
        modeScreen: this.modeScreen,
        title: 'Deletar tarifa',
        text: 'Deseja deletar a tarifa?',
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == 'yes') {
        this.loading = true;
        this.tariffService.deleteTariffInCompany(obj).subscribe(
          (data) => {
            this.openSnackBar('Tarifa deletada com sucesso', 'Fechar');
            this.getListTariffCompanyId();
          },
          (error) => {
            this.openSnackBar('Não foi possível deletar a tarifa', 'Fechar');
            this.loading = false;
          }
        );
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 10000,
    });
  }

  deleteInvoice(id: number) {
    this.modalRef = this.modalService.show(ModalDeleteComponent, {
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal',
      containerClass: '',
      animated: false,
      data: {
        title: 'Deletar Fatura',
        text: 'Deseja deletar a fatura?',
        modeScreen: this.modeScreen,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == 'yes') {
        this.tariffService.deleteInvoice(id).subscribe({
          next: () => {
            this.openSnackBar('Fatura deletada com sucesso', 'Fechar');
            this.getListInvoicesCompanyId();
          },
        });
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MDBModalRef } from 'angular-bootstrap-md';
import { User } from '../../models/user';
import { StorageService } from '../../services/storage.service';
import { TransferService } from '../../services/transfer.service';
import { Converters } from '../../utils/converters';

@Component({
  selector: 'app-dashboard-employer',
  templateUrl: './dashboard-employer.component.html',
  styleUrls: ['./dashboard-employer.component.scss'],
})
export class DashboardEmployerComponent implements OnInit {
  constructor(
    private storage: StorageService,
    private transferService: TransferService,
    private _snackBar: MatSnackBar,
  ) {}

  modalRef: any = MDBModalRef;
  modalRefMessage: MDBModalRef = new MDBModalRef();

  loading_refresh: boolean = false;

  modeScreen: any;
  loading: boolean = false;
  user: User = {} as User;
  text_input: string = '';
  type_status: string = 'NEW';

  data_response: any;
  displayedColumns: string[] = [
    'company',
    'all',
    'news',
    'approved',
    'cancelled',
    'waiting_reprocessing',
    'process',
    'successes',
    'errors',
  ];
  page_size: any = '10';
  page_atual: number = 1;
  count_page: number = 1;

  converters = new Converters();

  date_from: any = new Date();
  date_to: any = new Date();

  ngOnInit(): void {
    this.modeScreen = this.storage.checkTheme();
    this.user = this.storage.getUser();

    this.storage.watchTheme().subscribe((data: any) => {
      this.modeScreen = this.storage.checkTheme();
    });

    this.storage.watchUser().subscribe((user: any) => {
      this.user = this.storage.getUser();
    });

    this.getDashboard(1);
  }

  convertDateSendApi(data: string) {
    if (data == null) {
      return null;
    } else {
      var d = new Date(data),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      var aux_data = [year, month, day].join('-');

      return aux_data;
    }
  }

  getDashboard(page: number) {
    let _date_from: any = this.convertDateSendApi(this.date_from);
    let _date_to: any = this.convertDateSendApi(this.date_to);

    this.loading = true;
    if (_date_from && _date_to) {
      this.transferService
        .getDashboardQuantityPaymntByStatus(_date_from, _date_to)
        .subscribe(
          (data: any) => {
            this.data_response = data;
            this.page_atual = page;
            this.count_page = Math.ceil(data.count / Number(this.page_size));
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
          }
        );
    }
  }

  getTotal(column: string): number {
    let total = 0;
    for (const element of this.data_response) {
      total += element[column] || 0;
    }
    return total;
  }

  backPage() {
    if (this.data_response.previous) {
      this.getDashboard(this.page_atual - 1);
    }
  }

  nextPage() {
    if (this.data_response.next) {
      this.getDashboard(this.page_atual + 1);
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 10000,
    });
  }

  downloadDashboardQuantityPaymnt(): void {
    this.loading = true;

    if (this.data_response.length <= 0) {
      this.loading = false;
      this.openSnackBar('Não há dados para exportar', 'Fechar');
      return;
    }

    let _date_from: any = this.convertDateSendApi(this.date_from);
    let _date_to: any = this.convertDateSendApi(this.date_to);

    this.transferService
      .downloadDashboardQuantityPaymnt(_date_from, _date_to)
      .subscribe(
        (data) => {
          let _file: any = document.getElementById(
            'downloadLink'
          ) as HTMLElement;

          let blob = data.body.slice(0, data.body.size, 'text/xlsx'),
            url = window.URL.createObjectURL(blob);

          _file.href = url;
          _file.download = 'dashboard.xlsx';
          _file.click();

          this.loading = false;
          return;
        },
        (error) => {
          this.openSnackBar(
            'Não foi possível exportar os dados. Tente novamente',
            'Fechar'
          );
          this.loading = false;
        }
      );
  }
}

import { Component, OnInit } from '@angular/core';
import { Business_Group } from '../../models/business-group';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-business-group',
  templateUrl: './business-group.component.html',
  styleUrls: ['./business-group.component.scss']
})
export class BusinessGroupComponent implements OnInit {

  constructor(
    private storage: StorageService,
  ) { }

  modeScreen: any
  loading: boolean = false
  name_user: string = ''
  text_input: string = ''
  page_atual: number = 1
  count_page: number = 1

  displayedColumns: string[] = ["name", "company", "status", "buttons"];
  dataSource = ELEMENT_DATA;

  ngOnInit(): void {
    this.modeScreen = this.storage.checkTheme();
    this.name_user = this.storage.getUser().name

    this.storage.watchTheme().subscribe(data => {
      this.modeScreen = this.storage.checkTheme();
    })

    this.storage.watchUser().subscribe(user => {
      this.name_user = this.storage.getUser().name
    })
  }

  editGroup(obj: any) {
  }

  deleteGroup(obj: any) {
  }

  addGroup() {
  }

  filterGroup(text: string) { }

}

const ELEMENT_DATA: Business_Group[] = [
  { name: 'Grupo 1', company: 'Empresa 1; Empresa 2; Empresa 3', status: true },
  { name: 'Grupo 1', company: 'Empresa 1; Empresa 2; Empresa 3', status: true },
  { name: 'Grupo 1', company: 'Empresa 1; Empresa 2; Empresa 3', status: true },
  { name: 'Grupo 1', company: 'Empresa 1; Empresa 2; Empresa 3', status: true },
  { name: 'Grupo 1', company: 'Empresa 1; Empresa 2; Empresa 3', status: true },
  { name: 'Grupo 1', company: 'Empresa 1; Empresa 2; Empresa 3', status: true },
  { name: 'Grupo 1', company: 'Empresa 1; Empresa 2; Empresa 3', status: true },
  { name: 'Grupo 1', company: 'Empresa 1; Empresa 2; Empresa 3', status: true },
  { name: 'Grupo 1', company: 'Empresa 1; Empresa 2; Empresa 3', status: true },
];

<div class="modal-content"
  [ngClass]="{'darkModal': modeScreen.theme == 'dark', 'lightModal': modeScreen.theme == 'light' }">

  <svg (click)="close('no')" class="icons_close" width="25px" height="25px" viewBox="0 0 24 24"
    [style.fill]="modeScreen.color">
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path [style.fill]="modeScreen.color"
      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
  </svg>

  <div class="modal-header">
    <h1 class="modal-title" [style.color]="modeScreen.color">
      <b> {{type == 'edit' ? 'Editar tarifa' : 'Cadastrar tarifa'}} </b>
    </h1>
  </div>

  <div class="modal-body" [style.color]="modeScreen.color">

    <div class="card_input" [style.color]="modeScreen.color">
      <label class="font14">Tarifa</label>
      <mat-form-field [ngClass]="{'border_color': warningMap.get('tariff_type')}">
        <mat-select [(ngModel)]="tariff_company.tariff_type" [disabled]="type == 'edit'">
          <mat-option *ngFor="let type of list_tariffs" [value]="type.id" (onSelectionChange)="selectTariff(type)">
            {{ type.id }} - {{ type.description_tariff }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <ng-container *ngIf="select_tariff">
      <div class="card_input">
        <label class="font14" [style.color]="modeScreen.color">Desconto</label>
        <input matInput #inputValue type="text" [(ngModel)]="tariff_company.value_discount"
          [ngClass]="{'border_color': warningMap.get('value_discount')}" currencyMask (ngModelChange)="calculatePercent()" (click)="placeCursorAtTheEnd()">
      </div>

      <div class="cards_line">
        <div class="card_input">
          <label class="font14" [style.color]="modeScreen.color">Valor com desconto</label>
          <input matInput type="text" [(ngModel)]="tariff_company.value_with_discount" mask="separator.2"
            [thousandSeparator]="'.'" [decimalMarker]="','" prefix="R$ " readonly disabled>
        </div>

        <div class="card_input">
          <label class="font14" [style.color]="modeScreen.color">Porcentagem</label>
          <input matInput type="text" [(ngModel)]="tariff_company.discount"
            [ngClass]="{'border_color': warningMap.get('discount')}" mask="separator.2" readonly disabled>
          <span class="icon_right">%</span>
        </div>
      </div>

      <div class="card_date">
        <label class="font14" [style.color]="modeScreen.color">Período de cobrança</label>

        <div class="checkbox">
          <mat-checkbox [(ngModel)]="checked" (click)="checkedDate()">
            Cobrar mensalmente
          </mat-checkbox>
        </div>

        <div class="card_date">
          <label class="font14" [style.color]="modeScreen.color">Data inicial</label>

          <mat-form-field appearance="fill" (click)="start_date.open()">
            <input matInput [disabled]="checked" [matDatepicker]="start_date" readonly
              [(ngModel)]="tariff_company.start_date">
            <mat-datepicker-toggle matIconSuffix [for]="start_date"></mat-datepicker-toggle>
            <mat-datepicker #start_date startView="multi-year" panelClass="example-month-picker">
            </mat-datepicker>
          </mat-form-field>
        </div>

        <div class="card_date">
          <label class="font14" [style.color]="modeScreen.color">Data final</label>

          <mat-form-field appearance="fill" (click)="end_date.open()">
            <input matInput [disabled]="checked" [matDatepicker]="end_date" readonly
              [(ngModel)]="tariff_company.end_date">
            <mat-datepicker-toggle matIconSuffix [for]="end_date"></mat-datepicker-toggle>
            <mat-datepicker #end_date startView="multi-year" panelClass="example-month-picker">
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="line_bottom">
        <div class="btns">
          <button mat-raised-button class="btn-modal" (click)="close('no')" [style.background]="modeScreen.color"
            [style.color]="modeScreen.color2"> Voltar </button>

          <button mat-raised-button class="btn-modal" (click)="checkInput()" [style.background]="modeScreen.color"
            [style.color]="modeScreen.color2"> {{type == 'edit' ? 'Salvar' : 'Criar'}} <span *ngIf="loading"
              style="margin-left: 5px;" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
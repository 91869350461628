import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Transfer } from 'src/app/models/transfer';
import { StorageService } from 'src/app/services/storage.service';
import { TransferService } from 'src/app/services/transfer.service';
import { instution } from 'src/app/utils/instution';
import { ValidatorUtils } from 'src/app/utils/validator.utils';

@Component({
  selector: 'app-details-transfer',
  templateUrl: './details-transfer.component.html',
  styleUrls: ['./details-transfer.component.scss']
})
export class DetailsTransferComponent implements OnInit {

  constructor(
    private storage: StorageService,
    private route: ActivatedRoute,
    private transferService: TransferService,
    public modalRef: MDBModalRef,
  ) {
    this.transfer.id = this.route.snapshot.paramMap.get('id');
  }

  modeScreen: any;
  loading: boolean = false;
  warningMap = new Map();
  validator = new ValidatorUtils()
  transfer: Transfer = {} as Transfer;

  displayedColumns: string[] = ["status", "created_by", "created_at", "error"];
  dataSource: any = [];

  panelOpen: boolean = true;

  list_instution: any = instution;

  edit_error: boolean = false;
  text_button: string = 'Editar Descrição do Erro';

  ngOnInit(): void {
    this.modeScreen = this.storage.checkTheme();

    this.storage.watchTheme().subscribe(data => {
      this.modeScreen = this.storage.checkTheme();
    })

    this.getTransferId()
  }

  setErrorInput() {
    if (!this.edit_error) {
      this.edit_error = true;
      this.text_button = 'Salvar Alteração'
    }
    else {
      this.edit_error = false;
      this.text_button = 'Editar Descrição do Erro'

      this.editErrorTransaction(this.transfer.id, this.transfer.new_erro_descriptor)

      this.dataSource.forEach((el: any) => {
        this.editErrorLog(el.id, el.new_erro_descriptor)
      });
    }
  }

  editErrorLog(id: any, text: string) {
    this.transferService.editErrorLog(id, text).subscribe();
  }

  editErrorTransaction(id: any, text: string) {
    this.transferService.editErrorTransaction(id, text).subscribe();
  }

  getTransferId() {
    this.loading = true;
    this.transferService.getTransferId(this.transfer.id).subscribe(data => {
      this.transfer = data;
      this.dataSource = data.logs;

      this.formatStatus(data.status, this.transfer)
      this.formatType(data.to_account_type)
      this.loading = false;
    })
  }

  checkError(text: string) {
    let _aux = text.indexOf('operationUuid must be a `string` ')

    if (_aux == 0) {
      return 'Ocorreu um erro durante o processamento. Verifique os dados informados e tente novamente.'
    }
    else {
      return text
    }
  }

  checkInstution(instution: any) {
    if (instution != null && instution != '') {
      let _item = this.list_instution.filter((el: any) => el.ispb == instution)

      if (_item != null && _item.length > 0) {
        return `${_item[0].companyName} - ${_item[0].ispb}`
      }
      else {
        return '-'
      }
    }
    else {
      return '-'
    }
  }

  formatStatus(status: string, obj: any) {
    if (status == "NEW") obj.status = "Novo"
    if (status == "APPROVED") obj.status = "Aprovado"
    if (status == "CANCELED") obj.status = "Cancelado"
    if (status == "PROCESSING") obj.status = "Em processamento"
    if (status == "SUCCESS") obj.status = "Sucesso"
    if (status == "ERROR") obj.status = "Erro"
    if (status == "AWAITING BANK CONFIRMATION") obj.status = "Aguardando confirmação"

    return obj.status
  }

  formatType(type: string) {
    if (type == "CURRENT_ACCOUNT") this.transfer.to_account_type = 'Conta Corrente'
    if (type == "SAVING_ACCOUNT") this.transfer.to_account_type = 'Conta Poupança'
    if (type == "PAYMENT_ACCOUNT") this.transfer.to_account_type = 'Conta de Pagamento'
  }

  close() {
    this.modalRef.hide();
  }
}

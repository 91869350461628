export class ValidatorUtils {

  isEmptyOrNull(value: any): boolean {
    if (value == null || value == "")
      return true
    return false
  }

  isValidCPF(cpf: string): boolean {
    if (typeof cpf !== "string") return false;

    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (!cpf || cpf.length != 11 ||
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999") {
      return false
    }
    let sum = 0
    let remainder;
    for (let i = 1; i <= 9; i++)
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);

    remainder = (sum * 10) % 11;
    if ((remainder == 10) || (remainder == 11)) remainder = 0;
    if (remainder != parseInt(cpf.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++)
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)

    remainder = (sum * 10) % 11
    if ((remainder == 10) || (remainder == 11)) remainder = 0
    if (remainder != parseInt(cpf.substring(10, 11))) return false

    return true
  }

  isValidCNPJ(cnpj: string): boolean {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14) return false;

    if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999") return false;

    let cnpj_size = cnpj.length - 2;
    let numbers = cnpj.substring(0, cnpj_size);
    let last_digit = cnpj.substring(cnpj_size);
    let sum = 0;
    let pos = cnpj_size - 7;
    for (let i = cnpj_size; i >= 1; i--) {
      sum += parseInt(numbers.charAt(cnpj_size - i)) * pos--;
      if (pos < 2)
        pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result != parseInt(last_digit.charAt(0)))
      return false;

    cnpj_size = cnpj_size + 1;
    numbers = cnpj.substring(0, cnpj_size);
    sum = 0;
    pos = cnpj_size - 7;
    for (let i = cnpj_size; i >= 1; i--) {
      sum += parseInt(numbers.charAt(cnpj_size - i)) * pos--;
      if (pos < 2)
        pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result != parseInt(last_digit.charAt(1)))
      return false;

    return true;
  }

  isValidEmail(email: string): boolean {
    let re = /\S+@\S+\.\S+/;
    return re.test(email)
  }

  convertDateStringToApi(data: string) {
    if (data != null) {
      let aux_date = data.split('/')
      var aux_data = [aux_date[2], aux_date[1], aux_date[0]].join('-')
      return aux_data;
    }
    else {
      return null
    }
  }

  convertDateApiToString(data: string) {
    if (data != null) {
      let aux_date = data.split('-')
      var aux_data = [aux_date[2], aux_date[1], aux_date[0]].join('/')
      return aux_data;
    }
    else {
      return null
    }
  }

  checkInputMessage(value: string, type: string, value_2?: string) {
    if (value == '' || value == null) {
      return 'Campo obrigatório.'
    }

    if (type == 'cpf' && !this.isValidCPF(value)) {
      return 'CPF inválido.'
    }

    if (type == 'email' && !this.isValidEmail(value)) {
      return 'E-mail inválido.'
    }
    if (type == 'confirm_password' && value != value_2) {
      return 'As senhas inseridas não coincidem.'
    }

    else return ''
  }

}

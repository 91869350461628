import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-access-groups',
  templateUrl: './access-groups.component.html',
  styleUrls: ['./access-groups.component.scss']
})
export class AccessGroupsComponent implements OnInit {

  constructor(
    private storage: StorageService,
  ) { }

  modeScreen: any
  loading: boolean = false
  name_user: string = ''
  text_input: string = ''
  page_atual: number = 1
  count_page: number = 1

  displayedColumns: string[] = ["name", "profile", "status", "buttons"];
  dataSource = ELEMENT_DATA;

  ngOnInit(): void {
    this.modeScreen = this.storage.checkTheme()
    this.name_user = this.storage.getUser().name

    this.storage.watchTheme().subscribe(data => {
      this.modeScreen = this.storage.checkTheme()
    })

    this.storage.watchUser().subscribe(user => {
      this.name_user = this.storage.getUser().name
    })
  }

  editGroup(obj: any) {
  }

  deleteGroup(obj: any) {
  }

  addGroup() {
  }

  filterGroup(text: string) { }

}

export interface PeriodicElement {
  name: string;
  profile: string;
  status: true;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { name: 'Grupo 1', profile: 'Gestão de acesso', status: true },
  { name: 'Grupo 2', profile: 'Gestão de Menu', status: true },
  { name: 'Grupo 3', profile: 'Transacional', status: true },
  { name: 'Grupo 4', profile: 'Gestão de Menu', status: true },
  { name: 'Grupo 5', profile: 'Transacional', status: true },
  { name: 'Grupo 6', profile: 'Gestão de acesso', status: true },
  { name: 'Grupo 7', profile: 'Gestão de acesso', status: true },
  { name: 'Grupo 8', profile: 'Gestão de acesso', status: true },
];

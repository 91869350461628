export const instution= [
    {
        "companyName": "ABASTECE AI CLUBE AUTOMOBILISTA PAYMENT LTDA.",
        "ispb": "09515813",
        "participantDocument": "09515813000199",
        "participation": "INDIRETA"
    },
    {
        "companyName": "ACESSO SOLUÇÕES DE PAGAMENTO S.A.",
        "ispb": "13140088",
        "participantDocument": "13140088000199",
        "participation": "DIRETA"
    },
    {
        "companyName": "ADIQ SOLUCOES DE PAGAMENTO S.A.",
        "ispb": "20520298",
        "participantDocument": "20520298000178",
        "participation": "INDIRETA"
    },
    {
        "companyName": "AL5 S.A. CRÉDITO",
        "ispb": "27214112",
        "participantDocument": "27214112000100",
        "participation": "DIRETA"
    },
    {
        "companyName": "ALYMENTE BENEFICIOS E SIMILARES LTDA.",
        "ispb": "29062232",
        "participantDocument": "29062232000156",
        "participation": "INDIRETA"
    },
    {
        "companyName": "AME DIGITAL BRASIL LTDA.",
        "ispb": "32778350",
        "participantDocument": "32778350000170",
        "participation": "INDIRETA"
    },
    {
        "companyName": "ASSOCIACAO DE POUPANCA E EMPRESTIMO - POUPEX",
        "ispb": "00655522",
        "participantDocument": "00655522000121",
        "participation": "INDIRETA"
    },
    {
        "companyName": "ATAR TECNOLOGIA LTDA.",
        "ispb": "19581142",
        "participantDocument": "19581142000118",
        "participation": "INDIRETA"
    },
    {
        "companyName": "BANCO ABC BRASIL S.A.",
        "ispb": "28195667",
        "participantDocument": "28195667000106",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO AGIBANK S.A.",
        "ispb": "10664513",
        "participantDocument": "10664513000150",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO ALFA S.A.",
        "ispb": "03323840",
        "participantDocument": "03323840000183",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO ARBI S.A.",
        "ispb": "54403563",
        "participantDocument": "54403563000150",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO BMG S.A.",
        "ispb": "61186680",
        "participantDocument": "61186680000174",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO BRADESCO S.A.",
        "ispb": "60746948",
        "participantDocument": "60746948000112",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO BS2 S.A.",
        "ispb": "71027866",
        "participantDocument": "71027866000134",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO BTG PACTUAL S.A.",
        "ispb": "30306294",
        "participantDocument": "30306294000145",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO C6 S.A.",
        "ispb": "31872495",
        "participantDocument": "31872495000172",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO CAPITAL S.A.",
        "ispb": "15173776",
        "participantDocument": "15173776000180",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO CETELEM S.A.",
        "ispb": "00558456",
        "participantDocument": "00558456000171",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO COOPERATIVO DO BRASIL S.A. - BANCOOB",
        "ispb": "02038232",
        "participantDocument": "02038232000164",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO COOPERATIVO SICREDI S.A.",
        "ispb": "01181521",
        "participantDocument": "01181521000155",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO CREFISA S.A.",
        "ispb": "61033106",
        "participantDocument": "61033106000186",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO DA AMAZONIA S.A.",
        "ispb": "04902979",
        "participantDocument": "04902979000144",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO DAYCOVAL S.A.",
        "ispb": "62232889",
        "participantDocument": "62232889000190",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO DIGIMAIS S.A.",
        "ispb": "92874270",
        "participantDocument": "92874270000140",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO DIGIO S.A.",
        "ispb": "27098060",
        "participantDocument": "27098060000145",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO DO BRASIL S.A.",
        "ispb": "00000000",
        "participantDocument": "00000000000191",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO DO ESTADO DE SERGIPE S.A.",
        "ispb": "13009717",
        "participantDocument": "13009717000146",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO DO ESTADO DO PARÁ S.A.",
        "ispb": "04913711",
        "participantDocument": "04913711000108",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.",
        "ispb": "92702067",
        "participantDocument": "92702067000196",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO DO NORDESTE DO BRASIL S.A.",
        "ispb": "07237373",
        "participantDocument": "07237373000120",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO FIBRA S.A.",
        "ispb": "58616418",
        "participantDocument": "58616418000108",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO GUANABARA S.A.",
        "ispb": "31880826",
        "participantDocument": "31880826000116",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO INBURSA S.A.",
        "ispb": "04866275",
        "participantDocument": "04866275000163",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO INDUSTRIAL DO BRASIL S.A.",
        "ispb": "31895683",
        "participantDocument": "31895683000116",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO INDUSVAL S.A.",
        "ispb": "61024352",
        "participantDocument": "61024352000171",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO INTER S.A.",
        "ispb": "00416968",
        "participantDocument": "00416968000101",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO ITAUCARD S.A.",
        "ispb": "17192451",
        "participantDocument": "17192451000170",
        "participation": "INDIRETA"
    },
    {
        "companyName": "BANCO KEB HANA DO BRASIL S.A.",
        "ispb": "02318507",
        "participantDocument": "02318507000113",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO MERCANTIL DO BRASIL S.A.",
        "ispb": "17184037",
        "participantDocument": "17184037000110",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO MODAL S.A.",
        "ispb": "30723886",
        "participantDocument": "30723886000162",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO ORIGINAL S.A.",
        "ispb": "92894922",
        "participantDocument": "92894922000108",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO OURINVEST S.A.",
        "ispb": "78632767",
        "participantDocument": "78632767000120",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO PAN S.A.",
        "ispb": "59285411",
        "participantDocument": "59285411000113",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO PINE S.A.",
        "ispb": "62144175",
        "participantDocument": "62144175000120",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO RENDIMENTO S.A.",
        "ispb": "68900810",
        "participantDocument": "68900810000138",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO RIBEIRAO PRETO S.A.",
        "ispb": "00517645",
        "participantDocument": "00517645000104",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO SAFRA S.A.",
        "ispb": "58160789",
        "participantDocument": "58160789000128",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO SANTANDER (BRASIL) S.A.",
        "ispb": "90400888",
        "participantDocument": "90400888000142",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO SMARTBANK S.A.",
        "ispb": "58497702",
        "participantDocument": "58497702000102",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO SOFISA S.A.",
        "ispb": "60889128",
        "participantDocument": "60889128000180",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO TOPÁZIO S.A.",
        "ispb": "07679404",
        "participantDocument": "07679404000100",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO TRIANGULO S.A.",
        "ispb": "17351180",
        "participantDocument": "17351180000159",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO VOTORANTIM S.A.",
        "ispb": "59588111",
        "participantDocument": "59588111000103",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO VR S.A.",
        "ispb": "78626983",
        "participantDocument": "78626983000163",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCO WOORI BANK DO BRASIL S.A.",
        "ispb": "15357060",
        "participantDocument": "15357060000133",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANCOSEGURO S.A.",
        "ispb": "10264663",
        "participantDocument": "10264663000177",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANESTES S.A. BANCO DO ESTADO DO ESPÍRITO SANTO",
        "ispb": "28127603",
        "participantDocument": "28127603000178",
        "participation": "DIRETA"
    },
    {
        "companyName": "BANQI INSTITUICAO DE PAGAMENTO LTDA.",
        "ispb": "30723871",
        "participantDocument": "30723871000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "BANRISUL CARTOES S.A.",
        "ispb": "92934215",
        "participantDocument": "92934215000106",
        "participation": "INDIRETA"
    },
    {
        "companyName": "BEPAY INSTITUIÇÃO DE PAGAMENTO S.A.",
        "ispb": "24313102",
        "participantDocument": "24313102000125",
        "participation": "INDIRETA"
    },
    {
        "companyName": "BEXS BANCO DE CÂMBIO S/A",
        "ispb": "13059145",
        "participantDocument": "13059145000100",
        "participation": "DIRETA"
    },
    {
        "companyName": "BIT CAPITAL LTDA.",
        "ispb": "29079725",
        "participantDocument": "29079725000107",
        "participation": "INDIRETA"
    },
    {
        "companyName": "BIZ - TECNOLOGIA EM MEIOS DE PAGAMENTO LTDA.",
        "ispb": "07155434",
        "participantDocument": "07155434000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "BLU PAY TECNOLOGIA DE DADOS S.A.",
        "ispb": "33873062",
        "participantDocument": "33873062000167",
        "participation": "INDIRETA"
    },
    {
        "companyName": "BOLETOBANCÁRIO.COM TECNOLOGIA DE PAGAMENTOS LTDA.",
        "ispb": "21018182",
        "participantDocument": "21018182000106",
        "participation": "DIRETA"
    },
    {
        "companyName": "BPP INSTITUIÇÃO DE PAGAMENTO S.A.",
        "ispb": "13370835",
        "participantDocument": "13370835000185",
        "participation": "DIRETA"
    },
    {
        "companyName": "BRB - BANCO DE BRASILIA S.A.",
        "ispb": "00000208",
        "participantDocument": "00000208000100",
        "participation": "DIRETA"
    },
    {
        "companyName": "BÔNUSCRED SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ispb": "36586946",
        "participantDocument": "36586946000129",
        "participation": "DIRETA"
    },
    {
        "companyName": "CAIXA ECONOMICA FEDERAL",
        "ispb": "00360305",
        "participantDocument": "00360305000104",
        "participation": "DIRETA"
    },
    {
        "companyName": "CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ispb": "21332862",
        "participantDocument": "21332862000191",
        "participation": "DIRETA"
    },
    {
        "companyName": "CARUANA S.A. - SOCIEDADE DE CRÉDITO",
        "ispb": "09313766",
        "participantDocument": "09313766000109",
        "participation": "DIRETA"
    },
    {
        "companyName": "CASA DO CRÉDITO S.A. SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR",
        "ispb": "05442029",
        "participantDocument": "05442029000147",
        "participation": "DIRETA"
    },
    {
        "companyName": "CC DOS MEDICOS, PROFISSIONAIS DA SAUDE E EMPRESARIOS DE MATO GROSSO",
        "ispb": "36900256",
        "participantDocument": "36900256000879",
        "participation": "INDIRETA"
    },
    {
        "companyName": "CC E INVESTIMENTO DO SUDOESTE DE RONDONIA LTDA - CREDISIS SUDOESTE/RO",
        "ispb": "03985375",
        "participantDocument": "03985375000227",
        "participation": "INDIRETA"
    },
    {
        "companyName": "CC UNICRED VALE EUROPEU SANTA CATARINA LTDA - UNICRED VALE EUROPEU",
        "ispb": "73443863",
        "participantDocument": "73443863001340",
        "participation": "INDIRETA"
    },
    {
        "companyName": "CELER PROCESSAMENTO COMÉRCIO E SERVIÇOS LTDA.",
        "ispb": "22347623",
        "participantDocument": "22347623000178",
        "participation": "INDIRETA"
    },
    {
        "companyName": "CENTRAL DAS COOPERATIVAS DE CRÉDITO UNICOOB - SICOOB CENTRAL UNICOOB",
        "ispb": "05036532",
        "participantDocument": "05036532000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "CENTRAL DAS COOPERATIVAS DE CRÉDITO DO ESTADO DE SÃO PAULO - SICOOB CENTRAL CECRESP",
        "ispb": "62931522",
        "participantDocument": "62931522000164",
        "participation": "INDIRETA"
    },
    {
        "companyName": "CENTRAL DAS COOPERATIVAS DE CRÉDITO DO NORDESTE - SICOOB CENTRAL NE",
        "ispb": "70116611",
        "participantDocument": "70116611000185",
        "participation": "INDIRETA"
    },
    {
        "companyName": "CENTRAL DAS COOPERATIVAS DE CRÉDITO DOS ESTADOS DE MATO GROSSO, MATO GROSSO DO SUL E DO MUNICÍPIO DE CACOAL/RO - SICOOB CENTRAL RONDON",
        "ispb": "00106180",
        "participantDocument": "00106180000190",
        "participation": "INDIRETA"
    },
    {
        "companyName": "CENTRAL DAS COOPERATIVAS DE ECONOMIA E CRÉDITO DO ESTADO DE MINAS GERAIS LTDA. - SICOOB CENTRAL CECREMGE",
        "ispb": "00309024",
        "participantDocument": "00309024000127",
        "participation": "INDIRETA"
    },
    {
        "companyName": "CENTRAL DAS COOPERATIVAS DE ECONOMIA E CRÉDITO DO PLANALTO CENTRAL LTDA - SICOOB PLANALTO CENTRAL",
        "ispb": "00692214",
        "participantDocument": "00692214000176",
        "participation": "INDIRETA"
    },
    {
        "companyName": "CENTRAL SICOOB UNI DE COOPERATIVAS DE CRÉDITO",
        "ispb": "04243780",
        "participantDocument": "04243780000151",
        "participation": "INDIRETA"
    },
    {
        "companyName": "CIELO S.A.",
        "ispb": "01027058",
        "participantDocument": "01027058000191",
        "participation": "DIRETA"
    },
    {
        "companyName": "COMPANHIA GLOBAL DE SOLUCOES E SERVICOS DE PAGAMENTOS S.A.",
        "ispb": "32024691",
        "participantDocument": "32024691000150",
        "participation": "INDIRETA"
    },
    {
        "companyName": "CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E SOLIDÁRIA CRESOL CONFEDERAÇÃO",
        "ispb": "10398952",
        "participantDocument": "10398952000169",
        "participation": "DIRETA"
    },
    {
        "companyName": "CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASIL.",
        "ispb": "00315557",
        "participantDocument": "00315557000111",
        "participation": "DIRETA"
    },
    {
        "companyName": "CONTA ZAP SOLUCOES EM PAGAMENTO SA.",
        "ispb": "29415342",
        "participantDocument": "29415342000154",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA CENTRAL DE CRÉDITO DO ESPÍRITO SANTO - SICOOB CENTRAL ES",
        "ispb": "32428294",
        "participantDocument": "32428294000143",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA CENTRAL DE CRÉDITO - AILOS",
        "ispb": "05463212",
        "participantDocument": "05463212000129",
        "participation": "DIRETA"
    },
    {
        "companyName": "COOPERATIVA CENTRAL DE CRÉDITO DA BAHIA LTDA SICOOB CENTRAL BA",
        "ispb": "34148882",
        "participantDocument": "34148882000159",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA CENTRAL DE CRÉDITO DE GOIÁS LTDA",
        "ispb": "33416108",
        "participantDocument": "33416108000119",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA CENTRAL DE CRÉDITO DE MINAS GERAIS LTDA. - SICOOB CENTRAL CREDIMINAS",
        "ispb": "25683434",
        "participantDocument": "25683434000164",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA CENTRAL DE CRÉDITO DE SANTA CATARINA E RIO GRANDE DO SUL - SICOOB CENTRAL SC/RS",
        "ispb": "80160260",
        "participantDocument": "80160260000163",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA CENTRAL DE CRÉDITO DO ESTADO DE SÃO PAULO - SICOOB SÃO PAULO",
        "ispb": "63917579",
        "participantDocument": "63917579000171",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA CENTRAL DE CRÉDITO DO NORTE DO BRASIL - SICOOB NORTE",
        "ispb": "03502131",
        "participantDocument": "03502131000165",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA CENTRAL DE CRÉDITO DO RIO DE JANEIRO LTDA.",
        "ispb": "14568725",
        "participantDocument": "14568725000195",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA CENTRAL DE ECONOMIA E CRÉDITO MÚTUO - SICOOB UNIMAIS",
        "ispb": "73085573",
        "participantDocument": "73085573000139",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO ESTADO DO PIAUÍ - SICREDI PIAUÍ",
        "ispb": "03128973",
        "participantDocument": "03128973000107",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO - SICOOB CREDICOONAI",
        "ispb": "53923116",
        "participantDocument": "53923116000169",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CECRES - SICOOB CECRES",
        "ispb": "54603022",
        "participantDocument": "54603022000175",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CENTRO-SERRANA DO ESPÍRITO SANTO - SICOOB CENTRO- SERRANO",
        "ispb": "31804966",
        "participantDocument": "31804966000105",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CLASSICA DO ESTADO DO RIO DE JANEIRO - SICOOB EMPRESAS RJ",
        "ispb": "04120633",
        "participantDocument": "04120633000194",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO COOPLIVRE",
        "ispb": "49389307",
        "participantDocument": "49389307000115",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDIAGRO LTDA. - SICOOB CREDIAGRO",
        "ispb": "00429890",
        "participantDocument": "00429890000151",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDICERIPA - SICOOB CREDICERIPA",
        "ispb": "00966246",
        "participantDocument": "00966246000112",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDICITRUS",
        "ispb": "54037916",
        "participantDocument": "54037916000145",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDIMOTA SICOOB CREDIMOTA",
        "ispb": "66788142",
        "participantDocument": "66788142000173",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDISUDESTE LTDA. - SICOOB CREDISUDESTE",
        "ispb": "22656789",
        "participantDocument": "22656789000176",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDLIDER - SICOOB CREDLIDER",
        "ispb": "05463934",
        "participantDocument": "05463934000183",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE ITAPAGIPE LTDA - SICOOB ITAPAGIPE",
        "ispb": "71506513",
        "participantDocument": "71506513000117",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSAO DE PARA DE MINAS LTDA - SICOOB ASCICRED",
        "ispb": "01009908",
        "participantDocument": "01009908000129",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSAO DE PITANGUI E REGIAO LTDA. - SICOOB CREDPIT",
        "ispb": "04181542",
        "participantDocument": "04181542000169",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSAO DE RUBIATABA E REGIAO LTDA",
        "ispb": "73422792",
        "participantDocument": "73422792000166",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSAO DO CENTRO GOIANO LTDA",
        "ispb": "03730508",
        "participantDocument": "03730508000214",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSAO DO CENTRO NORDESTE MINEIRO LTDA - SICOOB CREDICENM",
        "ispb": "02173447",
        "participantDocument": "02173447000198",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSAO DO VALE DO AÇO LTDA - SICOOB VALE DO AÇO",
        "ispb": "19869338",
        "participantDocument": "19869338000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA ALTA PAULISTA - SICOOB COCREALPA",
        "ispb": "59869560",
        "participantDocument": "59869560000120",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO SUDESTE DE MINAS GERAIS LTDA. - SICOOB CREDISUCESSO",
        "ispb": "42887133",
        "participantDocument": "42887133000171",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS ITAIPU SICOOB CREDITAIPU",
        "ispb": "81014060",
        "participantDocument": "81014060000165",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE MARTINHO CAMPOS LTDA - SICOOB CREDIMAC",
        "ispb": "64480833",
        "participantDocument": "64480833000180",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO SUL DE MINAS LTDA - SICOOB CREDIVASS",
        "ispb": "01604998",
        "participantDocument": "01604998000104",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE DO GORUTUBA LTDA - SICOOB CREDIVAG",
        "ispb": "25404542",
        "participantDocument": "25404542000150",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE DO SÃO PATRICIO LTDA.",
        "ispb": "00968602",
        "participantDocument": "00968602000137",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE SALVADOR - SICREDI SALVADOR",
        "ispb": "02245493",
        "participantDocument": "02245493000155",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO ALTO PARANAIBA E REGIÃO LTDA. - SICOOB CREDIPATOS",
        "ispb": "25387671",
        "participantDocument": "25387671000188",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO CENTRO DO ESTADO DE RONDONIA - SICOOB CENTRO",
        "ispb": "08044854",
        "participantDocument": "08044854000181",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO NORTE DE RONDONIA LTDA. - CREDISIS CREDIARI",
        "ispb": "03222753",
        "participantDocument": "03222753000210",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO PLANALTO SUL - SICOOB CREDISSERRANA",
        "ispb": "03033469",
        "participantDocument": "03033469000115",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERACAO SOLIDARIA DE CHAPECO - CRESOL CHAPECO",
        "ispb": "04281182",
        "participantDocument": "04281182000177",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERACAO SOLIDARIA DE CONSTANTINA - CRESOL CONSTANTINA.",
        "ispb": "02663426",
        "participantDocument": "02663426000150",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDARIA DE QUILOMBO - CRESOL QUILOMBO",
        "ispb": "73254757",
        "participantDocument": "73254757000185",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL ASCOOB SISAL",
        "ispb": "63229629",
        "participantDocument": "63229629000128",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERACAO SOLIDARIA DE ERECHIM - CRESOL ERECHIM",
        "ispb": "02910987",
        "participantDocument": "02910987000107",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERACAO SOLIDARIA DE GETULIO VARGAS- CRESOL GETÚLIO VARGAS",
        "ispb": "05241145",
        "participantDocument": "05241145000106",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERACAO SOLIDARIA DE MARCELINO RAMOS-CRESOL MARCELINO RAMOS",
        "ispb": "05211129",
        "participantDocument": "05211129000162",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERACAO SOLIDARIA DE OLIVEIRA DOS BREJINHOS - CRESOL OLIVEIRA DOS BREJINHOS",
        "ispb": "05428587",
        "participantDocument": "05428587000158",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERACAO SOLIDARIA DE SANTA MARIA- CRESOL SANTA MARIA",
        "ispb": "05220232",
        "participantDocument": "05220232000179",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERACAO SOLIDARIA DE SAO JOAQUIM- CRESOL SÃO JOAQUIM",
        "ispb": "02766672",
        "participantDocument": "02766672000138",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERACAO SOLIDARIA DE SARANDI-CRESOL SARANDI",
        "ispb": "05220243",
        "participantDocument": "05220243000159",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERACAO SOLIDARIA PIONEIRA - CRESOL PIONEIRA",
        "ispb": "00971300",
        "participantDocument": "00971300000118",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDARIA DA ZONA DA MATA - CRESOL ZONA DA MATA",
        "ispb": "07208637",
        "participantDocument": "07208637000117",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE TENENTE PORTELA - CRESOL TENENTE PORTELA",
        "ispb": "04622657",
        "participantDocument": "04622657000141",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDARIA DAS ENCOSTAS DA SERRA GERAL CRESOL ENCOSTAS DA SERRA GERAL",
        "ispb": "03485130",
        "participantDocument": "03485130000150",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDARIA DE RIO FORTUNA - CRESOL RIO FORTUNA",
        "ispb": "07958405",
        "participantDocument": "07958405000186",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO UNICRED DESBRAVADORA LTDA - UNICRED DESBRAVADORA SUL",
        "ispb": "01039011",
        "participantDocument": "01039011000733",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO UNICRED REGIAO DOS VALES LTDA.- UNICRED REGIAO DOS VALES",
        "ispb": "01796302",
        "participantDocument": "01796302000695",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO VALE DO ITAJAI - VIACREDI",
        "ispb": "82639451",
        "participantDocument": "82639451000138",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANCA E INVESTIMENTO DO RIO DE JANEIRO - SICREDI RIO RJ",
        "ispb": "72128440",
        "participantDocument": "72128440000130",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANCA E INVESTIMENTO IGUACU - SICREDI IGUACU PR/SC/SP",
        "ispb": "84974278",
        "participantDocument": "84974278000150",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO ALIANÇA - SICREDI ALIANÇA PR/SP",
        "ispb": "79052122",
        "participantDocument": "79052122000181",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO PARQUE DAS ARAUCARIAS - SICREDI PARQUE DAS ARAUCARIAS PR/SC/SP",
        "ispb": "82065285",
        "participantDocument": "82065285000103",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO RIO PARANA - SICREDI RIO PARANA PR/SP",
        "ispb": "81206039",
        "participantDocument": "81206039000161",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO - SICREDI RIO GRANDE DO NORTE",
        "ispb": "70038237",
        "participantDocument": "70038237000147",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO AGROCREDI LTDA. - SICOOB AGROCREDI",
        "ispb": "42873828",
        "participantDocument": "42873828000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO ALIANÇA RS/SC - SICREDI ALIANÇA RS/SC",
        "ispb": "87795639",
        "participantDocument": "87795639000199",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO ALTO VALE DO ITAJAÍ - SICOOB ALTO VALE",
        "ispb": "80959612",
        "participantDocument": "80959612000145",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CARLOS CHAGAS LTDA. - SICOOB CARLOS CHAGAS",
        "ispb": "41753500",
        "participantDocument": "41753500000181",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CENTRO LESTE - SICREDI CENTRO LESTE RS",
        "ispb": "88471024",
        "participantDocument": "88471024000170",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CLÁSSICA DOS EMPREGADOS DA VALE S/A E ENTIDADES VINCULADAS LTDA. SICOOB COOPVALE",
        "ispb": "33924028",
        "participantDocument": "33924028000174",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO COCRE",
        "ispb": "54401286",
        "participantDocument": "54401286000146",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA DE GOIÁS - CRESOL GOIÁS",
        "ispb": "04490531",
        "participantDocument": "04490531000160",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA DE MINAS GERAIS - CRESOL MINAS",
        "ispb": "08624548",
        "participantDocument": "08624548000114",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA DO VALE DAS ARAUCÁRIAS- CRESOL VALE DAS ARAUCÁRIAS",
        "ispb": "06126780",
        "participantDocument": "06126780000105",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA DO VALE EUROPEU - CRESOL VALE EUROPEU",
        "ispb": "07512780",
        "participantDocument": "07512780000106",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA FRONTEIRAS PR/SC/SP - CRESOL FRONTEIRAS PR/SC/SP",
        "ispb": "05276770",
        "participantDocument": "05276770000185",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA INTEGRAÇÃO - CRESOL INTEGRAÇÃO",
        "ispb": "07268499",
        "participantDocument": "07268499000161",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA NORTE PARANAENSE - CRESOL NORTE PARANAENSE",
        "ispb": "07925729",
        "participantDocument": "07925729000118",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA OESTE CATARINENSE - CRESOL OESTE CATARINENSE",
        "ispb": "03965737",
        "participantDocument": "03965737000137",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA PROGRESSO - CRESOL PROGRESSO",
        "ispb": "02448310",
        "participantDocument": "02448310000107",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA TRADIÇÃO CRESOL TRADIÇÃO",
        "ispb": "04350225",
        "participantDocument": "04350225000129",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA UNIÃO DOS PINHAIS - CRESOL UNIÃO DOS PINHAIS",
        "ispb": "02446089",
        "participantDocument": "02446089000140",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA UNIÃO DOS VALES CRESOL UNIÃO DOS VALES",
        "ispb": "05231945",
        "participantDocument": "05231945000138",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO COOCRELIVRE",
        "ispb": "53935029",
        "participantDocument": "53935029000121",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO COOPACREDI LTDA. SICOOB COOPACREDI",
        "ispb": "65229254",
        "participantDocument": "65229254000121",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO COOPCRED",
        "ispb": "53623781",
        "participantDocument": "53623781000137",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO COOPERAÇÃO RS/SC SICREDI COOPERAÇÃO RS/SC",
        "ispb": "87779625",
        "participantDocument": "87779625000181",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDCOOPER LTDA SICOOB CREDCOOPER",
        "ispb": "19449602",
        "participantDocument": "19449602000159",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDIALP LTDA - SICOOB CREDIALP",
        "ispb": "25353939",
        "participantDocument": "25353939000160",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDIBELO LTDA. - SICOOB CREDIBELO",
        "ispb": "71237184",
        "participantDocument": "71237184000156",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDIBOM LTDA. SICOOB CREDIBOM",
        "ispb": "21670187",
        "participantDocument": "21670187000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDICAMPINA LTDA. - SICOOB CREDICAMPINA",
        "ispb": "01609345",
        "participantDocument": "01609345000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDICAMPO LTDA.- SICOOB CREDICAMPO",
        "ispb": "21661202",
        "participantDocument": "21661202000154",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDICARPA LTDA. - SICOOB CREDICARPA",
        "ispb": "23949522",
        "participantDocument": "23949522000130",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDICOCAPEC",
        "ispb": "67096909",
        "participantDocument": "67096909000166",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDIESMERALDAS LTDA. SICOOB CREDIESMERALDAS",
        "ispb": "68512748",
        "participantDocument": "68512748000107",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDIFIEMG LTDA. SICOOB CREDIFIEMG",
        "ispb": "07469260",
        "participantDocument": "07469260000150",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDIFOR LTDA SICOOB CREDIFOR",
        "ispb": "41931445",
        "participantDocument": "41931445000172",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDIGUAÇU - SICOOB CREDIGUAÇU",
        "ispb": "67960229",
        "participantDocument": "67960229000149",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDIJEQUITINHONHA LTDA. SICOOB CREDIJEQUITINHONHA",
        "ispb": "71243034",
        "participantDocument": "71243034000155",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDILIVRE LTDA. - SICOOB CREDILIVRE",
        "ispb": "41697103",
        "participantDocument": "41697103000130",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDILUZ LTDA. - SICOOB CREDILUZ",
        "ispb": "25536764",
        "participantDocument": "25536764000127",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDIMATA LTDA. SICOOB CREDIMATA",
        "ispb": "01152097",
        "participantDocument": "01152097000110",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDINOR LTDA - SICOOB CREDINOR",
        "ispb": "21866694",
        "participantDocument": "21866694000114",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDINOSSO LTDA - SICOOB CREDINOSSO",
        "ispb": "06324872",
        "participantDocument": "06324872000191",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDINTER LTDA. SICOOB CREDINTER",
        "ispb": "24048910",
        "participantDocument": "24048910000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDIOESTE LTDA. - SICOOB CREDIOESTE",
        "ispb": "25420696",
        "participantDocument": "25420696000136",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDIRAMA LTDA - SICOOB CREDIRAMA",
        "ispb": "03412878",
        "participantDocument": "03412878000122",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDISETE LTDA. - SICOOB CREDISETE",
        "ispb": "22753982",
        "participantDocument": "22753982000125",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDISG LTDA. - SICOOB CREDISG",
        "ispb": "03645752",
        "participantDocument": "03645752000106",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDIUNA LTDA SICOOB CREDIUNA",
        "ispb": "64237530",
        "participantDocument": "64237530000130",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDIVAR LTDA. - SICOOB CREDIVAR",
        "ispb": "25798596",
        "participantDocument": "25798596000148",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDIVERTENTES LTDA. SICOOB CREDIVERTENTES",
        "ispb": "22724710",
        "participantDocument": "22724710000105",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO CREDSAOPAULO - SICOOB CREDSAOPAULO",
        "ispb": "02197569",
        "participantDocument": "02197569000114",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DA AMAZÔNIA SICOOB AMAZÔNIA",
        "ispb": "05203605",
        "participantDocument": "05203605000101",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DA FOZ DO RIO ITAJAÍ AÇU - CREDIFOZ",
        "ispb": "09512539",
        "participantDocument": "09512539000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DA FRONTEIRA SUL SICREDI FRONTEIRA SUL RS",
        "ispb": "88530142",
        "participantDocument": "88530142000101",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DA GRANDE FLORIANÓPOLIS - ÚNILOS",
        "ispb": "02405189",
        "participantDocument": "02405189000128",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DA REGIÃO CENTRAL DE MINAS LTDA. SICOOB UNIÃO CENTRAL",
        "ispb": "22749014",
        "participantDocument": "22749014000145",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DA REGIÃO DE ARAXÁ LTDA. - SICOOB CREDIARA",
        "ispb": "66398496",
        "participantDocument": "66398496000101",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DA REGIÃO DE FRONTEIRAS DE RO/MT LTDA. - SICOOB FRONTEIRAS",
        "ispb": "03612764",
        "participantDocument": "03612764000126",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DA REGIÃO DO CONTESTADO - CIVIA",
        "ispb": "10218474",
        "participantDocument": "10218474000168",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DA REGIÃO DO SUDOESTE DO PARANÁ - EVOLUA",
        "ispb": "10311218",
        "participantDocument": "10311218000110",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DA REGIÃO DO VALE DO RIO GRANDE LTDA. - SICOOB CREDILEITE",
        "ispb": "86585049",
        "participantDocument": "86585049000179",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DA REGIÃO E COLAR METROPOLITANO DO VALE DO AÇO LTDA. - SICOOB COSMIPA",
        "ispb": "17361536",
        "participantDocument": "17361536000135",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DA REGIÃO MERIDIONAL DO BRASIL - SICOOB UNICOOB MERIDIONAL",
        "ispb": "05392810",
        "participantDocument": "05392810000154",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DA SERRA CATARINENSE - CREDICOMIN",
        "ispb": "09590601",
        "participantDocument": "09590601000176",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE CASCAVEL E REGIÃO - SICOOB CREDICAPITAL",
        "ispb": "04529074",
        "participantDocument": "04529074000170",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE EMPRESÁRIOS - SICOOB/TRANSCREDI",
        "ispb": "04247370",
        "participantDocument": "04247370000189",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE GUAPÉ LTDA - SICOOB CREDIGUAPÉ",
        "ispb": "71009237",
        "participantDocument": "71009237000181",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO CENTRO BRASILEIRA LTDA.",
        "ispb": "37395399",
        "participantDocument": "37395399000167",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO CENTRO OESTE LTDA.",
        "ispb": "07502031",
        "participantDocument": "07502031000190",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO CREDIEMBRAPA LTDA - SICOOB.",
        "ispb": "02338666",
        "participantDocument": "02338666000180",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA GRANDE GOIÂNIA LTDA.",
        "ispb": "10209619",
        "participantDocument": "10209619000164",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA GRANDE TERESINA E MICRORREGIÕES DE CAMPO MAIOR",
        "ispb": "05477038",
        "participantDocument": "05477038000173",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA MARGEM ESQUERDA DO URUCUIA E SÃO FRANCISCO LTDA - SICOOB CREDICHAPADA",
        "ispb": "14046512",
        "participantDocument": "14046512000101",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA PARAÍBA SICOOB PARAÍBA",
        "ispb": "11907520",
        "participantDocument": "11907520000107",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO CENTRAL DE RONDÔNIA SICOOB OUROCREDI",
        "ispb": "02144899",
        "participantDocument": "02144899000141",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO CENTRAL E OESTE MINEIRO LTDA. - SICOOB DIVICRED",
        "ispb": "01736516",
        "participantDocument": "01736516000161",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE DIVINÓPOLIS LTDA - SICOOB CREDIVERDE",
        "ispb": "25528753",
        "participantDocument": "25528753000103",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE FRUTAL LTDA. SICOOB FRUTAL",
        "ispb": "71419600",
        "participantDocument": "71419600000137",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE GUARIBA",
        "ispb": "44469161",
        "participantDocument": "44469161000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE GUAXUPÉ LTDA. - SICOOB ACICREDI",
        "ispb": "01699652",
        "participantDocument": "01699652000129",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE PARÁ DE MINAS LTDA - SICOOB CREDIRURAL",
        "ispb": "25387713",
        "participantDocument": "25387713000180",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE SÃO SEBASTIÃO DO PARAÍSO LTDA. - SICOOB PARAÍSOCRED",
        "ispb": "01657678",
        "participantDocument": "01657678000104",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE TIROS E MATUTINA LTDA - SICOOB CREDITIROS",
        "ispb": "42880617",
        "participantDocument": "42880617000199",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE TRÊS PONTAS LTDA. - SICOOB COPERSUL",
        "ispb": "18966739",
        "participantDocument": "18966739000118",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DO ALTO SÃO FRANCISCO LTDA. - SICOOB CREDIPRATA",
        "ispb": "26178111",
        "participantDocument": "26178111000186",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DO CAPARAÓ LTDA. - SICOOB SAÚDE",
        "ispb": "71261184",
        "participantDocument": "71261184000191",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DO CIRCUITO CAMPOS DAS VERTENTES LTDA - SICOOB COPERMEC",
        "ispb": "02232383",
        "participantDocument": "02232383000159",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO LESTE DA BACIA DO RIO DOCE LTDA SICOOB CREDILESTE",
        "ispb": "05158093",
        "participantDocument": "05158093000109",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO METROPOLITANA DE GOIÂNIA LTDA. - SECOVICRED",
        "ispb": "07599206",
        "participantDocument": "07599206000129",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO SUL DO MARANHÃO SICREDI SUL DO MARANHÃO",
        "ispb": "26191078",
        "participantDocument": "26191078000124",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA UNIÃO DOS VALES DO PIRANGA E MATIPÓ LTDA. SICOOB UNIÃO",
        "ispb": "01060307",
        "participantDocument": "01060307000140",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA ZONA DA MATA LTDA SICOOB COOPEMATA",
        "ispb": "02335109",
        "participantDocument": "02335109000105",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DAS MICRO- REGIÕES DE GOIÂNIA E ANÁPOLIS LTDA",
        "ispb": "02935307",
        "participantDocument": "02935307000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ANÁPOLIS E REGIÃO LTDA",
        "ispb": "33615055",
        "participantDocument": "33615055000165",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ARAXÁ LTDA - SICOOB COOCREZ",
        "ispb": "71378426",
        "participantDocument": "71378426000121",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ARCOS LTDA. - SICOOB ARCOMCREDI",
        "ispb": "71230338",
        "participantDocument": "71230338000188",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS - SICOOB CREDICARU SC/RS",
        "ispb": "00694389",
        "participantDocument": "00694389000112",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS AURIVERDE SICOOB CREDIAL/SC",
        "ispb": "78858107",
        "participantDocument": "78858107000162",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS CAMPOS NOVOS - SICOOB CAMPOS NOVOS",
        "ispb": "78862083",
        "participantDocument": "78862083000115",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DE CAÇADOR SICOOB CAÇADOR SC",
        "ispb": "86791837",
        "participantDocument": "86791837000111",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DE DORES DO INDAIÁ LTDA - COOPCREDI",
        "ispb": "02254376",
        "participantDocument": "02254376000158",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DE GUARAMIRIM - CREVISC",
        "ispb": "10143743",
        "participantDocument": "10143743000174",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DE JARAGUÁ DO SUL E REGIÃO - SICOOB CEJASCRED",
        "ispb": "12384953",
        "participantDocument": "12384953000180",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DE MANAUS",
        "ispb": "01566038",
        "participantDocument": "01566038000199",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DO ALTO URUGUAI CATARINENSE SICOOB CREDIAUC",
        "ispb": "78840071",
        "participantDocument": "78840071000190",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DO MEIO OESTE CATARINENSE - SICOOB CREDIMOC SC",
        "ispb": "80959638",
        "participantDocument": "80959638000193",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DO PLANALTO CATARINENSE SICOOB CREDIPLANALTO SC/RS",
        "ispb": "01389651",
        "participantDocument": "01389651000188",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DO PLANALTO SERRANO - SICOOB CREDISERRA SC",
        "ispb": "01692448",
        "participantDocument": "01692448000186",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DO VALE DO CANOINHAS - SICOOB CREDICANOINHAS/SC",
        "ispb": "78834975",
        "participantDocument": "78834975000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DO VALE DO VINHO - SICOOB VALE DO VINHO",
        "ispb": "81016131",
        "participantDocument": "81016131000169",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS ITAPIRANGA - SICOOB CREDITAPIRANGA SC/RS",
        "ispb": "78483310",
        "participantDocument": "78483310000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS NOVA TRENTO SICOOB TRENTOCREDI SC",
        "ispb": "02641969",
        "participantDocument": "02641969000177",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS OESTE CATARINENSE",
        "ispb": "78825023",
        "participantDocument": "78825023000122",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS SICOOB ECOCREDI",
        "ispb": "11565109",
        "participantDocument": "11565109000192",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS SUL CATARINENSE - SICOOB CREDISULCA SC",
        "ispb": "81367880",
        "participantDocument": "81367880000130",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS SÃO MIGUEL DO OESTE - SICOOB SÃO MIGUEL SC",
        "ispb": "81607046",
        "participantDocument": "81607046000175",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS UNIRONDÔNIA LTDA",
        "ispb": "01664968",
        "participantDocument": "01664968000185",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS URUBICI - SICOOB CREDIARAUCÁRIA/SC",
        "ispb": "02587492",
        "participantDocument": "02587492000199",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS VALE DO CHAPECOZINHO - SICOOB VALCREDI SUL",
        "ispb": "02090126",
        "participantDocument": "02090126000120",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS VALE DO RIO DO PEIXE - SICOOB CREDIRIO SC",
        "ispb": "78865995",
        "participantDocument": "78865995000140",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE BAMBUÍ LTDA. - SICOOB CREDIBAM",
        "ispb": "66259110",
        "participantDocument": "66259110000180",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE BELO HORIZONTE E CIDADES POLO DO ESTADO DE MINAS GERAIS LTDA. SICOOB NOSSACOOP",
        "ispb": "01760242",
        "participantDocument": "01760242000146",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE BOA ESPERANÇA LTDA. - SICOOB BELCREDI",
        "ispb": "00869687",
        "participantDocument": "00869687000104",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE CAMPOS GERAIS E CAMPO DO MEIO LTDA - SICOOB CREDCAM",
        "ispb": "71146450",
        "participantDocument": "71146450000135",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE CAPITÓLIO LTDA SICOOB CREDICAPI",
        "ispb": "00804046",
        "participantDocument": "00804046000163",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE CARMO DO RIO CLARO LTDA - SICOOB CREDICARMO",
        "ispb": "25743311",
        "participantDocument": "25743311000171",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE CERES E RIALMA LTDA",
        "ispb": "09576849",
        "participantDocument": "09576849000182",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE CONSELHEIRO PENA E REGIÃO LTDA. - SICOOB CREDICOPE",
        "ispb": "38588174",
        "participantDocument": "38588174000190",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE DORES DO INDAIÁ LTDA. - SICOOB CREDINDAIÁ",
        "ispb": "71069587",
        "participantDocument": "71069587000133",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE GOIÂNIA E MICRORREGIÕES LTDA.",
        "ispb": "01720488",
        "participantDocument": "01720488000194",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ITAJUBÁ LTDA - SICOOB SUL DE MINAS",
        "ispb": "04079285",
        "participantDocument": "04079285000159",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ITAÚNA E REGIÃO LTDA - SICOOB CENTRO- OESTE",
        "ispb": "66463407",
        "participantDocument": "66463407000163",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE JATAÍ E REGIÃO LTDA.",
        "ispb": "10143499",
        "participantDocument": "10143499000140",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE LAGOA DA PRATA E REGIÃO LTDA. SICOOB LAGOACRED GERAIS",
        "ispb": "01739375",
        "participantDocument": "01739375000130",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE MARECHAL CÂNDIDO RONDON E REGIÃO - SICOOB MARECHAL",
        "ispb": "07097064",
        "participantDocument": "07097064000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE PALMEIRAS E REGIÃO LTDA.",
        "ispb": "37631058",
        "participantDocument": "37631058000143",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE PEDRO LEOPOLDO LTDA - SICOOB CREDIPEL",
        "ispb": "71441406",
        "participantDocument": "71441406000158",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE PERNAMBUCO - SICOOB PERNAMBUCO",
        "ispb": "03732359",
        "participantDocument": "03732359000141",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE PIRACANJUBA LTDA",
        "ispb": "05222094",
        "participantDocument": "05222094000167",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE PITANGUI",
        "ispb": "41931221",
        "participantDocument": "41931221000160",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE POMPÉU LTDA. - SICOOB CREDIPÉU",
        "ispb": "66262643",
        "participantDocument": "66262643000111",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE RIO BRANCO LTDA",
        "ispb": "01608685",
        "participantDocument": "01608685000116",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE RIO VERDE E REGIÃO LTDA.",
        "ispb": "09033698",
        "participantDocument": "09033698000116",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE RORAIMA LTDA.",
        "ispb": "02137018",
        "participantDocument": "02137018000165",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE SANTA CRUZ DAS PALMEIRAS E REGIÃO",
        "ispb": "06910457",
        "participantDocument": "06910457000110",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE SANTO ANTÔNIO DO MONTE LTDA. SICOOB CREDIMONTE",
        "ispb": "41707258",
        "participantDocument": "41707258000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE SÃO ROQUE DE MINAS LTDA. SICOOB SAROMCREDI",
        "ispb": "66402207",
        "participantDocument": "66402207000109",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ACRE LTDA.",
        "ispb": "05439425",
        "participantDocument": "05439425000115",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ALTO PARANAÍBA, NOROESTE E OESTE DE MINAS LTDA - UNICRED INTEGRAÇÃO DE MINAS",
        "ispb": "01727929",
        "participantDocument": "01727929000180",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ALTO SÃO FRANCISCO LTDA.",
        "ispb": "41805003",
        "participantDocument": "41805003000180",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ALTO VALE DO ITAJAÍ - VIACREDI ALTO VALE",
        "ispb": "16779741",
        "participantDocument": "16779741000152",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CENTRO NORTE DOS ESTADOS DE MATO GROSSO E MATO GROSSO DO SUL SICOOB UNIÃO MT/MS",
        "ispb": "03326437",
        "participantDocument": "03326437000108",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CENTRO NORTE GOIANO",
        "ispb": "02282709",
        "participantDocument": "02282709000152",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CENTRO OESTE MINEIRO LTDA. SICOOB UNIÃO CENTRO OESTE",
        "ispb": "26072728",
        "participantDocument": "26072728000112",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CENTRO SUL DE MATO GROSSO DO SUL SICOOB CENTRO SUL MS",
        "ispb": "10319386",
        "participantDocument": "10319386000152",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CENTRO SUL RONDONIENSE - SICOOB CREDIP.",
        "ispb": "02015588",
        "participantDocument": "02015588000182",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CENTRO- SUL GOIANO LTDA",
        "ispb": "33579731",
        "participantDocument": "33579731000192",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CERRADO MATO-GROSSENSE - SICOOB CERRADO MT",
        "ispb": "05247312",
        "participantDocument": "05247312000118",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CIRCUITO DAS ÁGUAS - SICOOB CIRCUITO DAS ÁGUAS",
        "ispb": "03941182",
        "participantDocument": "03941182000193",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ESTADO DE SÃO PAULO - SICOOB PAULISTA",
        "ispb": "10262276",
        "participantDocument": "10262276000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ESTADO DO PARÁ - SICOOB COOESA",
        "ispb": "83836114",
        "participantDocument": "83836114000101",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ESTADO DO RIO DE JANEIRO - SICOOB FLUMINENSE",
        "ispb": "02931668",
        "participantDocument": "02931668000188",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO LESTE DE MINAS LTDA. - SICOOB CREDICAF",
        "ispb": "25395435",
        "participantDocument": "25395435000103",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO MÉDIO LESTE DE MATO GROSSO - SICOOB PRIMAVERA MT",
        "ispb": "05241619",
        "participantDocument": "05241619000101",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO MÉDIO PIRACICABA E DO CIRCUITO DO OURO LTDA. - SICOOB CREDIMEPI",
        "ispb": "01644264",
        "participantDocument": "01644264000140",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO NORDESTE DE MINAS GERAIS E SUL DA BAHIA LTDA - SICOOB CREDINORTE",
        "ispb": "64276058",
        "participantDocument": "64276058000145",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO NOROESTE DE MINAS GERAIS LTDA. SICOOB CREDIPINHO",
        "ispb": "71154876",
        "participantDocument": "71154876000130",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO NORTE DE MATO GROSSO - SICOOB NORTE MT",
        "ispb": "23623636",
        "participantDocument": "23623636000195",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO OESTE MARANHENSE - SICOOB OESTE MARANHENSE",
        "ispb": "09424988",
        "participantDocument": "09424988000190",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO OESTE MINEIRO LTDA. - SICOOB CREDICOPA",
        "ispb": "71297899",
        "participantDocument": "71297899000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO PONTAL DO TRIANGULO LTDA - SICOOB CREDIPONTAL",
        "ispb": "26178533",
        "participantDocument": "26178533000151",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO SERTÃO DE MINAS GERAIS LTDA. - SICOOB SERTÃO MINAS",
        "ispb": "01667352",
        "participantDocument": "01667352000168",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO SUDOESTE DA AMAZÔNIA LTDA. - SICOOB CREDISUL.",
        "ispb": "03632872",
        "participantDocument": "03632872000160",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO SUDOESTE GOIANO",
        "ispb": "24795049",
        "participantDocument": "24795049000146",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO SUL CATARINENSE - ACENTRA",
        "ispb": "03427097",
        "participantDocument": "03427097000101",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO TOCANTINS LTDA",
        "ispb": "26960328",
        "participantDocument": "26960328000143",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO TRIÂNGULO MINEIRO E SUDESTE DE GOIÁS LTDA. - SICOOB ARACREDI",
        "ispb": "00068987",
        "participantDocument": "00068987000186",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE - SICOOB CREDIVALE/SC",
        "ispb": "86829827",
        "participantDocument": "86829827000128",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE DO ARAGUAIA LTDA.",
        "ispb": "24830879",
        "participantDocument": "24830879000167",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE DO MACHADO - CREDISIS JICRED",
        "ispb": "02309070",
        "participantDocument": "02309070000151",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE DO PARANAPANEMA",
        "ispb": "01637949",
        "participantDocument": "01637949000160",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE DO PARANAÍBA LTDA.",
        "ispb": "24799033",
        "participantDocument": "24799033000101",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE DO RIO GRANDE LTDA. - SICOOB UBERABA",
        "ispb": "25683475",
        "participantDocument": "25683475000150",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE RIO CRIXÁS LTDA.",
        "ispb": "07108146",
        "participantDocument": "07108146000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO E DOS ADVOGADOS LTDA. - SICOOB CREDIJUR",
        "ispb": "02480577",
        "participantDocument": "02480577000173",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO E DOS TRANSPORTADORES RODOVIÁRIOS DE VEÍCULOS SICOOB CREDCEG",
        "ispb": "07789195",
        "participantDocument": "07789195000140",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO LTDA. SICOOB CREDIJUSTRA",
        "ispb": "37079720",
        "participantDocument": "37079720000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO MÉDIO OESTE - SICOOB MÉDIO OESTE",
        "ispb": "07318874",
        "participantDocument": "07318874000130",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO SICOOB ARENITO",
        "ispb": "07026923",
        "participantDocument": "07026923000161",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO SICOOB UNIMAIS CENTRO LESTE PAULISTA - SICOOB UNIMAIS CENTRO LESTE PAULISTA",
        "ispb": "01259518",
        "participantDocument": "01259518000107",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO SUL MARANHENSE - SICOOB SUL MARANHENSE",
        "ispb": "26526166",
        "participantDocument": "26526166000130",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO TRÊS FRONTEIRAS - SICOOB TRÊS FRONTEIRAS",
        "ispb": "04876393",
        "participantDocument": "04876393000152",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO UNIÃO DO CENTRO OESTE DE MINAS LTDA - SICOOB CREDESP",
        "ispb": "01703620",
        "participantDocument": "01703620000150",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO UNIÃO E NEGÓCIOS - SICOOB INTEGRAÇÃO",
        "ispb": "08742188",
        "participantDocument": "08742188000155",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO VALE DO MUCURI LTDA. - SICOOB CREDIVALE",
        "ispb": "25387655",
        "participantDocument": "25387655000195",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE PIMENTA LTDA. - SICOOB CREDIPIMENTA",
        "ispb": "42934794",
        "participantDocument": "42934794000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE PRODUTORES RURAIS E DE LIVRE ADMISSÃO DO TRIÂNGULO LTDA SICOOB CREDITRIL",
        "ispb": "41669227",
        "participantDocument": "41669227000101",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE RIO RUFINO - SICOOB CREDIUNIÃO",
        "ispb": "02448839",
        "participantDocument": "02448839000112",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE SERVIDORES PÚBLICOS COOPERPLAN LTDA. - SICOOB COOPERPLAN",
        "ispb": "02674113",
        "participantDocument": "02674113000106",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE SÃO FRANCISCO DE SALES LTDA. SICOOB CREDISALES",
        "ispb": "01374196",
        "participantDocument": "01374196000147",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DE VAZANTE LTDA. SICOOB CREDIVAZ",
        "ispb": "00803287",
        "participantDocument": "00803287000198",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO CENTRO LESTE E NORTE MARANHENSE - SICOOB CENTRO LESTE E NORTE MARANHENSE",
        "ispb": "09403026",
        "participantDocument": "09403026000155",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO CENTRO SUL MINEIRO LTDA. - SICOOB CENTRO SUL MINEIRO",
        "ispb": "71238232",
        "participantDocument": "71238232000120",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO DISTRITO FEDERAL E ENTORNO LTDA",
        "ispb": "01187961",
        "participantDocument": "01187961000110",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO LESTE DE SANTA CATARINA E DO PARANÁ LTDA - UNICRED UNIÃO",
        "ispb": "74114042",
        "participantDocument": "74114042000190",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO MÉDIO SUL BAIANO LTDA. SICOOB COSTA DO DESCOBRIMENTO",
        "ispb": "01205736",
        "participantDocument": "01205736000169",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO NOROESTE DE MINAS LTDA. - SICOOB NOROESTE DE MINAS",
        "ispb": "86564051",
        "participantDocument": "86564051000161",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO NORTE CATARINENSE - ACREDICOOP",
        "ispb": "03461243",
        "participantDocument": "03461243000115",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO NORTE CATARINENSE E SUL PARANAENSE - SICOOB CREDINORTE",
        "ispb": "81011686",
        "participantDocument": "81011686000118",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO NORTE E NOROESTE DE MINAS GERAIS LTDA. - SICOOB CREDIGERAIS",
        "ispb": "00698609",
        "participantDocument": "00698609000186",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO RIO GRANDE DO NORTE - SICOOB RIO GRANDE DO NORTE",
        "ispb": "04138455",
        "participantDocument": "04138455000129",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO SERTÃO BAIANO LTDA - SICOOB SERTÃO",
        "ispb": "02057584",
        "participantDocument": "02057584000167",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO SERVIDOR FEDERAL E DE EMPRESAS LTDA. - SICOOB CREDFAZ",
        "ispb": "00952415",
        "participantDocument": "00952415000165",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO SUDOESTE BAIANO LTDA SICOOB CREDICONQUISTA",
        "ispb": "02282165",
        "participantDocument": "02282165000129",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO TRIÂNGULO MINEIRO E SÃO FRANCISCO LTDA. - SICOOB ARACOOP",
        "ispb": "03320525",
        "participantDocument": "03320525000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO VALE DO ITAJAÍ E LITORAL CATARINENSE SICREDI VALE LITORAL SC",
        "ispb": "10348181",
        "participantDocument": "10348181000103",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO VALE DO ITAJAÍ E VALE DO ITAPOCÚ - SICOOB MULTICREDI",
        "ispb": "02883398",
        "participantDocument": "02883398000187",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO VALE DO SÃO FRANCISCO - SICREDI VALE DO SÃO FRANCISCO",
        "ispb": "04237413",
        "participantDocument": "04237413000145",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DO VALE EUROPEU - SICOOB EURO VALE",
        "ispb": "82133182",
        "participantDocument": "82133182000133",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS EMPREGADOS DA COMPANHIA INDUSTRIAL CATAGUASES E DA BAUMINAS LTDA. - SICOOB COOPECIC",
        "ispb": "19495266",
        "participantDocument": "19495266000180",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS EMPRESÁRIOS DE MANAUS - SICOOB CREDEMPRESAS-AM",
        "ispb": "10808037",
        "participantDocument": "10808037000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS EMPRESÁRIOS DE TRANSPORTES DO SUL DO BRASIL - TRANSPOCRED",
        "ispb": "08075352",
        "participantDocument": "08075352000118",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS EMPRESÁRIOS DO ESTADO DE GOIÁS LTDA",
        "ispb": "04406371",
        "participantDocument": "04406371000129",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS INTEGRANTES DO MINISTÉRIO PÚBLICO E DO PODER JUDICIÁRIO NOS ESTADOS DO AMAPÁ E CEARÁ E LIVRE ADMISSÃO NO ESTADO DO PARÁ - SICOOB COIMPPA",
        "ispb": "84156470",
        "participantDocument": "84156470000139",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS INTEGRANTES DO PODER JUDICIÁRIO E DAS INSTITUIÇÕES JURÍDICAS DA UNIÃO EM MINAS GERAIS LTDA. SICOOB COOPJUS",
        "ispb": "25363615",
        "participantDocument": "25363615000103",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS INTEGRANTES DO PODER JUDICIÁRIO E DO MINISTÉRIO PÚBLICO DO ESTADO DE MINAS GERAIS LTDA. - SICOOB JUS-MP",
        "ispb": "03519240",
        "participantDocument": "03519240000195",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS MAGISTRADOS, SERVIDORES DA JUSTIÇA DO ESTADO DE GOIÁS E EMPREGADOS DA CELG LTDA.",
        "ispb": "09552111",
        "participantDocument": "09552111000185",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS MEDICOS E DEMAIS PROFISSIONAIS DE NIVEL SUPERIOR DA AREA DE SAUDE DE SALVADOR E REGIAO METROPOLITANA LTDA - SICOOB CREDMED",
        "ispb": "07440317",
        "participantDocument": "07440317000198",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS MILITARES E DA SEGURANÇA PÚBLICA NO DISTRITO FEDERAL LTDA. - SICOOB DFMIL",
        "ispb": "04649337",
        "participantDocument": "04649337000185",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS MÉDICOS E DEMAIS PROFISSIONAIS DA SAÚDE DE VITÓRIA DA CONQUISTA",
        "ispb": "02833202",
        "participantDocument": "02833202000140",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS MÉDICOS E PROFISSIONAIS DE SAÚDE DE SÃO LUÍS - SICREDI SÃO LUÍS",
        "ispb": "08143326",
        "participantDocument": "08143326000180",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS MÉDICOS, SERVIDORES PÚBLICOS E EMPRESÁRIOS DO NORDESTE LTDA - UNICRED DO NORDESTE",
        "ispb": "02602922",
        "participantDocument": "02602922000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS MÉDICOS, TÉCNICOS E DEMAIS PROFISSIONAIS DA SAÚDE DO SUL DE MINAS LTDA., UNICRED SUL DE MINAS",
        "ispb": "71418784",
        "participantDocument": "71418784000110",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS PLANTADORES DE CANA DA REGIÃO DE LENÇÓIS PAULISTA",
        "ispb": "50848910",
        "participantDocument": "50848910000105",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS PROFISSIONAIS DA ÁREA DA SAÚDE E DE LIVRE ADMISSÃO LTDA - UNICRED ALIANÇA",
        "ispb": "70937271",
        "participantDocument": "70937271000153",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS PROFISSIONAIS DE SAÚDE DO TRIÂNGULO MINEIRO E SUL DE MINAS LTDA.",
        "ispb": "71432926",
        "participantDocument": "71432926000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS PROFISSIONAIS DOS CREAS E DEMAIS ÁREAS TECNOLÓGICAS CREDCREA",
        "ispb": "05979692",
        "participantDocument": "05979692000185",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS PROPRIETÁRIOS DA INDÚSTRIA DE ROCHAS ORNAMENTAIS, CAL E CALCÁRIOS DO ESTADO DO ESPÍRITO SANTO - SICOOB CREDIROCHAS",
        "ispb": "03358914",
        "participantDocument": "03358914000117",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS SERVIDORES DA UNIÃO, EMPRESÁRIOS, PROFISSIONAIS AUTÔNOMOS E LIBERAIS - SICOOB CENTRO NORDESTE",
        "ispb": "03102185",
        "participantDocument": "03102185000133",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS SERVIDORES DOS PODERES LEGISLATIVOS DO ESTADO DE MINAS GERAIS E DO SEU ÓRGÃO AUXILIAR LTDA. - SICOOB COFAL",
        "ispb": "21797311",
        "participantDocument": "21797311000101",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS SERVIDORES MILITARES, POLÍCIA CIVIL E DA SECRETARIA DE EDUCAÇÃO DO ESTADO DE MINAS GERAIS LTDA – SICOOB COOPEMG",
        "ispb": "03269540",
        "participantDocument": "03269540000163",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS NO ESTADO DA BAHIA LTDA. - SICOOB CRED EXECUTIVO",
        "ispb": "04321309",
        "participantDocument": "04321309000134",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E CAPTAÇÃO SICOOB UNICIDADES",
        "ispb": "03047549",
        "participantDocument": "03047549000120",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDARIA DE PORTO XAVIER CRESOL PORTO XAVIER",
        "ispb": "05442759",
        "participantDocument": "05442759000148",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE CORONEL FREITAS - CRESOL CORONEL FREITAS",
        "ispb": "08647473",
        "participantDocument": "08647473000197",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE CORONEL MARTINS - CRESOL PEDRA BRANCA",
        "ispb": "05070112",
        "participantDocument": "05070112000132",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE DIONÍSIO CERQUEIRA - CRESOL DIONÍSIO CERQUEIRA",
        "ispb": "02844024",
        "participantDocument": "02844024000152",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE FORMOSA DO SUL - CRESOL FORMOSA",
        "ispb": "05269976",
        "participantDocument": "05269976000188",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE GUARACIABA - CRESOL GUARACIABA",
        "ispb": "05400634",
        "participantDocument": "05400634000155",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE PINHALZINHO - CRESOL PINHALZINHO",
        "ispb": "07412987",
        "participantDocument": "07412987000109",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE SANTA TEREZINHA DO PROGRESSO - CRESOL SANTA TEREZINHA DO PROGRESSO",
        "ispb": "08652872",
        "participantDocument": "08652872000146",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE VITOR MEIRELES - CRESOL VITOR MEIRELES",
        "ispb": "07320890",
        "participantDocument": "07320890000168",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE XANXERÊ - CRESOL XANXERÊ",
        "ispb": "07509426",
        "participantDocument": "07509426000114",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE XAVANTINA - CRESOL XAVANTINA",
        "ispb": "08055016",
        "participantDocument": "08055016000103",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E INVESTIMENTO D`OESTE DE RONDÔNIA",
        "ispb": "05597773",
        "participantDocument": "05597773000110",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E INVESTIMENTO DE LIVRE ADMISSÃO AGROEMPRESARIAL - SICREDI AGROEMPRESARIAL PR/SP",
        "ispb": "79457883",
        "participantDocument": "79457883000113",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E INVESTIMENTO DO OESTE - CREDISIS OESTE",
        "ispb": "04985665",
        "participantDocument": "04985665000152",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E INVESTIMENTO DOS EMPREGADOS E SERVIDORES DA SECRETARIA ESTADUAL DE LOGÍSTICA E TRANSPORTES E ÓRGÃOS SUBORDINADOS DO ESTADO DE SÃO PAULO - SICOOB COOPTRANSP",
        "ispb": "66555665",
        "participantDocument": "66555665000170",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E INVESTIMENTOS DE RONDÔNIA SICOOB CREDJURD",
        "ispb": "04751713",
        "participantDocument": "04751713000148",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO E INVESTIMENTOS DO ACRE - SICOOB ACRE",
        "ispb": "03528402",
        "participantDocument": "03528402000151",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO GUARANICREDI LTDA. - SICOOB GUARANICREDI",
        "ispb": "26014175",
        "participantDocument": "26014175000141",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO HORIZONTE - SICOOB HORIZONTE",
        "ispb": "07194313",
        "participantDocument": "07194313000177",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO INTEGRADO - SICOOB UNICOOB INTEGRADO",
        "ispb": "07122321",
        "participantDocument": "07122321000108",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO INTEGRAÇÃO ROTA DAS TERRAS SICREDI INTEGRAÇÃO ROTA DAS TERRAS RS/MG",
        "ispb": "87510475",
        "participantDocument": "87510475000106",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO LESTE CAPIXABA - SICOOB LESTE CAPIXABA",
        "ispb": "32430233",
        "participantDocument": "32430233000110",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO LITORÂNEA",
        "ispb": "85291086",
        "participantDocument": "85291086000101",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO MAXI ALFA DE LIVRE ADMISSÃO DE ASSOCIADOS - SICOOB MAXICRÉDITO",
        "ispb": "78825270",
        "participantDocument": "78825270000129",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO MONTECREDI LTDA. SICOOB MONTECREDI",
        "ispb": "71392047",
        "participantDocument": "71392047000196",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO MÚTUO DOS ADVOGADOS DE SANTA CATARINA",
        "ispb": "05861932",
        "participantDocument": "05861932000142",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO MÚTUO DOS CORRETORES DE SEGUROS DO ESTADO DE SÃO PAULO - SICOOB CREDICOR/SP",
        "ispb": "08030602",
        "participantDocument": "08030602000101",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RIO GRANDE DO SUL - SICOOB CREDITRAN",
        "ispb": "04715685",
        "participantDocument": "04715685000103",
        "participation": "DIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO MÚTUO DOS EMPREGADOS DA EMBRAER E DA BOEING BRASIL COMMERCIAL - COOPEREMB",
        "ispb": "46642294",
        "participantDocument": "46642294000156",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO MÚTUO DOS EMPREGADOS DO MAGAZINE LUIZA, EMPRESAS CONTROLADAS E COLIGADAS – SICOOB COOPLUIZA",
        "ispb": "02093154",
        "participantDocument": "02093154000109",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO MÚTUO DOS MICRO E PEQUENOS EMPRESÁRIOS E MICROEMPREENDEDORES DO GRANDE ABC - SICOOB GRANDE ABC.",
        "ispb": "07946216",
        "participantDocument": "07946216000193",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO MÚTUO DOS MICROS E PEQUENOS EMPRESÁRIOS E MICROEMPREENDEDORES DE SÃO CARLOS - SICOOB CREDIACISC",
        "ispb": "07669921",
        "participantDocument": "07669921000190",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO MÚTUO DOS POLICIAIS FEDERAIS E SERVIDORES DA UNIÃO NO CEARÁ E LIVRE ADMISSÃO NAS MESORREGIÕES METROPOLITANA DE FORTALEZA E SUL CEARENSE - SICOOB CEARÁ",
        "ispb": "03620772",
        "participantDocument": "03620772000114",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO MÚTUO DOS PROFISSIONAIS DE NÍVEL SUPERIOR DA SAÚDE, ARQUITETOS E URBANISTAS DE SETE LAGOAS E REGIÃO LTDA - UNICRED SETE LAGOAS",
        "ispb": "71163315",
        "participantDocument": "71163315000106",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO MÚTUO DOS SERVIDORES DO MINISTERIO DA EDUCAÇÃO EM SÃO PAULO - COOPEMESP",
        "ispb": "73092827",
        "participantDocument": "73092827000146",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO MÚTUO DOS SERVIDORES FEDERAIS NA PARAÍBA - SICOOB COOPERCRET",
        "ispb": "02275781",
        "participantDocument": "02275781000152",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO MÚTUO DOS SERVIDORES MUNICIPAIS DE BEBEDOURO - COOCRESB.",
        "ispb": "05969937",
        "participantDocument": "05969937000193",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO MÚTUO DOS SERVIDORES PÚBLICOS MUNICIPAIS DE SÃO BERNARDO DO CAMPO - CREDIABC",
        "ispb": "03612679",
        "participantDocument": "03612679000168",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO MÚTUO SERRA MAR LTDA. - UNICRED SERRA MAR",
        "ispb": "86803939",
        "participantDocument": "86803939000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO NORTE DO ESPÍRITO SANTO - SICOOB NORTE",
        "ispb": "31815293",
        "participantDocument": "31815293000199",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO NORTE SUL DA BAHIA LTDA - SICOOB NORTE SUL",
        "ispb": "02876918",
        "participantDocument": "02876918000124",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO NOSSA SENHORA DO DESTERRO - SICOOB CREDISC",
        "ispb": "03419786",
        "participantDocument": "03419786000174",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO NOSSOCRÉDITO LTDA - SICOOB NOSSOCRÉDITO",
        "ispb": "22760839",
        "participantDocument": "22760839000160",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO ORIGINAL - SICOOB ORIGINAL",
        "ispb": "81292278",
        "participantDocument": "81292278000181",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO POUPANÇA E INVESTIMENTO DA ALTA NOROESTE DE SÃO PAULO - SICREDI ALTA NOROESTE SP",
        "ispb": "04484490",
        "participantDocument": "04484490000108",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO POUPANÇA E INVESTIMENTO DA ZONA SUL - SICREDI ZONA SUL RS",
        "ispb": "90497256",
        "participantDocument": "90497256000149",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO POUPANÇA E INVESTIMENTO DOS ADVOGADOS DO ESTADO DO RIO GRANDE DO SUL - SICREDI COOABCRED RS",
        "ispb": "19962468",
        "participantDocument": "19962468000195",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO POUPANÇA E INVESTIMENTO PANTANAL DO MATO GROSSO DO SUL - SICREDI PANTANAL MS",
        "ispb": "26408187",
        "participantDocument": "26408187000150",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL ASCOOB COOPEC - ASCOOB COOPEC",
        "ispb": "09244973",
        "participantDocument": "09244973000140",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL ASCOOB COOPERAR",
        "ispb": "02025743",
        "participantDocument": "02025743000141",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL ASCOOB ITAPICURU - ASCOOB ITAPICURU.",
        "ispb": "03921543",
        "participantDocument": "03921543000130",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DA COSTA OESTE - CRESOL COSTA OESTE",
        "ispb": "11969853",
        "participantDocument": "11969853000152",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE ALFREDO WAGNER - CRESOL ALFREDO WAGNER",
        "ispb": "05494591",
        "participantDocument": "05494591000114",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE APIÚNA - CRESOL APIÚNA",
        "ispb": "07215632",
        "participantDocument": "07215632000111",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE CURITIBANOS - CRESOL CURITIBANOS",
        "ispb": "01155801",
        "participantDocument": "01155801000199",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE DONA EMMA - CRESOL DONA EMMA",
        "ispb": "04261151",
        "participantDocument": "04261151000154",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE FREDERICO WESTPHALEN - CRESOL FREDERICO WESTPHALEN",
        "ispb": "17343510",
        "participantDocument": "17343510000164",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE GUARANI DAS MISSÕES - CRESOL GUARANI DAS MISSÕES",
        "ispb": "08488377",
        "participantDocument": "08488377000143",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE HUMAITÁ - CRESOL HUMAITÁ",
        "ispb": "05983995",
        "participantDocument": "05983995000171",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE IBIRAIARAS - CRESOL NORDESTE",
        "ispb": "06139650",
        "participantDocument": "06139650000107",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE IRINEÓPOLIS - CRESOL IRINEÓPOLIS",
        "ispb": "09433005",
        "participantDocument": "09433005000182",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE JACINTO MACHADO - CRESOL JACINTO MACHADO",
        "ispb": "09463721",
        "participantDocument": "09463721000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE PAIM FILHO - CRESOL PAIM FILHO",
        "ispb": "07252614",
        "participantDocument": "07252614000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE POUSO REDONDO - CRESOL POUSO REDONDO",
        "ispb": "10453077",
        "participantDocument": "10453077000170",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE RONDÔNIA - CRESOL RONDÔNIA",
        "ispb": "10520232",
        "participantDocument": "10520232000124",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE SANTO CRISTO - CRESOL SANTO CRISTO",
        "ispb": "06031727",
        "participantDocument": "06031727000112",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE SÃO JOÃO DO SUL - CRESOL SÃO JOÃO DO SUL",
        "ispb": "09488496",
        "participantDocument": "09488496000169",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE TANGARÁ - CRESOL TANGARÁ",
        "ispb": "07946451",
        "participantDocument": "07946451000165",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE TIRADENTES DO SUL - CRESOL TIRADENTES DO SUL",
        "ispb": "08805562",
        "participantDocument": "08805562000114",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE TREZE DE MAIO - CRESOL TREZE DE MAIO",
        "ispb": "09330158",
        "participantDocument": "09330158000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE WITMARSUM - CRESOL WITMARSUM",
        "ispb": "07465539",
        "participantDocument": "07465539000165",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE ÁUREA - CRESOL ÁUREA",
        "ispb": "02904138",
        "participantDocument": "02904138000140",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DO MATO GROSSO DO SUL - CRESOL MS",
        "ispb": "23256599",
        "participantDocument": "23256599000124",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA HORIZONTE - CRESOL HORIZONTE",
        "ispb": "05277312",
        "participantDocument": "05277312000160",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA LIDERANÇA - CRESOL LIDERANÇA",
        "ispb": "02934201",
        "participantDocument": "02934201000191",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA TRIUNFO - CRESOL TRIUNFO",
        "ispb": "08560508",
        "participantDocument": "08560508000156",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA VALE DAS ÁGUAS - CRESOL VALE DAS ÁGUAS",
        "ispb": "04663561",
        "participantDocument": "04663561000121",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL COOPAVEL",
        "ispb": "76461557",
        "participantDocument": "76461557000191",
        "participation": "DIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL DE ALTEROSA LTDA - SICOOB COOPEROSA",
        "ispb": "71437123",
        "participantDocument": "71437123000132",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL DE IRAÍ DE MINAS LTDA - SICOOB CREDIMIL",
        "ispb": "71207740",
        "participantDocument": "71207740000141",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL DE OURO SULCREDI/OURO",
        "ispb": "07853842",
        "participantDocument": "07853842000135",
        "participation": "DIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL DE SÃO MIGUEL DO OESTE - SULCREDI/SÃO MIGUEL",
        "ispb": "08253539",
        "participantDocument": "08253539000164",
        "participation": "DIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL DO SERTÃO ALAGOANO ASCOOB COCREAL",
        "ispb": "07564184",
        "participantDocument": "07564184000161",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDÁRIA CONCÓRDIA - CRESOL CONCÓRDIA",
        "ispb": "05425526",
        "participantDocument": "05425526000137",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE ARATIBA CRESOL ARATIBA",
        "ispb": "04565791",
        "participantDocument": "04565791000158",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE ITATIBA DO SUL - CRESOL ITATIBA DO SUL",
        "ispb": "05745533",
        "participantDocument": "05745533000116",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE JACUTINGA - CRESOL JACUTINGA",
        "ispb": "02904125",
        "participantDocument": "02904125000171",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE JAGUARUNA CRESOL JAGUARUNA",
        "ispb": "05410056",
        "participantDocument": "05410056000138",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE SÃO VALENTIM - CRESOL SÃO VALENTIM",
        "ispb": "03015152",
        "participantDocument": "03015152000156",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DO PLANALTO SERRA DO RIO GRANDE DO SUL - CRESOL PLANALTO SERRA",
        "ispb": "05863726",
        "participantDocument": "05863726000171",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SACRAMENTO LTDA - SICOOB SACRAMENTO",
        "ispb": "71154256",
        "participantDocument": "71154256000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICOOB ALIANÇA",
        "ispb": "06174009",
        "participantDocument": "06174009000103",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICOOB COOPEMAR LTDA",
        "ispb": "04174720",
        "participantDocument": "04174720000124",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICOOB COOPERBOM LTDA",
        "ispb": "74026998",
        "participantDocument": "74026998000130",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICOOB COOPMIL",
        "ispb": "62673470",
        "participantDocument": "62673470000173",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICOOB CRED-ACIF",
        "ispb": "04013172",
        "participantDocument": "04013172000150",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICOOB CREDCOOP LTDA.",
        "ispb": "02528151",
        "participantDocument": "02528151000142",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICOOB CREDMETAL",
        "ispb": "03535065",
        "participantDocument": "03535065000120",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICOOB ENGECRED LTDA.",
        "ispb": "04388688",
        "participantDocument": "04388688000180",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICOOB IPÊ - SICOOB IPÊ",
        "ispb": "24610065",
        "participantDocument": "24610065000117",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICOOB METROPOLITANO",
        "ispb": "03459850",
        "participantDocument": "03459850000140",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICOOB OURO VERDE",
        "ispb": "05582619",
        "participantDocument": "05582619000175",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICOOB TRANSAMAZÔNICA",
        "ispb": "24431221",
        "participantDocument": "24431221000182",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICOOB UNIDAS",
        "ispb": "01042487",
        "participantDocument": "01042487000138",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICOOB VALE SUL",
        "ispb": "02466552",
        "participantDocument": "02466552000115",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICOOBMAIS LTDA. SICOOBMAIS",
        "ispb": "26359745",
        "participantDocument": "26359745000135",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICREDI ALTO SERTÃO PARAIBANO - SICREDI ALTO SERTÃO PARAIBANO",
        "ispb": "09343038",
        "participantDocument": "09343038000131",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICREDI ARACAJU",
        "ispb": "02923389",
        "participantDocument": "02923389000172",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICREDI CENTRO PERNAMBUCANA",
        "ispb": "41255225",
        "participantDocument": "41255225000176",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICREDI PERNAMBUCRED",
        "ispb": "04146333",
        "participantDocument": "04146333000184",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SICREDI REGIÃO SUL DA BAHIA - SICREDI REGIÃO SUL DA BAHIA",
        "ispb": "97489280",
        "participantDocument": "97489280000185",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SUL - SICOOB SUL",
        "ispb": "05888589",
        "participantDocument": "05888589000120",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SUL DO ESPÍRITO SANTO - SICOOB SUL",
        "ispb": "32467086",
        "participantDocument": "32467086000153",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SUL- LITORÂNEA DO ESPÍRITO SANTO",
        "ispb": "32474884",
        "participantDocument": "32474884000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO SUL- SERRANA DO ESPÍRITO SANTO",
        "ispb": "00815319",
        "participantDocument": "00815319000175",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO TEIXEIRA DE FREITAS LTDA. SICOOB EXTREMO SUL",
        "ispb": "02447120",
        "participantDocument": "02447120000167",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO UNICOOB OURO BRANCO - SICOOB UNICOOB OURO BRANCO",
        "ispb": "05772229",
        "participantDocument": "05772229000168",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO UNICRED CENTRO-SUL LTDA UNICRED CENTRO-SUL",
        "ispb": "00075847",
        "participantDocument": "00075847000135",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO UNICRED COOMARCA LTDA. - COOMARCA",
        "ispb": "04355489",
        "participantDocument": "04355489000175",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO UNICRED VALOR CAPITAL LTDA UNICRED VALOR CAPITAL",
        "ispb": "74064502",
        "participantDocument": "74064502000112",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO UNIÃO DO CENTRO OESTE DE MINAS LTDA. - SICOOB CENTRO UNIÃO",
        "ispb": "65239402",
        "participantDocument": "65239402000199",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO VALE DO CANOAS - SICOOB/SC CREDICANOAS",
        "ispb": "00707903",
        "participantDocument": "00707903000107",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, INVESTIMENTO E SERVIÇOS FINANCEIROS SICOOB UNI SUDESTE",
        "ispb": "86389236",
        "participantDocument": "86389236000187",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO ALTO RIO GRANDE MG - SICREDI CREDIGRANDE MG",
        "ispb": "25626490",
        "participantDocument": "25626490000167",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO ALTO URUGUAI - SICREDI ALTO URUGUAI RS/SC/MG",
        "ispb": "87733770",
        "participantDocument": "87733770000121",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO BANDEIRANTES – SICREDI BANDEIRANTES SP",
        "ispb": "04853988",
        "participantDocument": "04853988000192",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO BOTUCARAÍ – SICREDI BOTUCARAÍ RS/MG",
        "ispb": "87900601",
        "participantDocument": "87900601000139",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO CAMPOS GERAIS - SICREDI CAMPOS GERAIS PR/SP",
        "ispb": "81466286",
        "participantDocument": "81466286000105",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO CELEIRO CENTRO OESTE – SICREDI CELEIRO CENTRO OESTE",
        "ispb": "03566655",
        "participantDocument": "03566655000110",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO CENTRO SERRA - SICREDI CENTRO SERRA RS",
        "ispb": "87067757",
        "participantDocument": "87067757000180",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO CENTRO SUL - SICREDI CENTRO SUL PR/SC/RJ",
        "ispb": "78907607",
        "participantDocument": "78907607000147",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DA REGIÃO ALTOS DA SERRA - SICREDI ALTOS DA SERRA RS/SC",
        "ispb": "92555150",
        "participantDocument": "92555150000180",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DA REGIÃO CENTRO OESTE PAULISTA - SICREDI CENTRO OESTE PAULISTA",
        "ispb": "04463602",
        "participantDocument": "04463602000136",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DA REGIÃO DA PRODUÇÃO - SICREDI REGIÃO DA PRODUÇÃO RS/SC/MG",
        "ispb": "89468565",
        "participantDocument": "89468565000101",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DA REGIÃO DAS FLORES, DAS ÁGUAS E DOS VENTOS SP - SICREDI FORÇA DOS VENTOS SP",
        "ispb": "57647653",
        "participantDocument": "57647653000184",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DA REGIÃO DE PALMEIRA DAS MISSÕES - SICREDI GRANDE PALMEIRA/RS",
        "ispb": "87789178",
        "participantDocument": "87789178000141",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DA REGIÃO DOS VALES - SICREDI REGIÃO DOS VALES RS",
        "ispb": "89126130",
        "participantDocument": "89126130000189",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DAS REGIÕES CENTRO DO RS E MG – SICREDI REGIÃO CENTRO RS/MG",
        "ispb": "95594941",
        "participantDocument": "95594941000107",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DE ARARAQUARA E REGIÃO - SICREDI CENTRO NORTE SP",
        "ispb": "73113243",
        "participantDocument": "73113243000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DE CAMPO GRANDE E REGIÃO - SICREDI CAMPO GRANDE MS",
        "ispb": "03042597",
        "participantDocument": "03042597000125",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DE CARLOS BARBOSA - SICREDI SERRANA RS",
        "ispb": "90608712",
        "participantDocument": "90608712000180",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DE ESPUMOSO – SICREDI ESPUMOSO RS/MG",
        "ispb": "87900411",
        "participantDocument": "87900411000111",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DE IBIRAIARAS – SICREDI IBIRAIARAS RS/MG",
        "ispb": "89990501",
        "participantDocument": "89990501000176",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DE LAJEADO - SICREDI INTEGRAÇÃO RS/MG",
        "ispb": "91159764",
        "participantDocument": "91159764000180",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO ARAGUAIA E XINGU - SICREDI ARAXINGU",
        "ispb": "33021064",
        "participantDocument": "33021064000128",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO CENTRO SUL DO MATO GROSSO DO SUL – SICREDI CENTRO-SUL MS",
        "ispb": "26408161",
        "participantDocument": "26408161000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO CERRADO DE GOIÁS - SICREDI CERRADO GO",
        "ispb": "06332931",
        "participantDocument": "06332931000173",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO NOROESTE DE MATO GROSSO, ACRE E AMAZONAS - SICREDI BIOMAS",
        "ispb": "33022690",
        "participantDocument": "33022690000139",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO NOROESTE DO ESTADO DE SÃO PAULO - SICREDI NOROESTE SP",
        "ispb": "03065046",
        "participantDocument": "03065046000187",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO NORTE DO PARANÁ E SUL DE SÃO PAULO - SICREDI NORTE SUL PR/SP",
        "ispb": "79063574",
        "participantDocument": "79063574000169",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO NORTE E NORDESTE DE SANTA CATARINA - SICREDI NORTE SC",
        "ispb": "02843443",
        "participantDocument": "02843443000170",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO PLANALTO CENTRAL - SICREDI PLANALTO CENTRAL",
        "ispb": "10736214",
        "participantDocument": "10736214000184",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO SUDOESTE MT/PA - SICREDI SUDOESTE MT/PA",
        "ispb": "32995755",
        "participantDocument": "32995755000160",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO SUL DE MATO GROSSO – SICREDI SUL MT",
        "ispb": "26549311",
        "participantDocument": "26549311000106",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO SUL DO ESTADO DE SANTA CATARINA – SICREDI SUL SC",
        "ispb": "03793242",
        "participantDocument": "03793242000178",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO VALE DO RIO PARDO - SICREDI VALE DO RIO PARDO RS",
        "ispb": "95424891",
        "participantDocument": "95424891000110",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS ESCRIVÃES, NOTÁRIOS E REGISTRADORES NO ESTADO DO PARANÁ - SICREDI CREDENOREG PR",
        "ispb": "06078926",
        "participantDocument": "06078926000186",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO UNIÃO PARANÁ/SÃO PAULO - SICREDI UNIÃO PR/SP",
        "ispb": "79342069",
        "participantDocument": "79342069000153",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS INTEGRANTES DA MAGISTRATURA E DO MINISTÉRIO PÚBLICO NO ESTADO DO PARANÁ - SICREDI CREDJURIS",
        "ispb": "04886317",
        "participantDocument": "04886317000128",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS JUÍZES E CARREIRAS PÚBLICAS DO DIREITO DO ESTADO DO RIO GRANDE DO SUL – SICREDI AJURIS RS",
        "ispb": "03750034",
        "participantDocument": "03750034000191",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS MILITARES ESTADUAIS DO RIO GRANDE DO SUL – SICREDI MIL RS",
        "ispb": "03212823",
        "participantDocument": "03212823000179",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS MÉDICOS E DEMAIS PROFISSIONAIS DA SAÚDE E LIVRE ADMISSÃO MINAS - ESPÍRITO SANTO LTDA. - UNICRED MINAS - ES",
        "ispb": "01110032",
        "participantDocument": "01110032000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS POLICIAIS FEDERAIS DO RIO GRANDE DO SUL E SANTA CATARINA – SICREDI POL RS/SC",
        "ispb": "03000142",
        "participantDocument": "03000142000147",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS PROFESSORES, FUNCIONÁRIOS E ALUNOS DA UNIVERSIDADE DE CAXIAS DO SUL – SICREDI COOPERUCS",
        "ispb": "04525997",
        "participantDocument": "04525997000154",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS PROFISSIONAIS MÉDICOS E DA SAÚDE DE CURITIBA E REGIÃO METROPOLITANA - SICREDI MEDICRED PR",
        "ispb": "02924977",
        "participantDocument": "02924977000120",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO EMPREENDEDORES - SICREDI EMPREENDEDORES PR",
        "ispb": "07070495",
        "participantDocument": "07070495000174",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO ENCOSTA SUPERIOR DO NORDESTE RS – SICREDI NORDESTE RS",
        "ispb": "95213211",
        "participantDocument": "95213211000119",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO FRONTEIRAS DO PARANÁ, SANTA CATARINA E SÃO PAULO - SICREDI FRONTEIRAS PR/SC/SP",
        "ispb": "82527557",
        "participantDocument": "82527557000140",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO GRANDES LAGOS DO PARANÁ E LITORAL PAULISTA – SICREDI GRANDES LAGOS PR/SP",
        "ispb": "81115149",
        "participantDocument": "81115149000118",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO INTEGRAÇÃO - SICREDI INTEGRAÇÃO PR/SC",
        "ispb": "81054686",
        "participantDocument": "81054686000103",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO INTEGRAÇÃO DE ESTADOS DO RIO GRANDE DO SUL E SANTA CATARINA - SICREDI INTEGRAÇÃO DE ESTADOS RS/SC",
        "ispb": "87781530",
        "participantDocument": "87781530000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO NOROESTE RS – SICREDI NOROESTE RS",
        "ispb": "89049738",
        "participantDocument": "89049738000157",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO NORTE MATO-GROSSENSE E OESTE PARAENSE – SICREDI GRANDES RIOS MT/PA",
        "ispb": "37442605",
        "participantDocument": "37442605000142",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO NOSSA TERRA - SICREDI NOSSA TERRA PR/SP",
        "ispb": "81192106",
        "participantDocument": "81192106000136",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO NOVOS HORIZONTES - SICREDI NOVOS HORIZONTES PR/SP",
        "ispb": "07206072",
        "participantDocument": "07206072000139",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO OURO BRANCO – SICREDI OURO BRANCO RS",
        "ispb": "87853206",
        "participantDocument": "87853206000142",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO OURO VERDE DO MATO GROSSO - SICREDI OURO VERDE MT",
        "ispb": "26529420",
        "participantDocument": "26529420000153",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO PAMPA GAÚCHO - SICREDI PAMPA GAÚCHO",
        "ispb": "87733077",
        "participantDocument": "87733077000159",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO PARANAPANEMA PR/SP - SICREDI PARANAPANEMA PR/SP",
        "ispb": "79086997",
        "participantDocument": "79086997000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO PLANALTO – SICREDI PLANALTO RS/MG",
        "ispb": "88038260",
        "participantDocument": "88038260000105",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO PROGRESSO - SICREDI PROGRESSO PR/SP",
        "ispb": "76059997",
        "participantDocument": "76059997000117",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO RAÍZES – SICREDI RAÍZES RS/SC/MG",
        "ispb": "88099247",
        "participantDocument": "88099247000158",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO REGIÕES DAS CULTURAS – SICREDI DAS CULTURAS RS/MG",
        "ispb": "90729369",
        "participantDocument": "90729369000122",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SERRO AZUL – SICREDI UNIÃO RS",
        "ispb": "88894548",
        "participantDocument": "88894548000173",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SICREDI EVOLUÇÃO - SICREDI EVOLUÇÃO",
        "ispb": "35571249",
        "participantDocument": "35571249000131",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SICREDI PIONEIRA RS - SICREDI PIONEIRA RS",
        "ispb": "91586982",
        "participantDocument": "91586982000109",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SICREDI RECIFE - SICREDI RECIFE",
        "ispb": "70241658",
        "participantDocument": "70241658000170",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SORRISO - SICREDI CELEIRO DO MT",
        "ispb": "26555235",
        "participantDocument": "26555235000133",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SUL MINAS DO RIO GRANDE DO SUL E MINAS GERAIS – SICREDI SUL MINAS RS/MG.",
        "ispb": "87784088",
        "participantDocument": "87784088000168",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SUL RIOGRANDENSE - SICREDI UNIÃO METROPOLITANA RS",
        "ispb": "92796564",
        "participantDocument": "92796564000109",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO TERRA DOS PINHEIRAIS DO PARANÁ E NOROESTE PAULISTA - SICREDI PLANALTO DAS ÁGUAS PR/SP",
        "ispb": "77984870",
        "participantDocument": "77984870000177",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO UNIVALES – SICREDI UNIVALES MT/RO",
        "ispb": "70431630",
        "participantDocument": "70431630000104",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO UNIÃO DE ESTADOS RIO GRANDE DO SUL, SANTA CATARINA E MINAS GERAIS - SICREDI UNIESTADOS",
        "ispb": "87780268",
        "participantDocument": "87780268000171",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO UNIÃO DOS ESTADOS DE MATO GROSSO DO SUL, TOCANTINS E OESTE DA BAHIA - SICREDI UNIÃO MS/TO",
        "ispb": "24654881",
        "participantDocument": "24654881000122",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO VALE DO CERRADO - SICREDI VALE DO CERRADO",
        "ispb": "32983165",
        "participantDocument": "32983165000117",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO VALE DO JAGUARI E ZONA DA MATA – SICREDI VALE DO JAGUARI E ZONA DA MATA RS/MG",
        "ispb": "87780284",
        "participantDocument": "87780284000164",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO VALE DO PIQUIRI ABCD - SICREDI VALE DO PIQUIRI ABCD PR/SP",
        "ispb": "81099491",
        "participantDocument": "81099491000171",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO VALOR SUSTENTÁVEL - SICREDI VALOR SUSTENTÁVEL PR/SP",
        "ispb": "81706616",
        "participantDocument": "81706616000184",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO VANGUARDA DA REGIÃO DAS CATARATAS DO IGUAÇU E VALE DO PARAÍBA - SICREDI VANGUARDA PR/SP/RJ",
        "ispb": "78414067",
        "participantDocument": "78414067000160",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA CRÉDITO MÚTUO MAGISTRADOS MEMBROS SERVIDORES PODER JUDICIÁRIO MINISTÉRIO PÚBLICO DEFENSORIA PÚBLICA PGE PGM POLÍCIA JUDICIÁRIA OFICIAIS REGISTRADORES CEARÁ SICREDI COOPERJURIS",
        "ispb": "08041950",
        "participantDocument": "08041950000176",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA CRÉDITO MÚTUO SICOOB CREDSAÚDE",
        "ispb": "02072790",
        "participantDocument": "02072790000146",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS APOSENTADOS, PENSIONISTAS E IDOSOS LIGADOS AO SINDICATO NACIONAL DOS APOSENTADOS, PENSIONISTAS E IDOSOS DA FORÇA SINDICAL - SICOOB COOPERNAPI",
        "ispb": "16564240",
        "participantDocument": "16564240000159",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DAS EMPRESAS RANDON",
        "ispb": "89280960",
        "participantDocument": "89280960000166",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FUNCIONARIOS DA COMIGO",
        "ispb": "01062439",
        "participantDocument": "01062439000101",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS INTEGRANTES DO MINISTERIO PUBLICO DO RIO GRANDE DO SUL - SICREDI MP",
        "ispb": "03662047",
        "participantDocument": "03662047000109",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MEDICOS DE PORTO ALEGRE LTDA- UNICRED PORTO ALEGRE",
        "ispb": "94433109",
        "participantDocument": "94433109000166",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MEDICOS E CATEGORIAS AFINS-UNICRED NITEROI LTDA",
        "ispb": "01090027",
        "participantDocument": "01090027000185",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MEDICOS E DEMAIS PROFISSIONAIS DA AREA DE SAUDE DO ESTADO DE SAO PAULO LTDA UNICRED DO ESTADO DE SAO PAULO",
        "ispb": "71884498",
        "participantDocument": "71884498000140",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERV DA SEC DE E DA SEG PUB E DA DEFESA SOCIAL DO E DO RN E DOS TRAB SINDICALIZADOS DA AREA DE SAUDE E AFINS NA REG MET DE NATAL - SICOOB POTIGUAR",
        "ispb": "02382755",
        "participantDocument": "02382755000123",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO SICOOB COOPERCREDI - SP",
        "ispb": "00915950",
        "participantDocument": "00915950000146",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO DE LIVRE ADMISSÃO LTDA. - SICOOB EMPRESARIAL",
        "ispb": "05856736",
        "participantDocument": "05856736000180",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO DOS EMPREGADOS DOS CORREIOS LTDA. - SICOOB COOPERCORREIOS",
        "ispb": "03862898",
        "participantDocument": "03862898000103",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO DOS EMPRESÁRIOS, PROFISSIONAIS DO MERCADO IMOBILIÁRIO E DA CMI / SECOVI - SICOOB SECOVICRED MG",
        "ispb": "14913919",
        "participantDocument": "14913919000180",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO - COOPERSERV",
        "ispb": "05667301",
        "participantDocument": "05667301000197",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DAS EMPRESAS, EMPREGADOS E PRESTADORES DE SERVIÇO DAS METALÚRGICAS, SIDERÚRGICAS E INDUSTRIAIS – SICOOB COPESITA",
        "ispb": "19875244",
        "participantDocument": "19875244000146",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE LEME - SICOOB CREDIACIL",
        "ispb": "08795285",
        "participantDocument": "08795285000106",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE LIVRE ADMISSÃO DE ARARAQUARA E REGIÃO - SICOOB",
        "ispb": "53236808",
        "participantDocument": "53236808000139",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE SOROCABA E REGIÃO SICOOB COOPERASO",
        "ispb": "10175348",
        "participantDocument": "10175348000173",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DO VALE DAS ANTAS LTDA. - UNICRED VALE DAS ANTAS",
        "ispb": "94243839",
        "participantDocument": "94243839000102",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS COLABORADORES DA VALE S.A LTDA. SICOOB CREDIVALE",
        "ispb": "20961629",
        "participantDocument": "20961629000105",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS CONTABILISTAS E CORRETORES DE SEGUROS DA GRANDE BELO HORIZONTE LTDA. - SICOOB CREDITABIL",
        "ispb": "02217068",
        "participantDocument": "02217068000152",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DA CEMIG E DAS INDÚSTRIAS METALÚRGICAS E DE MATERIAL ELÉTRICO E DOS SERVIDORES PÚBLICOS MUNICIPAIS DE JUIZ DE FORA LTDA. - SICOOB CECREMEC",
        "ispb": "17502881",
        "participantDocument": "17502881000141",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DA CENTRAIS ELÉTRICAS DE SANTA CATARINA - CREDELESC",
        "ispb": "08850613",
        "participantDocument": "08850613000120",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DA COOP - COOPERATIVA DE CONSUMO E DOS EMPREGADOS DO SUPER MERCADO ZONA SUL S/A",
        "ispb": "47074323",
        "participantDocument": "47074323000193",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DAS EMPRESAS CONTROLADAS E COLIGADAS DA USINA SANTA ADELIA - USAGRO",
        "ispb": "68969625",
        "participantDocument": "68969625000108",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DAS EMPRESAS DO GRUPO ECONÔMICO RHODIA - CREDIRHODIA",
        "ispb": "57563728",
        "participantDocument": "57563728000149",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DAS INSTITUIÇÕES DE ENSINO SUPERIOR DO SUL DE MINAS LTDA. - CREDFENAS",
        "ispb": "02414044",
        "participantDocument": "02414044000193",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DE FURNAS E DAS DEMAIS EMPRESAS DO SISTEMA ELETROBRÁS LTDA. SICOOB CECREMEF",
        "ispb": "33370115",
        "participantDocument": "33370115000127",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DO GRUPO ALGAR E EMPREENDEDORES DO TRIÂNGULO MINEIRO LTDA - SICOOB TRIÂNGULO",
        "ispb": "01296040",
        "participantDocument": "01296040000195",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPRESÁRIOS DA REGIÃO METROPOLITANA DE JOÃO PESSOA, LITORAL PARAIBANO E DOS SERVIDORES PÚBLICOS ESTADUAIS DA PARAÍBA - SICOOB UNIÃO PARAIBANA",
        "ispb": "03373150",
        "participantDocument": "03373150000139",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPRESÁRIOS DE AMERICANA, LIMEIRA E REGIÃO - SICOOB ACICRED",
        "ispb": "09579249",
        "participantDocument": "09579249000178",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPRESÁRIOS DE ITAPETININGA - SICOOB CRED-ACI",
        "ispb": "08844074",
        "participantDocument": "08844074000116",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPRESÁRIOS DE PRAIA GRANDE E REGIÃO - SICOOB COOPERACE",
        "ispb": "09639338",
        "participantDocument": "09639338000162",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS ENGENHEIROS, ARQUITETOS, AGRÔNOMOS E AFINS DO ESTADO DO RIO GRANDE DO SUL - PROJETA UNICRED",
        "ispb": "09187555",
        "participantDocument": "09187555000169",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FUNCIONÁRIOS DA CEPLAC LTDA. - SICOOB COOPEC",
        "ispb": "32615247",
        "participantDocument": "32615247000109",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FUNCIONÁRIOS DA SECRETARIA DE ESTADO DA FAZENDA E ÓRGÃOS OFICIAIS DO ESTADO DE MINAS GERAIS LTDA SICOOB COOPSEF",
        "ispb": "16721078",
        "participantDocument": "16721078000135",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS INTEGRANTES DO MINISTÉRIO PÚBLICO E DA DEFENSORIA PÚBLICA NO ESTADO DO RIO DE JANEIRO LIMITADA. - SICOOB COMPERJ",
        "ispb": "04694278",
        "participantDocument": "04694278000167",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MAGISTRADOS DE SÃO PAULO - MAGISCRED",
        "ispb": "51489318",
        "participantDocument": "51489318000119",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MILITARES ESTADUAIS DE SANTA CATARINA - CREDPOM",
        "ispb": "04572960",
        "participantDocument": "04572960000187",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MÉDICOS DOS VALES DO TAQUARI E RIO PARDO E REGIÃO DA PRODUÇÃO LTDA",
        "ispb": "01635462",
        "participantDocument": "01635462000148",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MÉDICOS E DEMAIS PROFISSIONAIS DA SAÚDE DE TEÓFILO OTONI LTDA.",
        "ispb": "01709266",
        "participantDocument": "01709266000170",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MÉDICOS E DEMAIS PROFISSIONAIS DA SAÚDE, ENGENHARIA, ARQUITETURA, CONTABILIDADE, ADMINISTRAÇÃO, ECONOMIA E EMPRESÁRIOS DE ERECHIM LTDA",
        "ispb": "01572667",
        "participantDocument": "01572667000121",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MÉDICOS E DEMAIS PROFISSIONAIS DA ÁREA DE SAÚDE DA REGIÃO DE RIBEIRÃO PRETO - MEDCRED DE RIBEIRÃO PRETO",
        "ispb": "03575699",
        "participantDocument": "03575699000106",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MÉDICOS E DEMAIS PROFISSIONAIS DA ÁREA DE SAÚDE DE NÍVEL SUPERIOR DE JUIZ DE FORA LTDA. - UNICRED JUIZ DE FORA",
        "ispb": "71479653",
        "participantDocument": "71479653000143",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MÉDICOS E DEMAIS PROFISSIONAIS DE NÍVEL SUPERIOR DA ÁREA DE SAÚDE DE OURINHOS - OURICRED",
        "ispb": "02179673",
        "participantDocument": "02179673000186",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MÉDICOS, DEMAIS PROFISSIONAIS DA SAÚDE E EMPRESÁRIOS DE CRUZ ALTA LTDA - SICOOB CRUZ ALTA",
        "ispb": "73326449",
        "participantDocument": "73326449000118",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MÉDICOS, DEMAIS PROFISSIONAIS DE NÍVEL SUPERIOR DA ÁREA DE SAÚDE E EMPRESÁRIOS DE GUARULHOS E REGIÃO",
        "ispb": "02250794",
        "participantDocument": "02250794000177",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS PROFISSIONAIS DA SAÚDE DA REGIÃO DA ALTA MOGIANA - SICOOB CREDIMOGIANA",
        "ispb": "69346856",
        "participantDocument": "69346856000110",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS PROFISSIONAIS DA ÁREA NOTARIAL E REGISTRAL - COOPNORE",
        "ispb": "07714057",
        "participantDocument": "07714057000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SECURITARIOS, DOS CORRETORES DE SEGUROS E DE CAPITALIZACAO DO RIO DE JANEIRO LTDA.",
        "ispb": "03930587",
        "participantDocument": "03930587000126",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DA FUNDAÇÃO HOSPITALAR E EMPREGADOS DOS ESTABELECIMENTOS HOSPITALARES DE BELO HORIZONTE, REGIÃO METROPOLITANA E ZONA DA MATA LTDA.- SICOOB CECREF",
        "ispb": "19402130",
        "participantDocument": "19402130000189",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DA POLÍCIA MILITAR DO ESTADO DE SÃO PAULO DA REGIÃO CENTRO- OESTE PAULISTA - CREDMIL",
        "ispb": "04152107",
        "participantDocument": "04152107000106",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DA SECRETARIA DE ESTADO DE FAZENDA DO DISTRITO FEDERAL - SICOOB CREDSEF",
        "ispb": "03603683",
        "participantDocument": "03603683000160",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DA UNIVERSIDADE FEDERAL DE VIÇOSA LTDA. - SICOOB UFVCREDI",
        "ispb": "02794761",
        "participantDocument": "02794761000198",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DO PODER EXECUTIVO FEDERAL, DOS SERVIDORES DA SECRETARIA DE SAÚDE E DOS TRABALHADORES EM ENSINO DO DISTRITO FEDERAL LTDA. SICOOB EXECUTIVO",
        "ispb": "00694877",
        "participantDocument": "00694877000120",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DO PODER LEGISLATIVO FEDERAL E DO DISTRITO FEDERAL - SICOOB LEGISLATIVO",
        "ispb": "03329154",
        "participantDocument": "03329154000110",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DO SEMASA - SICOOB CECRESA",
        "ispb": "00512839",
        "participantDocument": "00512839000109",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES MUNICIPAIS DA REGIÃO METROPOLITANA DO VALE DO PARAÍBA E LITORAL NORTE - SICOOB-CRESSEM",
        "ispb": "54190525",
        "participantDocument": "54190525000166",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES MUNICIPAIS DE ITABIRA LTDA. - SICOOB COSEMI",
        "ispb": "16651002",
        "participantDocument": "16651002000180",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES MUNICIPAIS DE POÇOS DE CALDAS LTDA. - COOPOÇOS",
        "ispb": "41791005",
        "participantDocument": "41791005000167",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES PÚBLICOS FEDERAIS DO MUNICÍPIO DE UBERABA E SUAS FUNDAÇÕES - SICOOB - CREDIMED LTDA.",
        "ispb": "71336432",
        "participantDocument": "71336432000116",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES PÚBLICOS NO ESTADO DO ESPÍRITO SANTO",
        "ispb": "01330295",
        "participantDocument": "01330295000127",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS TRABALHADORES DA CIA. DE PROCESSAMENTO DE DADOS DO ESTADO DE SÃO PAULO - CREDIPRODESP",
        "ispb": "67915868",
        "participantDocument": "67915868000192",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS TRABALHADORES NA ÁREA DE SAÚDE, AFINS E EMPRESÁRIOS VINCULADOS À ACCI DE ERECHIM-RS-CREDISUL",
        "ispb": "07494300",
        "participantDocument": "07494300000113",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO NOS ESTADOS DE ALAGOAS, SERGIPE E BAHIA - SICOOB LESTE",
        "ispb": "02493000",
        "participantDocument": "02493000000104",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO SERVIDORES PJ, MP, ENSINO SUPERIOR ADVOGADOS PÚBLICOS, DEFENSORES PÚBLICOS E DELEGADOS POLÍCIA FEDERAL NO DISTRITO FEDERAL - SICOOB JUDICIÁRIO",
        "ispb": "37076205",
        "participantDocument": "37076205000160",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO SICOOB CRED- ACILPA",
        "ispb": "09004796",
        "participantDocument": "09004796000125",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO SICOOB METALCRED",
        "ispb": "04833655",
        "participantDocument": "04833655000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO SICOOB SANTA",
        "ispb": "65560658",
        "participantDocument": "65560658000101",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO UNICRED ELEVA LTDA.",
        "ispb": "95163002",
        "participantDocument": "95163002000108",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO UNICRED IJUÍ LTDA - UNICRED IJUÍ",
        "ispb": "01526924",
        "participantDocument": "01526924000199",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO UNICRED INTEGRAÇÃO LTDA.",
        "ispb": "73750424",
        "participantDocument": "73750424000147",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO UNICRED PONTO CAPITAL - UNICRED PONTO CAPITAL.",
        "ispb": "02641032",
        "participantDocument": "02641032000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO UNICRED REGIÃO DA CAMPANHA LTDA - UNICRED REGIÃO DA CAMPANHA",
        "ispb": "01705236",
        "participantDocument": "01705236000196",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO SICOOB COOPERAC",
        "ispb": "08071414",
        "participantDocument": "08071414000113",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA DE POUPANÇA E CRÉDITO DO VALE DO RIO DOCE LTDA. SICOOB CREDIRIODOCE",
        "ispb": "25606237",
        "participantDocument": "25606237000141",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA ECONOMIA CRÉDITO MÚTUO SERVIDORES E EMPREGADOS MUNIC PREF MUNIC BELO HORIZONTE BETIM BRUMADINHO CONTAGEM IBIRITÉ NOVA LIMA RIBEIRÃO DAS NEVES SABARÁ SANTA LUZIA VESPASIANO SICOOB CREDISERV",
        "ispb": "01864151",
        "participantDocument": "01864151000150",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COOPERATIVA ECONOMIA CRÉDITO MÚTUO SERVIDORES PODER JUDICIÁRIO, TRIBUNAL CONTAS ESTADUAL E SERVIDORES ÓRGÃOS ENTIDADES ÁREA CIÊNCIA TECNOLOGIA NO ESTADO DO RIO DE JANEIRO LTDA.",
        "ispb": "02000895",
        "participantDocument": "02000895000190",
        "participation": "INDIRETA"
    },
    {
        "companyName": "COPERCRED - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DAS EMPRESAS ZILLO LORENZETTI",
        "ispb": "58022195",
        "participantDocument": "58022195000150",
        "participation": "INDIRETA"
    },
    {
        "companyName": "CORA SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ispb": "37880206",
        "participantDocument": "37880206000163",
        "participation": "INDIRETA"
    },
    {
        "companyName": "CREDICOAMO CRÉDITO RURAL COOPERATIVA",
        "ispb": "81723108",
        "participantDocument": "81723108000104",
        "participation": "DIRETA"
    },
    {
        "companyName": "CREDISAN COOPERATIVA DE CRÉDITO",
        "ispb": "62109566",
        "participantDocument": "62109566000103",
        "participation": "DIRETA"
    },
    {
        "companyName": "CREDISIS - CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.",
        "ispb": "04632856",
        "participantDocument": "04632856000130",
        "participation": "DIRETA"
    },
    {
        "companyName": "CREDITAS SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ispb": "32997490",
        "participantDocument": "32997490000139",
        "participation": "DIRETA"
    },
    {
        "companyName": "CREFISA S.A.- CRÉDITO",
        "ispb": "60779196",
        "participantDocument": "60779196000196",
        "participation": "INDIRETA"
    },
    {
        "companyName": "CLOUD WALK MEIOS DE PAGAMENTOS E SERVIÇOS LTDA.",
        "ispb": "18189547",
        "participantDocument": "18189547000142",
        "participation": "INDIRETA"
    },
    {
        "companyName": "DLOCAL BRASIL PAGAMENTOS LTDA.",
        "ispb": "25021356",
        "participantDocument": "25021356000132",
        "participation": "INDIRETA"
    },
    {
        "companyName": "DMCARD MEIOS DE PAGAMENTO LTDA.",
        "ispb": "22371651",
        "participantDocument": "22371651000120",
        "participation": "INDIRETA"
    },
    {
        "companyName": "DOCK SOLUCOES EM MEIOS DE PAGAMENTO S.A.",
        "ispb": "08744817",
        "participantDocument": "08744817000186",
        "participation": "INDIRETA"
    },
    {
        "companyName": "EWALLY TECNOLOGIA E SERVICOS S.A.",
        "ispb": "00714671",
        "participantDocument": "00714671000114",
        "participation": "INDIRETA"
    },
    {
        "companyName": "FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LIMITADA.",
        "ispb": "04307598",
        "participantDocument": "04307598000117",
        "participation": "DIRETA"
    },
    {
        "companyName": "FLAGSHIP INSTITUIÇÃO DE PAGAMENTO LTDA.",
        "ispb": "23114447",
        "participantDocument": "23114447000197",
        "participation": "INDIRETA"
    },
    {
        "companyName": "FREEDOM2BUY.COM BRASIL LTDA.",
        "ispb": "03856113",
        "participantDocument": "03856113000181",
        "participation": "INDIRETA"
    },
    {
        "companyName": "GERENCIANET S.A.",
        "ispb": "09089356",
        "participantDocument": "09089356000118",
        "participation": "DIRETA"
    },
    {
        "companyName": "GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.",
        "ispb": "11165756",
        "participantDocument": "11165756000107",
        "participation": "DIRETA"
    },
    {
        "companyName": "GALAX PAY PAGAMENTOS ELETRÔNICOS LTDA.",
        "ispb": "30765018",
        "participantDocument": "30765018000145",
        "participation": "INDIRETA"
    },
    {
        "companyName": "HUB PAGAMENTOS S. A.",
        "ispb": "13884775",
        "participantDocument": "13884775000119",
        "participation": "DIRETA"
    },
    {
        "companyName": "IS2B - INTEGRATED SOLUTIONS TO BUSINESS S.A.",
        "ispb": "13935893",
        "participantDocument": "13935893000109",
        "participation": "INDIRETA"
    },
    {
        "companyName": "ITAÚ UNIBANCO S.A.",
        "ispb": "60701190",
        "participantDocument": "60701190000104",
        "participation": "DIRETA"
    },
    {
        "companyName": "IUGU SERVICOS NA INTERNET S/A",
        "ispb": "15111975",
        "participantDocument": "15111975000164",
        "participation": "DIRETA"
    },
    {
        "companyName": "LISTO SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ispb": "34088029",
        "participantDocument": "34088029000199",
        "participation": "DIRETA"
    },
    {
        "companyName": "MADEIRA ADMINISTRADORA DE CARTOES LTDA.",
        "ispb": "32192325",
        "participantDocument": "32192325000100",
        "participation": "INDIRETA"
    },
    {
        "companyName": "MAG PAGAMENTOS LTDA.",
        "ispb": "21995256",
        "participantDocument": "21995256000156",
        "participation": "INDIRETA"
    },
    {
        "companyName": "MERCADOPAGO.COM REPRESENTACOES LTDA.",
        "ispb": "10573521",
        "participantDocument": "10573521000191",
        "participation": "DIRETA"
    },
    {
        "companyName": "MK DIGITAL BANK S.A.",
        "ispb": "35624319",
        "participantDocument": "35624319000172",
        "participation": "INDIRETA"
    },
    {
        "companyName": "MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LTDA.",
        "ispb": "11581339",
        "participantDocument": "11581339000145",
        "participation": "DIRETA"
    },
    {
        "companyName": "NEON PAGAMENTOS S.A.",
        "ispb": "20855875",
        "participantDocument": "20855875000182",
        "participation": "INDIRETA"
    },
    {
        "companyName": "NK 061 EMPREENDIMENTOS E PARTICIPACOES S.A.",
        "ispb": "34878543",
        "participantDocument": "34878543000128",
        "participation": "INDIRETA"
    },
    {
        "companyName": "NU PAGAMENTOS S.A.",
        "ispb": "18236120",
        "participantDocument": "18236120000158",
        "participation": "DIRETA"
    },
    {
        "companyName": "ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ispb": "34335592",
        "participantDocument": "34335592000114",
        "participation": "DIRETA"
    },
    {
        "companyName": "PAGAR.ME PAGAMENTOS S.A.",
        "ispb": "18727053",
        "participantDocument": "18727053000174",
        "participation": "INDIRETA"
    },
    {
        "companyName": "PAGGCERTO SOLUCOES EM PAGAMENTO S.A.",
        "ispb": "17819084",
        "participantDocument": "17819084000192",
        "participation": "INDIRETA"
    },
    {
        "companyName": "PAGSEGURO INTERNET S.A.",
        "ispb": "08561701",
        "participantDocument": "08561701000101",
        "participation": "DIRETA"
    },
    {
        "companyName": "PARANÁ BANCO S.A.",
        "ispb": "14388334",
        "participantDocument": "14388334000199",
        "participation": "DIRETA"
    },
    {
        "companyName": "PARATI - CRÉDITO",
        "ispb": "03311443",
        "participantDocument": "03311443000191",
        "participation": "DIRETA"
    },
    {
        "companyName": "PAYMEE BRASIL SERVICOS DE PAGAMENTOS S.A.",
        "ispb": "28683892",
        "participantDocument": "28683892000191",
        "participation": "INDIRETA"
    },
    {
        "companyName": "PEFISA S.A. - CRÉDITO",
        "ispb": "43180355",
        "participantDocument": "43180355000112",
        "participation": "DIRETA"
    },
    {
        "companyName": "PICPAY SERVICOS S.A.",
        "ispb": "22896431",
        "participantDocument": "22896431000110",
        "participation": "DIRETA"
    },
    {
        "companyName": "PLURAL S.A. BANCO MÚLTIPLO",
        "ispb": "45246410",
        "participantDocument": "45246410000155",
        "participation": "DIRETA"
    },
    {
        "companyName": "PAGUEVELOZ SERVIÇOS DE PAGAMENTOS LTDA.",
        "ispb": "03816413",
        "participantDocument": "03816413000137",
        "participation": "INDIRETA"
    },
    {
        "companyName": "PÓLOCRED SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.",
        "ispb": "07945233",
        "participantDocument": "07945233000106",
        "participation": "DIRETA"
    },
    {
        "companyName": "RECARGAPAY PAGAMENTOS LTDA.",
        "ispb": "11275560",
        "participantDocument": "11275560000175",
        "participation": "INDIRETA"
    },
    {
        "companyName": "ROADPASS PAYMENTS & URBAN MOBILITY LTDA.",
        "ispb": "25104230",
        "participantDocument": "25104230000121",
        "participation": "INDIRETA"
    },
    {
        "companyName": "S3 TECNOLOGIA E SOLUCOES DE PAGAMENTOS S.A.",
        "ispb": "31711972",
        "participantDocument": "31711972000118",
        "participation": "INDIRETA"
    },
    {
        "companyName": "SICOOB AC CREDI - COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO LESTE E NORDESTE MINEIRO LTDA.",
        "ispb": "01559914",
        "participantDocument": "01559914000150",
        "participation": "INDIRETA"
    },
    {
        "companyName": "SICOOB COCRED COOPERATIVA DE CRÉDITO",
        "ispb": "71328769",
        "participantDocument": "71328769000181",
        "participation": "INDIRETA"
    },
    {
        "companyName": "SICOOB CRED COPERCANA COOPERATIVA DE CRÉDITO",
        "ispb": "64739121",
        "participantDocument": "64739121000132",
        "participation": "INDIRETA"
    },
    {
        "companyName": "SICOOB CREDICOM - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MEDICOS E PROFISSIONAIS DA AREA DE SAUDE DO BRASIL LTDA.",
        "ispb": "42898825",
        "participantDocument": "42898825000115",
        "participation": "INDIRETA"
    },
    {
        "companyName": "SICOOB CREDIGOIÁS COOPERATIVA DE CRÉDITO LTDA.",
        "ispb": "37255049",
        "participantDocument": "37255049000103",
        "participation": "INDIRETA"
    },
    {
        "companyName": "SICOOB CREDIMONTES - COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO REGIONAL DE MONTES CLAROS LTDA",
        "ispb": "02031139",
        "participantDocument": "02031139000128",
        "participation": "INDIRETA"
    },
    {
        "companyName": "SICOOB CREDINOVA - COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE NOVA SERRANA E REGIÃO CENTRO- OESTE LTDA.",
        "ispb": "01667766",
        "participantDocument": "01667766000197",
        "participation": "INDIRETA"
    },
    {
        "companyName": "SICOOB UNIMAIS MANTIQUEIRA - COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO",
        "ispb": "71698674",
        "participantDocument": "71698674000150",
        "participation": "INDIRETA"
    },
    {
        "companyName": "SICOOB UNIMAIS METROPOLITANA COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO",
        "ispb": "00259231",
        "participantDocument": "00259231000114",
        "participation": "INDIRETA"
    },
    {
        "companyName": "SICREDI ALAGOAS - COOPERATIVA DE CRÉDITO",
        "ispb": "41180092",
        "participantDocument": "41180092000116",
        "participation": "INDIRETA"
    },
    {
        "companyName": "SICREDI CARIRI - COOPERATIVA DE CRÉDITO DA REGIÃO DO CARIRI",
        "ispb": "86913993",
        "participantDocument": "86913993000108",
        "participation": "INDIRETA"
    },
    {
        "companyName": "SICREDI CEARÁ CENTRO NORTE - COOPERATIVA DE CRÉDITO DA REGIÃO CENTRO NORTE DO CEARÁ",
        "ispb": "72257793",
        "participantDocument": "72257793000130",
        "participation": "INDIRETA"
    },
    {
        "companyName": "SICREDI COOMAMP - COOPERATIVA DE CRÉDITO DOS MEMBROS DE INSTITUIÇÕES PÚBLICAS DAS CARREIRAS JURÍDICAS E DOS SERVIDORES PÚBLICOS FEDERAIS, ESTADUAIS E MUNICIPAIS EM SÃO LUÍS/MA E MUNICÍPIOS CIRCUNVIZIN",
        "ispb": "05545390",
        "participantDocument": "05545390000107",
        "participation": "INDIRETA"
    },
    {
        "companyName": "SICREDI CREDUNI - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERV DAS INST PÚBLICAS DE ENSINO SUPERIOR DO ESTADO DA PARAÍBA E DAS DEMAIS INSTITUIÇÕES E ÓRGÃOS PÚBLICOS NO ESTADO DA PARAIBA LTDA.",
        "ispb": "03428338",
        "participantDocument": "03428338000137",
        "participation": "INDIRETA"
    },
    {
        "companyName": "SICREDI NORTE - COOPERATIVA DE CRÉDITO",
        "ispb": "83315408",
        "participantDocument": "83315408000180",
        "participation": "INDIRETA"
    },
    {
        "companyName": "SOCIEDADE COOPERATIVA DE CRÉDITO COOPERE LTDA. - SICOOB COOPERE",
        "ispb": "73398646",
        "participantDocument": "73398646000142",
        "participation": "INDIRETA"
    },
    {
        "companyName": "SPIN PAY SERVICOS DE PAGAMENTOS LTDA.",
        "ispb": "32219232",
        "participantDocument": "32219232000121",
        "participation": "INDIRETA"
    },
    {
        "companyName": "STONE PAGAMENTOS S.A.",
        "ispb": "16501555",
        "participantDocument": "16501555000157",
        "participation": "DIRETA"
    },
    {
        "companyName": "SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.",
        "ispb": "37241230",
        "participantDocument": "37241230000152",
        "participation": "DIRETA"
    },
    {
        "companyName": "SUPER PAGAMENTOS E ADMINISTRAÇÃO DE MEIOS ELETRÔNICOS S.A.",
        "ispb": "09554480",
        "participantDocument": "09554480000107",
        "participation": "DIRETA"
    },
    {
        "companyName": "STARK BANK S.A.",
        "ispb": "20018183",
        "participantDocument": "20018183000180",
        "participation": "INDIRETA"
    },
    {
        "companyName": "TRAVELEX BANCO DE CÂMBIO S.A.",
        "ispb": "11703662",
        "participantDocument": "11703662000144",
        "participation": "DIRETA"
    },
    {
        "companyName": "TRIVALE ADMINISTRACÃO LTDA",
        "ispb": "00604122",
        "participantDocument": "00604122000197",
        "participation": "INDIRETA"
    },
    {
        "companyName": "U4CRYPTO SOLUÇÕES TECNOLÓGICAS E FINANCEIRAS S.A.",
        "ispb": "30980539",
        "participantDocument": "30980539000115",
        "participation": "INDIRETA"
    },
    {
        "companyName": "UNICRED COSTA DO SOL RJ COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO LTDA.",
        "ispb": "39231527",
        "participantDocument": "39231527000162",
        "participation": "INDIRETA"
    },
    {
        "companyName": "UNICRED REGIONAL NORTE LAGOS - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO REGIONAL NORTE LAGOS LTDA",
        "ispb": "04445917",
        "participantDocument": "04445917000150",
        "participation": "INDIRETA"
    },
    {
        "companyName": "UNIPRIME ALLIANCE - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO",
        "ispb": "01736243",
        "participantDocument": "01736243000155",
        "participation": "INDIRETA"
    },
    {
        "companyName": "UNIPRIME CAMPOS GERAIS - COOPERATIVA DE CRÉDITO LTDA.",
        "ispb": "03234384",
        "participantDocument": "03234384000103",
        "participation": "INDIRETA"
    },
    {
        "companyName": "UNIPRIME CENTRAL - CENTRAL INTERESTADUAL DE COOPERATIVAS DE CRÉDITO LTDA.",
        "ispb": "03046391",
        "participantDocument": "03046391000173",
        "participation": "DIRETA"
    },
    {
        "companyName": "UNIPRIME CENTRO-OESTE DO BRASIL - COOPERATIVA DE CRÉDITO",
        "ispb": "73647935",
        "participantDocument": "73647935000138",
        "participation": "INDIRETA"
    },
    {
        "companyName": "UNIPRIME DO IGUAÇU - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS PROFISSIONAIS DA SAÚDE, DAS CIÊNCIAS, DAS ARTES E EMPRESÁRIOS",
        "ispb": "01848322",
        "participantDocument": "01848322000158",
        "participation": "INDIRETA"
    },
    {
        "companyName": "UNIPRIME NORTE DO PARANÁ - COOPERATIVA DE CRÉDITO LTDA",
        "ispb": "02398976",
        "participantDocument": "02398976000190",
        "participation": "DIRETA"
    },
    {
        "companyName": "UNIPRIME PAULISTA- COOPERATIVA DE CRÉDITO",
        "ispb": "74220948",
        "participantDocument": "74220948000199",
        "participation": "INDIRETA"
    },
    {
        "companyName": "UNIPRIME PIONEIRA COOPERATIVA DE CRÉDITO",
        "ispb": "01286361",
        "participantDocument": "01286361000109",
        "participation": "INDIRETA"
    },
    {
        "companyName": "UNIPRIME SUL MS - COOPERATIVA DE CRÉDITO",
        "ispb": "37554441",
        "participantDocument": "37554441000145",
        "participation": "INDIRETA"
    },
    {
        "companyName": "VERDE - ADMINISTRADORA DE CARTOES DE CRÉDITO S.A.",
        "ispb": "01722480",
        "participantDocument": "01722480000167",
        "participation": "INDIRETA"
    },
    {
        "companyName": "VINDI PAGAMENTOS LTDA.",
        "ispb": "24816123",
        "participantDocument": "24816123000163",
        "participation": "INDIRETA"
    },
    {
        "companyName": "WIRECARD BRAZIL S.A.",
        "ispb": "08718431",
        "participantDocument": "08718431000108",
        "participation": "INDIRETA"
    },
    {
        "companyName": "ZEMA CRÉDITO",
        "ispb": "05351887",
        "participantDocument": "05351887000186",
        "participation": "DIRETA"
    },
    {
        "companyName": "ZOOP TECNOLOGIA E MEIOS DE PAGAMENTO S.A.",
        "ispb": "19468242",
        "participantDocument": "19468242000132",
        "participation": "INDIRETA"
    },
    {
        "companyName": "ZRO PAGAMENTOS S/A",
        "ispb": "26264220",
        "participantDocument": "26264220000116",
        "participation": "INDIRETA"
    }
]
<div class="modal-content">

  <main [ngClass]="{'darkMode': modeScreen.theme == 'dark', 'lightMode': modeScreen.theme == 'light' }">

    <svg (click)="close()" class="icons_close" width="25px" height="25px" viewBox="0 0 24 24"
      [style.fill]="modeScreen.color">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path [style.fill]="modeScreen.color"
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
    </svg>

    <header>
      <div (click)="close()">
        <svg class="icon_back" height="48" width="48">
          <path [style.fill]="modeScreen.color" d="M20 44 0 24 20 4 22.8 6.85 5.65 24 22.8 41.15Z" />
        </svg>
      </div>
      <div class="title" [style.color]="modeScreen.color"> Detalhes da Transferência </div>
    </header>

    <div class="card_loading" *ngIf="loading">
      <span style="margin-left: 5px;" style="color: #fff;" class="spinner-border spinner-border-sm" role="status"
        aria-hidden="true"></span>
    </div>

    <section *ngIf="!loading" class="card_info">

      <div class="column_info">

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Agência de origem : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.account_obj?.agency != 'null' ? transfer.account_obj?.agency
            :
            '-' }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Conta de origem : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.account_obj?.account != 'null' ?
            transfer.account_obj?.account
            : '-' }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Nome da conta de origem : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.account_obj?.name != 'null' ? transfer.account_obj?.name: '-'
            }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> UUID da conta de origem : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.account_obj?.uuid != 'null' ? transfer.account_obj?.uuid :
            '-'
            }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Tag : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.tag != null ? transfer.tag : '-' }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Status : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.status != null ? transfer.status : '-' }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Valor : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> R$ {{ transfer.amount != null ? transfer.amount : '0' }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Tipo de conta : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.to_account_type != null ? transfer.to_account_type : '-' }}
          </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Tipo de transferência : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.transfer_type != null ? transfer.transfer_type : '-' }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Chave : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.key != null ? transfer.key : '-' }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> UUID da operação : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.operationUuid != null ? transfer.operationUuid : '-' }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> UUID : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.uuid != null ? transfer.uuid : '-' }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Id de transação : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.transactionId != null ? transfer.transactionId : '-' }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Criado em : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.created_at | date: 'dd/MM/yyyy HH:mm' }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Última atualização : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.updated_at | date: 'dd/MM/yyyy HH:mm' }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Comentários : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.comments != null ? transfer.comments : '-' }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Metadata : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.metadata != null ? transfer.metadata : '-' }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Nome do destinatário : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.recipient_name != null ? transfer.recipient_name : '-' }}
          </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Conta do destinatário : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.recipient_account_id != null ? transfer.recipient_account_id
            :
            '-' }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Agência do Destinatário : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.recipient_branch_id != null ? transfer.recipient_branch_id
            :
            '-' }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Instituição do destinatário : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color">
            {{ transfer.recipient_instution != null ? checkInstution(transfer.recipient_instution) : '-' }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Documento do destinatário : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
          <p [style.color]="modeScreen.color"> {{ transfer.recipient_legal_id != null ? transfer.recipient_legal_id :
            '-'
            }} </p>
        </div>

        <div class="row_info">
          <h3 [style.color]="modeScreen.color"> Descrição do erro : </h3>
          <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>

          <span [style.color]="modeScreen.color" class="text_error" *ngIf="edit_error">
            <input type="text" class="input_description" [(ngModel)]="transfer.new_erro_descriptor">
          </span>

          <p [style.color]="modeScreen.color" *ngIf="!edit_error"> {{ (transfer.new_erro_descriptor != null &&
            transfer.new_erro_descriptor != '' && transfer.new_erro_descriptor != 'null') ? transfer.new_erro_descriptor
            : '-' }} </p>
        </div>

        <div class="card_empty"></div>
        <div class="card_empty"></div>
      </div>
    </section>

    <button mat-button (click)="setErrorInput()"> {{ text_button }} </button>

    <section *ngIf="!loading" class="update card_table">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" (opened)="panelOpen = true" (closed)="panelOpen = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Alterações
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="card_table">
            <table aria-describedby="table" mat-table [dataSource]="dataSource">
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Id
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.id != null && element.id != '' ? element.id : '-' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Status
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ formatStatus(element.status, element) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="created_by">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Criado por
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.user_name ? element.user_name : '-' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Criado em
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.created_at | date: 'dd/MM/yyyy HH:mm' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="error" class="card_error">
                <th mat-header-cell *matHeaderCellDef class="font14 card_error">
                  Descrição de Erro
                </th>
                <td mat-cell *matCellDef="let element" class="card_error">
                  <span class="text_error" *ngIf="!edit_error"> {{ element.new_erro_descriptor ?
                    checkError(element.new_erro_descriptor) : '-' }} </span>
                  <span class="text_error" *ngIf="edit_error">
                    <input type="text" class="input_description" [(ngModel)]="element.new_erro_descriptor">
                  </span>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="11" style="text-align: center">
                  Nenhum resultado encontrado.
                </td>
              </tr>
            </table>
          </div>

        </mat-expansion-panel>
      </mat-accordion>
    </section>

  </main>
</div>

<div>
  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark fixed-top"
    [ngClass]="{'darkMode': modeScreen.theme == 'dark', 'lightMode': modeScreen.theme == 'light'  }"
    [containerInside]="false">

    <span (click)="drawer.toggle()">
      <svg id="menu" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"
        [style.fill]="modeScreen.icon">
        <path id="Path_535" data-name="Path 535" d="M0,0H36V36H0Z" fill="none" />
        <path id="Path_536" data-name="Path 536" d="M7,26H18.9V23H7Zm0-9H32.5V14H7ZM18.9,5V8H32.5V5Z"
          transform="translate(-1 2.5)" />
      </svg>
    </span>

    <img [src]="modeScreen.theme == 'dark' ? './assets/images/saQ-digital-W.png' : './assets/images/saQ-digital-B.png'"
      class="logo" alt="logo" />

    <ul class="navbar-nav ml-auto">
      <li class="nav-item" (click)="styleMode()">
        <app-icon-dark-light></app-icon-dark-light>
      </li>
      <!-- <li class="nav-item" (click)="styleMode()">
        <svg *ngIf="modeScreen.theme == 'light'" id="Component_1_1" data-name="Component 1 – 1"
          xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" [style.fill]="modeScreen.icon">
          <g id="sun">
            <path id="Path_831" data-name="Path 831" d="M0,0H24V24H0Z" fill="none" />
            <path id="Path_832" data-name="Path 832"
              d="M6.76,4.84,4.96,3.05,3.55,4.46,5.34,6.25ZM1,10.5H4v2H1ZM11,.55h2V3.5H11Zm8.04,2.495,1.408,1.407-1.79,1.79L17.251,4.834ZM17.24,18.16l1.79,1.8,1.41-1.41-1.8-1.79ZM20,10.5h3v2H20Zm-8-5a6,6,0,1,0,6,6A6,6,0,0,0,12,5.5Zm-1,14h2v2.95H11Zm-7.45-.96,1.41,1.41,1.79-1.8L5.34,16.74Z" />
          </g>
        </svg>
        <svg *ngIf="modeScreen.theme == 'dark'" id="Component_1_2" data-name="Component 1 – 2"
          xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" [style.fill]="modeScreen.icon">
          <g id="dark_mode_black_24dp">
            <rect id="Rectangle_491" data-name="Rectangle 491" width="24" height="24" fill="none" />
            <path id="Path_830" data-name="Path 830"
              d="M12,3a9,9,0,1,0,9,9,10.122,10.122,0,0,0-.1-1.36A5.4,5.4,0,1,1,13.36,3.1,10.122,10.122,0,0,0,12,3Z"
              fill="#fcfcfc" />
          </g>
        </svg>
      </li> -->
    </ul>
  </mdb-navbar>

  <mat-drawer-container autosize>
    <mat-drawer #drawer mode="side" opened class="sidenav"
      [ngClass]="{'drawerDark': modeScreen.theme == 'dark', 'drawerLight': modeScreen.theme == 'light' }">
      <div class="drawer-container"
        [ngClass]="{'darkMode': modeScreen.theme == 'dark', 'lightMode': modeScreen.theme == 'light' }">

        <div class="profile">

          <img *ngIf="user?.profile_image == null || user?.profile_image == ''" class="circle bg_circle"
            src="../assets/images/person_black.svg" alt="Imagem de perfil">

          <img *ngIf="user?.profile_image != null && user?.profile_image != ''" class="circle"
            [src]=user.profile_image_url alt="Imagem de perfil">

          <span [style.color]="modeScreen.color"> {{ user.name ? user.name : '' }} </span>
          <p [style.color]="modeScreen.color" routerLink="profile">Editar perfil</p>
        </div>

        <div class="screens">
          <div class="item" routerLink="home"
            [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
            [ngClass]="{'select': url == 'home'}">
            <svg id="dashboard_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
              [style.fill]="modeScreen.icon">
              <path id="Path_924" data-name="Path 924" d="M0,0H24V24H0Z" fill="none" />
              <path id="Path_925" data-name="Path 925" d="M3,13h8V3H3Zm0,8h8V15H3Zm10,0h8V11H13ZM13,3V9h8V3Z" />
            </svg>
            <span [style.color]="modeScreen.color"> Início </span>
          </div>

          <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <svg id="apartment_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" [style.fill]="modeScreen.icon">
                    <g id="Group_430" data-name="Group 430">
                      <rect id="Rectangle_2766" data-name="Rectangle 2766" width="24" height="24" fill="none" />
                    </g>
                    <g id="Group_431" data-name="Group 431" transform="translate(2.981 2.981)">
                      <path id="Path_908" data-name="Path 908"
                        d="M16.912,10.95V3H6.975V6.975H3V20.887h7.95V16.912h1.987v3.975h7.95V10.95ZM6.975,18.9H4.987V16.912H6.975Zm0-3.975H4.987V12.937H6.975Zm0-3.975H4.987V8.962H6.975Zm3.975,3.975H8.962V12.937H10.95Zm0-3.975H8.962V8.962H10.95Zm0-3.975H8.962V4.987H10.95Zm3.975,7.95H12.937V12.937h1.987Zm0-3.975H12.937V8.962h1.987Zm0-3.975H12.937V4.987h1.987ZM18.9,18.9H16.912V16.912H18.9Zm0-3.975H16.912V12.937H18.9Z"
                        transform="translate(-3 -3)" />
                    </g>
                  </svg>
                  <span [style.color]="modeScreen.color" class="open"> Empresas </span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="item" routerLink="tariffs"
                [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
                [ngClass]="{'select': url == 'tariffs'}">
                <mat-icon> attach_money </mat-icon>
                <span [style.color]="modeScreen.color"> Tarifas </span>
              </div>

              <div class="item" routerLink="companies"
                [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
                [ngClass]="{'select': url == 'companies'}">

                <svg id="description_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" [style.fill]="modeScreen.icon">
                    <path id="Path_845" data-name="Path 845" d="M0,0H24V24H0Z" fill="none" />
                    <path id="Path_846" data-name="Path 846"
                      d="M14,2H6A2,2,0,0,0,4.01,4L4,20a2,2,0,0,0,1.99,2H18a2.006,2.006,0,0,0,2-2V8Zm2,16H8V16h8Zm0-4H8V12h8ZM13,9V3.5L18.5,9Z" />
                  </svg>

                <span [style.color]="modeScreen.color"> Cadastros </span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <!-- <div class="item" routerLink="type-of-users"
            [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
            [ngClass]="{'select': url == 'type-of-users'}">
            <svg id="manage_accounts_black_24dp" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
              viewBox="0 0 32 32" [style.fill]="modeScreen.icon">
              <g id="Group_461" data-name="Group 461">
                <path id="Path_921" data-name="Path 921" d="M0,0H32V32H0Z" fill="none" />
              </g>
              <g id="Group_463" data-name="Group 463" transform="translate(2.667 4.8)">
                <g id="Group_462" data-name="Group 462">
                  <circle id="Ellipse_44" data-name="Ellipse 44" cx="5.5" cy="5.5" r="5.5"
                    transform="translate(5.333 0.2)" />
                  <path id="Path_922" data-name="Path 922"
                    d="M13.56,13.027c-.293-.013-.587-.027-.893-.027a17.168,17.168,0,0,0-8.813,2.427A3.9,3.9,0,0,0,2,18.8v3.533H14.347A9.284,9.284,0,0,1,12.667,17,9.429,9.429,0,0,1,13.56,13.027Z"
                    transform="translate(-2 -0.467)" />
                  <path id="Path_923" data-name="Path 923"
                    d="M23.61,17.667a5.784,5.784,0,0,0-.08-.84l1.52-1.347-1.333-2.307-1.933.653a4.862,4.862,0,0,0-1.44-.84L19.943,11H17.277l-.4,1.987a4.862,4.862,0,0,0-1.44.84L13.5,13.173,12.17,15.48l1.52,1.347a5.784,5.784,0,0,0-.08.84,5.784,5.784,0,0,0,.08.84l-1.52,1.347L13.5,22.16l1.933-.653a4.862,4.862,0,0,0,1.44.84l.4,1.987h2.667l.4-1.987a4.862,4.862,0,0,0,1.44-.84l1.933.653,1.333-2.307-1.52-1.347A5.784,5.784,0,0,0,23.61,17.667Zm-5,2.667a2.667,2.667,0,1,1,2.667-2.667A2.675,2.675,0,0,1,18.61,20.333Z"
                    transform="translate(1.39 -1.133)" />
                </g>
              </g>
            </svg>

            <span [style.color]="modeScreen.color"> Tipos de usuários </span>
          </div> -->

          <div class="item" routerLink="users"
            [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
            [ngClass]="{'select': url == 'users'}">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
              [style.fill]="modeScreen.icon">
              <g id="people_black_24dp" transform="translate(0)">
                <path id="Path_909" data-name="Path 909" d="M0,0H24V24H0Z" transform="translate(0)" fill="none" />
                <path id="Path_910" data-name="Path 910"
                  d="M15.988,10.964a2.982,2.982,0,1,0-3-2.982A2.977,2.977,0,0,0,15.988,10.964Zm-7.994,0A2.982,2.982,0,1,0,5,7.982,2.977,2.977,0,0,0,7.995,10.964Zm0,1.988C5.666,12.951,1,14.114,1,16.43v2.485H14.989V16.43C14.989,14.114,10.323,12.951,7.995,12.951Zm7.994,0c-.29,0-.62.02-.969.05a4.19,4.19,0,0,1,1.968,3.429v2.485h6V16.43C22.983,14.114,18.317,12.951,15.988,12.951Z"
                  transform="translate(0.009 0.043)" />
              </g>
            </svg>
            <span [style.color]="modeScreen.color"> Usuários </span>
          </div>

          <!-- <div class="item" routerLink="business-group"
            [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
            [ngClass]="{'select': url == 'business-group'}">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
              [style.fill]="modeScreen.icon">
              <g id="paid_black_24dp" transform="translate(0 -0.534)">
                <g id="Group_459" data-name="Group 459" transform="translate(0 0.333)">
                  <rect id="Rectangle_2785" data-name="Rectangle 2785" width="24" height="24"
                    transform="translate(0 0.2)" fill="none" />
                </g>
                <g id="Group_460" data-name="Group 460" transform="translate(2.038 2.119)">
                  <path id="Path_918" data-name="Path 918"
                    d="M12.188,2A10.188,10.188,0,1,0,22.377,12.188,10.192,10.192,0,0,0,12.188,2Zm.9,16.057V19.32H11.3V18.006A4.113,4.113,0,0,1,8.225,14.99l1.681-.683a2.691,2.691,0,0,0,2.445,2.129c.948,0,2.017-.489,2.017-1.64,0-.978-.713-1.488-2.323-2.068-1.121-.4-3.413-1.049-3.413-3.372A3.136,3.136,0,0,1,11.3,6.34V5.057h1.783V6.32A3.49,3.49,0,0,1,15.8,8.592l-1.61.683A1.969,1.969,0,0,0,12.25,7.909c-.713,0-1.844.377-1.844,1.416,0,.968.876,1.335,2.69,1.936,2.445.846,3.067,2.089,3.067,3.515A3.378,3.378,0,0,1,13.085,18.057Z"
                    transform="translate(-2 -2)" />
                </g>
              </g>
            </svg>

            <span [style.color]="modeScreen.color"> Grupo Econômico </span>
          </div> -->

          <div class="item" routerLink="baas"
            [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
            [ngClass]="{'select': url == 'baas'}">
            <svg id="account_balance_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
              viewBox="0 0 24 24" [style.fill]="modeScreen.icon">
              <g id="Group_456" data-name="Group 456">
                <rect id="Rectangle_2780" data-name="Rectangle 2780" width="24" height="24" fill="none" />
              </g>
              <g id="Group_458" data-name="Group 458">
                <g id="Group_457" data-name="Group 457">
                  <rect id="Rectangle_2781" data-name="Rectangle 2781" width="3" height="7"
                    transform="translate(4 10)" />
                  <rect id="Rectangle_2782" data-name="Rectangle 2782" width="3" height="7"
                    transform="translate(10.5 10)" />
                  <rect id="Rectangle_2783" data-name="Rectangle 2783" width="20" height="3"
                    transform="translate(2 19)" />
                  <rect id="Rectangle_2784" data-name="Rectangle 2784" width="3" height="7"
                    transform="translate(17 10)" />
                  <path id="Path_915" data-name="Path 915" d="M12,1,2,6V8H22V6Z" />
                </g>
              </g>
            </svg>
            <span [style.color]="modeScreen.color"> BaaS </span>
          </div>

          <!-- <div class="item" routerLink="access-groups" [ngClass]="{'option_select': url == 'access-groups'}"
            [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
            [ngClass]="{'select': url == 'access-groups'}">
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24"
              [style.fill]="modeScreen.icon">
              <g id="login_black_24dp" transform="translate(0.118 0.285)">
                <g id="Group_436" data-name="Group 436" transform="translate(0.024 -0.285)">
                  <rect id="Rectangle_2772" data-name="Rectangle 2772" width="23" height="24"
                    transform="translate(-0.143 0)" fill="none" />
                </g>
                <g id="Group_437" data-name="Group 437" transform="translate(1.8 3.082)">
                  <path id="Path_914" data-name="Path 914"
                    d="M10.8,6.91,9.428,8.278l2.541,2.541H2v1.955h9.969L9.428,15.315,10.8,16.683,15.683,11.8Zm8.8,11.729H11.774v1.955h7.819a1.961,1.961,0,0,0,1.955-1.955V4.955A1.961,1.961,0,0,0,19.593,3H11.774V4.955h7.819Z"
                    transform="translate(-2 -3)" />
                </g>
              </g>
            </svg>

            <span [style.color]="modeScreen.color"> Grupos de Acesso </span>
          </div> -->

          <div class="item" routerLink="transfer"
            [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
            [ngClass]="{'select': url == 'transfer'}">
            <mat-icon> attach_money</mat-icon>
            <span [style.color]="modeScreen.color"> Transferências </span>
          </div>

          <div class="item" routerLink="dashboard-employer"
            [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
            [ngClass]="{'select': url == 'dashboard-employer'}">
            <mat-icon> bar_chart </mat-icon>
            <span [style.color]="modeScreen.color"> Dashboard Employer </span>
          </div>

          <div class="item" routerLink="current-account"
            [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
            [ngClass]="{'select': url == 'current-account'}">
            <svg id="account_balance_wallet_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
              viewBox="0 0 24 24" [style.fill]="modeScreen.icon">
              <path id="Path_833" data-name="Path 833" d="M0,0H24V24H0Z" fill="none" />
              <path id="Path_834" data-name="Path 834"
                d="M21,18v1a2.006,2.006,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H19a2.006,2.006,0,0,1,2,2V6H12a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2Zm-9-2H22V8H12Zm4-2.5A1.5,1.5,0,1,1,17.5,12,1.5,1.5,0,0,1,16,13.5Z" />
            </svg>
            <span [style.color]="modeScreen.color"> Conta corrente </span>
          </div>

          <div class="item" routerLink="logs"
            [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
            [ngClass]="{'select': url == 'logs'}">
            <mat-icon> import_export </mat-icon>
            <span [style.color]="modeScreen.color"> Logs </span>
          </div>
        </div>

        <div class="card_bottom">

          <div class="item" (click)="logout()">
            <svg id="logout_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
              [style.fill]="modeScreen.icon">
              <g id="Group_232" data-name="Group 232">
                <path id="Path_781" data-name="Path 781" d="M0,0H24V24H0Z" fill="none" />
              </g>
              <g id="Group_233" data-name="Group 233">
                <path id="Path_782" data-name="Path 782"
                  d="M17,8,15.59,9.41,17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4ZM5,5h7V3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2h7V19H5Z" />
              </g>
            </svg>
            <span [style.color]="modeScreen.color"> Logout </span>
          </div>
        </div>
      </div>
    </mat-drawer>

    <router-outlet *ngIf="!loading"></router-outlet>

    <div *ngIf="loading" class="loading-container"
      [ngClass]="{'drawerDark': modeScreen.theme == 'dark', 'drawerLight': modeScreen.theme == 'light' }"></div>
  </mat-drawer-container>
</div>

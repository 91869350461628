import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { Tariff, TariffCompany } from 'src/app/models/tariff';
import { TariffService } from 'src/app/services/tariff.service';
import { ValidatorUtils } from 'src/app/utils/validator.utils';

@Component({
  selector: 'app-datail-tariff',
  templateUrl: './datail-tariff.component.html',
  styleUrls: ['./datail-tariff.component.scss'],
})
export class DatailTariffComponent implements OnInit {
  constructor(
    public modalRef: MDBModalRef,
    private tariffService: TariffService
  ) {}

  action: Subject<any> = new Subject();

  modeScreen: any;
  type: any = null;

  tariff_company: TariffCompany = {} as TariffCompany;
  company: any;

  loading: boolean = false;
  warningMap = new Map();
  validator = new ValidatorUtils();
  checked = false;
  date: string = '';

  list_type: string[] = ['MENSAL', 'SERVICE'];
  phase: number = 1;

  list_tariffs: any = [];
  select_tariff = false;

  @ViewChild('inputValue') inputValue!: ElementRef;

  ngOnInit() {

    console.log(this.tariff_company);
    

    if (this.tariff_company.start_date) {
      this.tariff_company.start_date =
        this.tariff_company.start_date + 'T00:00:01';
    }

    if (this.tariff_company.end_date) {
      this.tariff_company.end_date = this.tariff_company.end_date + 'T00:00:01';
    }

    if (this.tariff_company.start_date && this.tariff_company.end_date) {
      this.checked = false;
    } else {
      this.checked = true;
    }

    if (!this.tariff_company) this.tariff_company = {} as TariffCompany;
    else {
      this.tariff_company.tariff_type = this.tariff_company.tariff_type?.id;
    }

    this.getTariffs('');
  }

  getTariffs(search: any) {
    this.tariffService.getTariffs(100, 1, search).subscribe((data) => {
      this.list_tariffs = data.results;
    });
  }

  close(action: 'yes' | 'no') {
    this.action.next(action);
    this.modalRef.hide();
  }

  checkInput() {
    this.warningMap = new Map();

    if (!this.tariff_company.tariff_type)
      this.warningMap.set('tariff_type', true);
    if (this.tariff_company.value_discount === null)
      this.warningMap.set('value_discount', true);

    if (this.warningMap.size == 0) {
      this.loading = true;
      if (this.type == 'new') {
        this.postTariffInCompany();
      } else if (this.type == 'edit') {
        this.updateTariffInCompany();
      }
    }
  }

  placeCursorAtTheEnd(): void {
    this.inputValue.nativeElement.focus();
    this.inputValue.nativeElement.setSelectionRange(
      this.inputValue.nativeElement.value.length,
      this.inputValue.nativeElement.value.length
    );
  }

  postTariffInCompany() {
    if (this.checked) {
      this.tariff_company.start_date = this.date + '-01T00:00:01';
      this.tariff_company.end_date = '';
    }

    this.tariffService
      .postTariffInCompany(this.company.id, this.tariff_company)
      .subscribe(
        (data) => {
          this.action.next({ action: 'yes', tariff: this.tariff_company });
          this.modalRef.hide();
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  updateTariffInCompany() {
    if (this.checked) {
      this.tariff_company.start_date = this.date + '-01T00:00:01';
      this.tariff_company.end_date = '';
    }

    this.tariffService.updateTariffInCompany(this.tariff_company).subscribe(
      (data) => {
        this.action.next({ action: 'yes', tariff: this.tariff_company });
        this.modalRef.hide();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  checkedDate() {
    this.tariff_company.start_date = '';
    this.tariff_company.end_date = '';
  }

  setMonthAndYear(event: any, datepicker: any) {
    datepicker.close();
  }

  selectTariff(tariff: Tariff) {
    this.select_tariff = true;

    this.tariff_company.value = tariff.value;
    this.tariff_company.value_with_discount = tariff.value;

    if (this.type === 'edit') {
      this.tariff_company.value_discount = this.tariff_company.discount;
      this.calculatePercent()
    }

    if (this.type === 'new') {
      this.tariff_company.value_discount = 0;
      this.tariff_company.discount = 0;
    }
  }

  calculatePercent() {
    this.tariff_company.value_with_discount = parseFloat(
      (this.tariff_company.value - this.tariff_company.value_discount).toFixed(
        2
      )
    );

    setTimeout(() => {
      if (this.tariff_company.value_discount > this.tariff_company.value) {
        this.tariff_company.value_discount = this.tariff_company.value;
      }
    }, 100);

    if (this.tariff_company.value_with_discount < 0) {
      this.tariff_company.value_with_discount = 0;
    }

    this.tariff_company.discount =
      (this.tariff_company.value_discount * 100) / this.tariff_company.value;

    if (this.tariff_company.discount > 100) {
      this.tariff_company.discount = 100;
    }    
  }
}

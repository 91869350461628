import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalDeleteComponent } from '../../modals/modal-delete/modal-delete.component';

import { User } from '../../models/user';
import { AuthService } from '../../services/auth.service';
import { StorageService } from '../../services/storage.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {

  constructor(
    private storage: StorageService,
    private modalService: MDBModalService,
    private userService: UserService,
    private auth: AuthService
  ) { }

  modeScreen: any;
  loading: boolean = true;
  modalRef: any = MDBModalRef;
  modalEdit: any = MDBModalRef;

  user: User = {} as User;
  text_input: string = '';
  name_user: string = '';

  page_atual: number = 1;
  count_page: number = 1;
  page_size = 10;

  displayedColumns: string[] = ['name', 'email', 'company', 'profile', 'status', 'buttons'];
  dataSource = new MatTableDataSource<any>([]);

  input_search: any = '';
  list_users: any = [];
  user_response: any;

  ngOnInit(): void {
    this.getMe()
    this.getUsersAll(1);

    this.modeScreen = this.storage.checkTheme();
    this.name_user = this.storage.getUser().name

    this.storage.watchTheme().subscribe(data => {
      this.modeScreen = this.storage.checkTheme();
    })

    this.storage.watchUser().subscribe(user => {
      this.name_user = this.storage.getUser().name
    })
  }

  getMe() {
    this.loading = true
    this.auth.getMe().subscribe(data => {
      this.user = data
      this.loading = false
    }, error => {
      this.loading = false;
    })
  }

  getUsersAll(page: number) {
    this.loading = true;
    this.userService.getUsersAll(page, this.input_search).subscribe(data => {

        this.user_response = data;
        this.list_users = data.results;
        this.dataSource = new MatTableDataSource(this.list_users);

        this.page_atual = page
        this.count_page = Math.ceil(data.count / this.page_size)

        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  backPage() {
    if (this.user_response.previous) {
      this.getUsersAll(this.page_atual - 1)
    }
  }

  nextPage() {
    if (this.user_response.next) {
      this.getUsersAll(this.page_atual + 1)
    }
  }

  deleteUser(obj: any) {
    this.userService.deleteUser(obj.id).subscribe((data) => {
      this.getUsersAll(this.page_atual);
    });
  }

  deleteUserPoup(obj: any) {
    this.modalRef = this.modalService.show(ModalDeleteComponent, {
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal',
      containerClass: '',
      animated: false,
      data: {
        title: 'Deletar Usuário',
        text: 'Deseja deletar usuário',
        modeScreen: this.modeScreen,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == 'yes') {
        this.deleteUser(obj);
      }
    });
  }
}

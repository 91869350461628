import { Component } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-modal-select-download',
  templateUrl: './modal-select-download.component.html',
  styleUrls: ['./modal-select-download.component.scss']
})
export class ModalSelectDownloadComponent {

  constructor(
    public modalRef: MDBModalRef,
    public storage: StorageService) { }

  action: Subject<any> = new Subject();
  modeScreen = this.storage.checkTheme();

  closePopup() {
    this.modalRef.hide();
  }

  close(choice: 'csv' | 'ofx' | 'pdf' | 'cnab240') {
    this.action.next(choice);
    this.modalRef.hide();
  }
}

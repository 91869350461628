<main [ngClass]="{'darkMode': modeScreen.theme == 'dark', 'lightMode': modeScreen.theme == 'light' }">
  <span class="text" [style.color]="modeScreen.color">Olá, {{ name_user && name_user }} </span>
  <p class="sub_text" [style.color]="modeScreen.color" [style.color]="modeScreen.color">Admin</p>

  <div class="line_top">
    <div class="card_title">
      <h2 class="font25" [style.color]="modeScreen.color">BaaS</h2>
      <button mat-button routerLink="/baas/new-baas"
        [ngClass]="{'btn_white': modeScreen.theme == 'dark', 'btn_brown': modeScreen.theme == 'light' }"> Adicionar
      </button>
    </div>

    <div class="card_search">
      <input class="input" matInput [style.color]="modeScreen.color"
        [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
        [(ngModel)]="text_input" type="text" placeholder="Pesquisa" (keyup.enter)="getBanks()" autocomplete="off">
      <svg (click)="getBanks()" class="icon" id="Group_364" data-name="Group 364" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
        viewBox="0 0 24 24" [style.fill]="modeScreen.icon">
        <path id="Icon_material-search" data-name="Icon material-search"
          d="M17.806,16.209h-.841l-.3-.287a6.929,6.929,0,1,0-.745.745l.287.3v.841l5.322,5.312,1.586-1.586Zm-6.387,0a4.79,4.79,0,1,1,4.79-4.79A4.784,4.784,0,0,1,11.419,16.209Z"
          transform="translate(-1.5 -1.5)" />
        <rect id="Rectangle_2285" data-name="Rectangle 2285" width="24" height="24" fill="none" />
      </svg>
    </div>
  </div>

  <div class="card_table" *ngIf="!loading">
    <table aria-describedby="table" mat-table [dataSource]="dataSource"
      [ngClass]="{'darkMode2': modeScreen.theme == 'dark', 'lightMode2': modeScreen.theme == 'light' }">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Nome
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.name != null ? element.name : '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Número
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.number != null ? element.number : '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Empresa
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.company != null ? element.company : '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="buttons" class="size_auto">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          <div class="buttons">
            <div class="button" routerLink="/baas/{{element.id}}" matTooltip="Editar" matTooltipPosition="above">
              <svg id="edit_black" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                [style.fill]="modeScreen.icon">
                <path id="Path_897" data-name="Path 897" d="M0,0H20V20H0Z" fill="none" />
                <path id="Path_898" data-name="Path 898"
                  d="M3,14.868v3.123H6.123L15.335,8.78,12.211,5.656Zm14.75-8.5a.829.829,0,0,0,0-1.174L15.8,3.241a.829.829,0,0,0-1.174,0L13.1,4.765l3.123,3.123L17.75,6.364Z"
                  transform="translate(-0.496 -0.495)" />
              </svg>
            </div>

            <div class="button" (click)="deleteBankPopup(element.id)" matTooltip="Deletar" matTooltipPosition="above">
              <svg id="delete_black" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                [style.fill]="modeScreen.icon">
                <path id="Path_895" data-name="Path 895" d="M0,0H20V20H0Z" fill="none" />
                <path id="Path_896" data-name="Path 896"
                  d="M5.832,16.326A1.67,1.67,0,0,0,7.5,17.991h6.658a1.67,1.67,0,0,0,1.665-1.666V6.331H5.832ZM16.652,3.833H13.739L12.907,3H8.745l-.832.833H5V5.5H16.652Z"
                  transform="translate(-0.826 -0.496)" />
              </svg>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="11" style="text-align: center" [style.color]="modeScreen.color">
          Nenhum resultado encontrado.
        </td>
      </tr>
    </table>
  </div>

  <div class="table_paginator" *ngIf="!loading">
    <div class="paginator_button" matmatTooltip="Página anterior">
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
        <g id="Group_428" data-name="Group 428" transform="translate(-1688 -1004)">
          <g id="Rectangle_2304" data-name="Rectangle 2304" transform="translate(1688 1004)"
            [style.fill]="modeScreen.fill" [style.stroke]="modeScreen.stroke" stroke-width="1">
            <rect width="27" height="27" rx="5" stroke="none" />
            <rect x="0.5" y="0.5" width="26" height="26" rx="4.5" fill="none" />
          </g>
          <g id="expand" transform="translate(1712.5 1006.719) rotate(90)">
            <path id="Path_7" data-name="Path 7" d="M21.563,21.563H0V0H21.563Z" fill="none" opacity="0.87" />
            <path id="Path_8" data-name="Path 8" d="M9.515,0,5.391,4.115,1.267,0,0,1.267,5.391,6.658l5.391-5.391Z"
              transform="translate(5.391 7.718)" [style.fill]="modeScreen.arrow" />
          </g>
        </g>
      </svg>
    </div>

    <span class="text_paginator" [style.color]="modeScreen.color">
      {{page_atual}} de {{count_page}}
    </span>

    <div class="paginator_button" matmatTooltip="Próxima página">
      <svg id="Group_347" data-name="Group 347" xmlns="http://www.w3.org/2000/svg" width="27" height="27"
        viewBox="0 0 27 27">
        <g id="Rectangle_2305" data-name="Rectangle 2305" [style.fill]="modeScreen.fill"
          [style.stroke]="modeScreen.stroke" stroke-width="1">
          <rect width="27" height="27" rx="5" stroke="none" />
          <rect x="0.5" y="0.5" width="26" height="26" rx="4.5" fill="none" />
        </g>
        <g id="expand" transform="translate(2.719 24.281) rotate(-90)">
          <path id="Path_7" data-name="Path 7" d="M21.563,21.563H0V0H21.563Z" fill="none" opacity="0.87" />
          <path id="Path_8" data-name="Path 8" d="M9.515,0,5.391,4.115,1.267,0,0,1.267,5.391,6.658l5.391-5.391Z"
            transform="translate(5.391 7.718)" [style.fill]="modeScreen.arrow" />
        </g>
      </svg>
    </div>
  </div>
</main>

<main [ngClass]="{'darkMode': modeScreen.theme == 'dark', 'lightMode': modeScreen.theme == 'light' }">
   <span class="text" [style.color]="modeScreen.color">Olá, {{ user.name ? user.name : '' }} </span>
  <p class="sub_text" [style.color]="modeScreen.color" [style.color]="modeScreen.color">Admin</p>

  <div class="card_row">
    <h1 [style.color]="modeScreen.color"> <span class="inco_back" routerLink="/baas"> &lt; </span>{{page === "new" ? "Criar" : (isEdit ? "Editar" : "Editar")}} Banco</h1>

    <div *ngIf="page === 'new'" class="card_buttons">
      <button mat-button (click)="checkInput()"
        [ngClass]="{'btn_white': modeScreen.theme == 'dark', 'btn_brown': modeScreen.theme == 'light' }"> Salvar
        <span *ngIf="loading_button" style="margin-left: 5px;" [style.color]="modeScreen.color2"
          class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </button>
    </div>
  </div>

  <div class="card_loading" *ngIf="loading">
    <span style="margin-left: 5px;" [style.color]="modeScreen.color" class="spinner-border spinner-border-sm"
      role="status" aria-hidden="true"></span>
  </div>

  <section class="card_list" *ngIf="!loading">
    <div class="card_input_title">
      <span> Nome </span>
      <input [(ngModel)]="bank.name" matInput type="text" placeholder="Nome"
        [ngStyle]="{'border-color': warningMap.get('name')? 'red' : ''}" class="input"
        [style.color]="modeScreen.color"
        [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }">
    </div>
    <div class="card_input_title">
      <span> Número </span>
      <input [(ngModel)]="bank.number" matInput type="text" placeholder="Número"
        [ngStyle]="{'border-color': warningMap.get('number')? 'red' : ''}" class="input">
    </div>
    <div class="card_input_title">
      <span> Empresa </span>
      <input [(ngModel)]="bank.company" matInput type="number" placeholder="Empresa"
        [ngStyle]="{'border-color': warningMap.get('company')? 'red' : ''}" class="input">
    </div>
  </section>

  <!-- <h2>Api Info</h2>

  <section class="card_list" *ngIf="!loading">
    <div class="card_input_title">
      <span> Host </span>
      <input [(ngModel)]="bank.host" matInput type="text" placeholder="Host"
        [ngStyle]="{'border-color': warningMap.get('host')? 'red' : ''}" [readonly]="!isEdit">
    </div>
    <div class="card_input_title">
      <span> Client Id </span>
      <input [(ngModel)]="bank.client_id" matInput type="text" placeholder="Client Id"
        [ngStyle]="{'border-color': warningMap.get('client_id')? 'red' : ''}" [readonly]="!isEdit">
    </div>
    <div class="card_input_title">
      <span> Client Secret </span>
      <input [(ngModel)]="bank.client_secret" matInput type="text" placeholder="Client Secret"
        [ngStyle]="{'border-color': warningMap.get('client_secret')? 'red' : ''}" [readonly]="!isEdit">
    </div>
  </section> -->

  <section class="card_buttons" *ngIf="page === 'edit'">
    <button mat-button [disabled]="loading" (click)="isEdit ? checkInput() : editBank()"
      [ngClass]="{'btn_white': modeScreen.theme == 'dark', 'btn_brown': modeScreen.theme == 'light' }"> {{isEdit ? "Salvar" : "Editar"}}
      <span *ngIf="loading" style="margin-left: 5px;" [style.color]="modeScreen.color2"
          class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
    <button mat-button [disabled]="loading" (click)="deleteBankPopup(bank.id)"
      [ngClass]="{'btn_white': modeScreen.theme == 'dark', 'btn_brown': modeScreen.theme == 'light' }"> Excluir
      <span *ngIf="loading" style="margin-left: 5px;" [style.color]="modeScreen.color2"
          class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
  </section>
</main>

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalChangePasswordComponent } from 'src/app/modals/modal-change-password/modal-change-password.component';
import { ModalDeleteComponent } from 'src/app/modals/modal-delete/modal-delete.component';
import { Company } from 'src/app/models/company';
import { Permissions } from 'src/app/models/permission';
import { User } from 'src/app/models/user';
import { CompanyService } from 'src/app/services/company.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
@Component({
  selector: 'app-details-user',
  templateUrl: './details-user.component.html',
  styleUrls: ['./details-user.component.scss']
})
export class DetailsUserComponent implements OnInit {

  constructor(
    private storage: StorageService,
    private userService: UserService,
    private companyService: CompanyService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: MDBModalService,
    private _snackBar: MatSnackBar,
  ) {
    this.user.id = this.route.snapshot.params['id'];
  }

  isEdit: boolean = false;
  modalRef: any = MDBModalRef;

  warningMap = new Map();
  validator = new ValidatorUtils()
  loading: boolean = false;
  loading_save: boolean = false;

  user: User = {} as User;
  company_select: any
  permission_select: any
  modeScreen: any;

  name_user: string = ''
  list_category: Company[] = [];
  list_filter: Company[] = [];
  list_permissions: Permissions[] = [];

  ngOnInit(): void {
    this.getCompany('')
    this.getPermissions()

    this.modeScreen = this.storage.checkTheme();
    this.name_user = this.storage.getUser().name

    this.storage.watchTheme().subscribe(data => {
      this.modeScreen = this.storage.checkTheme();
    })

    this.storage.watchUser().subscribe(user => {
      this.name_user = this.storage.getUser().name
    })

    if (!this.validator.isEmptyOrNull(this.user.id) && this.user.id != 'new-user') {
      this.isEdit = true;
      this.getUserId()
    }
    else {
      this.isEdit = false;
    }
  }

  getUserId() {
    this.loading = true;
    this.userService.getUserId(this.user.id).subscribe(data => {
      this.user = data;

      if (data?.company?.name != null) {
        this.user.input_company = data?.company?.name;
        this.company_select = data?.company?.id;
      }

      this.user.input_permission = data?.type_user?.name;
      this.user.type_user = data?.type_user?.id;
      this.loading = false;
    },
      error => {
        this.loading = false;
      })
  }

  getPermissions() {
    this.userService.getPermissions(1, this.user.input_permission).subscribe(data => {
      this.list_permissions = data.results
    })
  }

  selectPermission(permission: Permissions) {
    this.user.type_user = permission.id
  }

  checkInput() {
    this.warningMap = new Map();

    if (this.user.name == "" || this.user.name == null) {
      this.warningMap.set('name', true);
    }
    if (!this.validator.isValidEmail(this.user.email)) {
      this.warningMap.set('email', true);
    }
    if (this.user.email == "" || this.user.email == null) {
      this.warningMap.set('email', true);
    }
    if (this.user.input_company == "" || this.user.input_company == null || this.company_select == "" || this.company_select == null) {
      this.warningMap.set('company', true);
    }
    if (!this.isEdit) {
      if (this.user.password == "" || this.user.password == null) {
        this.warningMap.set('password', true);
      }
      if (this.user.confirm_password == "" || this.user.confirm_password == null) {
        this.warningMap.set('confirm_password', true);
      }
      if (this.user.password != this.user?.confirm_password) {
        this.warningMap.set('password', true);
        this.warningMap.set('confirm_password', true);
      }
    }

    if (this.warningMap.size == 0 && !this.loading) {
      this.loading = true;

      if (this.user.id == null || this.user.id == 'new-user') {
        this.postUser()
      }
      else {
        this.editUser()
      }
    }
  }

  checkInputMessage(value: string, type: string, value_2?: string) {
    return this.validator.checkInputMessage(value, type, value_2)
  }

  editUser() {
    this.loading_save = true
    this.userService.editUser(this.user, this.company_select).subscribe(data => {
      this.loading_save = false;
      this.router.navigate(['/users']);

    }, error => {
      this.openSnackBar(error.error.detail, 'Fechar')
      this.loading_save = false;
      this.loading = false;
    })
  }

  postUser() {
    this.loading_save = true
    this.userService.postUser(this.user, this.company_select).subscribe(data => {
      this.loading_save = false;
      this.router.navigate(['/users']);
    }, error => {
      this.openSnackBar(error.error.detail, 'Fechar')
      this.loading_save = false;
      this.loading = false;
    })
  }

  getCompany(search: string) {
    this.companyService.getCompany(1, search).subscribe(data => {
      this.list_filter = data.results;
    })
  }

  openScreen(obj: any) {
    this.company_select = obj.id;
  }

  removeMfa() {
    this.modalRef = this.modalService.show(ModalDeleteComponent, {
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal",
      containerClass: "",
      animated: false,
      data: {
        title: "Deletar MFA",
        text: "Deseja remover o MFA desse usuário?",
        modeScreen: this.modeScreen,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "yes") {
        this.userService.deleteMfaUser(this.user.id).subscribe({
          next: () => {
            this.openSnackBar("MFA removido com sucesso", "Fechar")
            this.getUserId()
          },
          error: () => {
            this.openSnackBar("Não foi possível remover o MFA do usuário. Tente novamente", "Fechar")
          }
        });
      }
    });
  }

  changePassword() {
    this.modalRef = this.modalService.show(ModalChangePasswordComponent, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      show: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered modal',
      containerClass: '',
      animated: false,
      data: {
        title: 'Redefinir senha',
        user: this.user,
        modeScreen: this.modeScreen,
      },
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { CompanyService } from '../../services/company.service';
import { MatTableDataSource } from '@angular/material/table';
import { Company } from '../../models/company';
@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  constructor(
    private storage: StorageService,
    private companyService: CompanyService

  ) { }

  modeScreen = this.storage.checkTheme();

  loading: boolean = false
  name_user: string = ''
  text_input: string = ''

  data_response: any;
  page_atual: number = 1
  count_page: number = 1
  list_company: any = []
  user_response: any;

  displayedColumns: string[] = ["name", "cnpj", "buttons"];
  dataSource = new MatTableDataSource<Company>([]);

  ngOnInit(): void {
    this.modeScreen = this.storage.checkTheme();
    this.name_user = this.storage.getUser().name

    this.storage.watchTheme().subscribe(data => {
      this.modeScreen = this.storage.checkTheme();
    })

    this.storage.watchUser().subscribe(user => {
      this.name_user = this.storage.getUser().name
    })

    this.getCompany(this.page_atual)
  }

  getCompany(page: number) {
    this.loading = true
    this.companyService.getCompany(page, this.text_input).subscribe(data => {
      this.data_response = data
      this.dataSource = new MatTableDataSource(data.results)
      this.page_atual = page
      this.count_page = Math.ceil(data.count / 10)
      this.loading = false;

    }, error => {
      this.loading = false;
    })
  }

  backPage() {
    if (this.data_response.previous) {
      this.getCompany(this.page_atual - 1)
    }
  }

  nextPage() {
    if (this.data_response.next) {
      this.getCompany(this.page_atual + 1)
    }
  }
}

<main [ngClass]="{'darkMode': modeScreen.theme == 'dark', 'lightMode': modeScreen.theme == 'light' }">
  <span class="text" [style.color]="modeScreen.color">Olá, {{ user.name && user.name }} </span>
  <p class="sub_text" [style.color]="modeScreen.color" [style.color]="modeScreen.color">Admin</p>

  <div class="line_top">
    <div class="card_title">
      <h2 class="font25" [style.color]="modeScreen.color">Transferências</h2>
    </div>

    <button mat-fab class="btn_refresh" aria-label="Botão para atualizar" (click)="refresh()">
      <svg *ngIf="!loading_refresh" height="30" width="30" viewBox="0 0 48 48">
        <path [style.fill]="modeScreen.color2"
          d="M23.85 41.25q-7.15 0-12.2-5.05Q6.6 31.15 6.6 24q0-7.15 5.05-12.225Q16.7 6.7 23.85 6.7q4.55 0 8.025 1.825 3.475 1.825 5.975 5.125V6.7h3.6v14.55h-14.6V17.7h8.2q-1.95-2.8-4.7-4.525-2.75-1.725-6.5-1.725-5.25 0-8.9 3.65-3.65 3.65-3.65 8.9t3.65 8.9q3.65 3.65 8.9 3.65 3.9 0 7.225-2.25 3.325-2.25 4.725-5.95h4.85q-1.5 5.75-6.175 9.325Q29.8 41.25 23.85 41.25Z" />
      </svg>

      <span *ngIf="loading_refresh" class="spinner-border spinner-border-gg" role="status" aria-hidden="true">
      </span>
    </button>
  </div>

  <div class="line_top">
    <div class="card_filter">

      <div class="card_select">
        <mat-form-field style="margin: 0;">
          <mat-label style="color: black;"> Cliente <span *ngIf="loading_client"
              class="spinner-border spinner-border-xsm" role="status" aria-hidden="true"></span> </mat-label>
          <mat-select [(ngModel)]="client">
            <mat-option *ngFor="let item of list_clients" [value]="item.id" (click)="getClientId(item)">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="card_select">
        <mat-form-field style="margin: 0;">
          <mat-label style="color: black;"> Banco <span *ngIf="loading_account"
              class="spinner-border spinner-border-xsm" role="status" aria-hidden="true"></span></mat-label>
          <mat-select [(ngModel)]="account">
            <mat-option *ngFor="let item of list_accounts" [value]="item.id" (click)="tableStatus(1, 'ALL', page_size)">
              {{ item.name }} - <span class="uuid"> {{ item.uuid }} </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="card_date">
        <mat-form-field appearance="fill" (click)="picker.open()">
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate [(ngModel)]="date_from" [style.color]="'black'" readonly>
            <input matEndDate (dateInput)="getTransfer(1, type_status, page_size)" [(ngModel)]="date_to"
              [style.color]="'black'" readonly>
          </mat-date-range-input>

          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <div class="card_search">
        <input class="input" matInput [style.color]="modeScreen.color"
          [ngClass]="{'darkmode': modeScreen.theme == 'dark', 'lightmode': modeScreen.theme == 'light' }"
          [(ngModel)]="text_input" type="text" placeholder="Pesquisa" autocomplete="off"
          (keyup)="getTransfer(1, transfer.status, page_size)">

        <svg class="icon" id="Group_364" data-name="Group 364" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
          viewBox="0 0 24 24" [style.fill]="modeScreen.icon">
          <path id="Icon_material-search" data-name="Icon material-search"
            d="M17.806,16.209h-.841l-.3-.287a6.929,6.929,0,1,0-.745.745l.287.3v.841l5.322,5.312,1.586-1.586Zm-6.387,0a4.79,4.79,0,1,1,4.79-4.79A4.784,4.784,0,0,1,11.419,16.209Z"
            transform="translate(-1.5 -1.5)" />
          <rect id="Rectangle_2285" data-name="Rectangle 2285" width="24" height="24" fill="none" />
        </svg>

        <!-- <div class="option">
          <h3 [style.color]="modeScreen.color" (click)="picker.open()">Data:</h3>

          <mat-form-field class="card_data" appearance="fill" (click)="picker.open()">
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate [(ngModel)]="date_from" [style.color]="modeScreen.color" readonly>
              <input matEndDate (dateInput)="getTransfer(1, type_status, page_size)" [(ngModel)]="date_to" [style.color]="modeScreen.color"
                readonly>
            </mat-date-range-input>

            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div> -->
      </div>
    </div>

    <div class="card_title">
      <button [ngClass]="{'status_active': type_status == 'ALL'}" matBadge="{{ count.all }}" matBadgePosition="after"
        matBadgeColor="warn" (click)="tableStatus(page_atual, 'ALL', page_size)"> Todos </button>

      <button [ngClass]="{'status_active': type_status == 'NEW'}" matBadge="{{ count.new }}" matBadgePosition="after"
        matBadgeColor="warn" (click)="tableStatus(page_atual, 'NEW', page_size)"> Novos </button>

      <button [ngClass]="{'status_active': type_status == 'APPROVED'}" matBadge="{{ count.approved }}"
        matBadgePosition="after" matBadgeColor="warn" (click)="tableStatus(page_atual, 'APPROVED', page_size)">
        Aprovados
      </button>

      <button [ngClass]="{'status_active': type_status == 'CANCELED'}" matBadge="{{ count.canceled }}"
        matBadgePosition="after" matBadgeColor="warn" (click)="tableStatus(page_atual, 'CANCELED', page_size)">
        Cancelados
      </button>

      <button [ngClass]="{'status_active': type_status == 'WAITING_REPROCESSING'}"
        matBadge="{{ count.waiting_reprocessing }}" matBadgePosition="after" matBadgeColor="warn"
        (click)="tableStatus(page_atual, 'WAITING REPROCESSING', page_size)">
        Aguardando Reprocessamento
      </button>

      <button [ngClass]="{'status_active': type_status == 'PROCESSING'}" matBadge="{{ count.processing }}"
        matBadgePosition="after" matBadgeColor="warn" (click)="tableStatus(page_atual, 'PROCESSING', page_size)"> Em
        processamento
      </button>

      <button [ngClass]="{'status_active': type_status == 'SUCCESS'}" matBadge="{{ count.success }}"
        matBadgePosition="after" matBadgeColor="warn" (click)="tableStatus(page_atual, 'SUCCESS', page_size)"> Sucessos
      </button>

      <button [ngClass]="{'status_active': type_status == 'ERROR'}" matBadge="{{ count.error }}"
        matBadgePosition="after" matBadgeColor="warn" (click)="tableStatus(page_atual, 'ERROR', page_size)"> Erros
      </button>
    </div>
  </div>

  <div *ngIf="loading" class="card_loading">
    <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
  </div>

  <div class="card_table" *ngIf="!loading">
    <table aria-describedby="table" mat-table [dataSource]="dataSourceTransfer"
      [ngClass]="{'darkMode2': modeScreen.theme == 'dark', 'lightMode2': modeScreen.theme == 'light' }">

      <ng-container matColumnDef="checkbox">
        <th mat-header-cell *matHeaderCellDef class="font14">
          <mat-checkbox [checked]="allComplete" (change)="setAll($event.checked)">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.status == 'NEW' || element.status == 'ERROR'">
            <mat-checkbox [(ngModel)]="element.checkbox" (ngModelChange)="updateAllComplete(null)"></mat-checkbox>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Nome do destinatário
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          <span class="break_line"> {{ element.recipient_name != null ? element.recipient_name : '-' }} </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Empresa
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.company != null ? element.company : '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status_receipt">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Status
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.status_receipt ? element.status_receipt : '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status_saq">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Status_saq
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.status_saq ? element.status_saq : '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Valor
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.amount != null ? converters.convertMoney(element.amount) : '0' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Criado em
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.created_at | date: 'dd/MM/yyyy HH:mm' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="updated_at">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
          Atualizado em
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          {{ element.updated_at | date: 'dd/MM/yyyy HH:mm' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="buttons">
        <th mat-header-cell *matHeaderCellDef class="font16" [style.color]="modeScreen.color">
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="modeScreen.color">
          <div class="buttons">
            <mat-icon matTooltip="Visualizar" matTooltipPosition="above" (click)="viewTransfer(element)">
              visibility
            </mat-icon>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="11" style="text-align: center" [style.color]="modeScreen.color">
          <span *ngIf="account != null"> Nenhum resultado encontrado. </span>
          <span *ngIf="account == null"> Selecione um Cliente e um Banco. </span>
        </td>
      </tr>
    </table>
  </div>

  <div class="table_paginator" *ngIf="!loading">
    <div class="card_input w-50f card_qtd_select">
      <span class="font13" [style.color]="modeScreen.color"> Quantidade de itens na página </span>
      <mat-form-field>
        <mat-select [(ngModel)]="page_size">
          <mat-option *ngFor="let item of list_size" [value]="item" (click)="selectsize(item)">
            {{ item }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="paginator-button prev" (click)="backPage()" matmatTooltip="Página anterior">
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
        <g id="Group_428" data-name="Group 428" transform="translate(-1688 -1004)">
          <g id="Rectangle_2304" data-name="Rectangle 2304" transform="translate(1688 1004)"
            [style.fill]="modeScreen.fill" [style.stroke]="modeScreen.stroke" stroke-width="1">
            <rect width="27" height="27" rx="5" stroke="none" />
            <rect x="0.5" y="0.5" width="26" height="26" rx="4.5" fill="none" />
          </g>
          <g id="expand" transform="translate(1712.5 1006.719) rotate(90)">
            <path id="Path_7" data-name="Path 7" d="M21.563,21.563H0V0H21.563Z" fill="none" opacity="0.87" />
            <path id="Path_8" data-name="Path 8" d="M9.515,0,5.391,4.115,1.267,0,0,1.267,5.391,6.658l5.391-5.391Z"
              transform="translate(5.391 7.718)" [style.fill]="modeScreen.arrow" />
          </g>
        </g>
      </svg>
    </div>

    <span class="text_paginator" [style.color]="modeScreen.color">
      {{page_atual}} de {{count_page}}
    </span>

    <div class="paginator-button next" (click)="nextPage()" matmatTooltip="Próxima página">
      <svg id="Group_347" data-name="Group 347" xmlns="http://www.w3.org/2000/svg" width="27" height="27"
        viewBox="0 0 27 27">
        <g id="Rectangle_2305" data-name="Rectangle 2305" [style.fill]="modeScreen.fill"
          [style.stroke]="modeScreen.stroke" stroke-width="1">
          <rect width="27" height="27" rx="5" stroke="none" />
          <rect x="0.5" y="0.5" width="26" height="26" rx="4.5" fill="none" />
        </g>
        <g id="expand" transform="translate(2.719 24.281) rotate(-90)">
          <path id="Path_7" data-name="Path 7" d="M21.563,21.563H0V0H21.563Z" fill="none" opacity="0.87" />
          <path id="Path_8" data-name="Path 8" d="M9.515,0,5.391,4.115,1.267,0,0,1.267,5.391,6.658l5.391-5.391Z"
            transform="translate(5.391 7.718)" [style.fill]="modeScreen.arrow" />
        </g>
      </svg>
    </div>
  </div>

  <p class="itens_select" [style.color]="modeScreen.color"> {{ count_items != null ? count_items : 0 }} {{
    count_items == 1 ? 'item' : 'itens' }} selecionados </p>

  <div class="line_bottom">
    <div class="buttons">
      <button mat-button
        [ngClass]="{'btn_white': modeScreen.theme == 'dark', 'btn_brown': modeScreen.theme == 'light' }"
        (click)="changeStatus('reprocess', null)">
        Reprocessar selecionados
      </button>
    </div>
  </div>
</main>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertDateApiToInput'
})
export class ConvertDateApiToInputPipe implements PipeTransform {

  transform(data: string): string {
    if (data == null || data == '') return data

    let day: any = new Date(data).getDate();
    let month: any = new Date(data).getMonth() + 1;
    let year = new Date(data).getFullYear();

    day = day <= 9 ? `0${day}` : day;
    month = month <= 9 ? `0${month}` : month;

    return `${day}/${month}/${year}`
  }
}

export const colorTheme = [
    {
        theme: 'dark',
        icon: "#FFFFFF",
        fill: '#303030',
        stroke: 'transparent',
        arrow: '#FFFFFF',
        bg: '#303030',
        color: '#FFFFFF',
        color2: '#171717',
        circle: '#FFFFFF'
    },
    {
        theme: 'light',
        icon: "#281e16",
        fill: '#FFFFFF',
        stroke: '#dbdbdb',
        arrow: '#5e6a65',
        bg: '#FFFFFF',
        color: '#171717',
        color2: '#FFFFFF',
        circle: '#171717'
    },
]

export const environment = {
  production: false,
  path: 'https://api-dev.saq.digital/',
  pageSize: 10,
  recaptcha: '6LfWrkUlAAAAAJqIuMKDELa0-BHU13eJvRqwO-Z6',
};


// Comando para gerar build em desenvolvimento:
// ng build --configuration development

import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { StorageService } from '../../services/storage.service';
import { EMPTY } from 'rxjs';
import { expand, map } from 'rxjs/operators';
import { CompressorService } from '../../services/compressor.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { ValidatorUtils } from '../../utils/validator.utils';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {

  constructor(
    private storage: StorageService,
    private userService: UserService,
    private compressor: CompressorService,
    private router: Router,
  ) { }

  loading: boolean = false
  modeScreen: any
  compressed_image: any = [];

  user: User = {} as User
  validator = new ValidatorUtils()
  warningMap = new Map();

  ngOnInit(): void {
    this.modeScreen = this.storage.checkTheme();
    this.user = Object.assign({}, this.storage.getUser())

    this.storage.watchTheme().subscribe(data => {
      this.modeScreen = this.storage.checkTheme();
    })

    this.storage.watchUser().subscribe(user => {
      this.user = this.storage.getUser()
    })
  }

  checkInput() {
    this.warningMap = new Map();

    if (this.user.name == null || this.user.name == '') {
      this.warningMap.set("name", true);
    }
    // if (this.user.cellphone == null || this.user.name == '') {
    //   this.warningMap.set("cellphone", true);
    // }
    // if (this.user.birth_date == null || this.user.birth_date == '') {
    //   this.warningMap.set("birth_date", true);
    // }

    if (this.warningMap.size == 0) {
      this.editProfile()
    }
  }

  editProfile() {
    this.loading = true;
    this.userService.editUser(this.user, this.user.company.id).subscribe((data) => {
      this.storage.changeUser();
      this.loading = false;
    },
      error => {
        this.loading = false;
      }
    );

    this.router.navigate(['/home'])
    this.storage.getUser();

  }

  async onFileChanged(event: any) {
    if (event.target.files && event.target.files[0]) {
      var target = event.target.files;
      const compress = this.recursiveCompress(target[0], 0, target).pipe(
        expand((res) => {
          return res.index > res.array.length - 1
            ? EMPTY
            : this.recursiveCompress(target[res.index], res.index, target);
        })
      );
      compress.subscribe((res) => {
        if (res.index > res.array.length - 1) {
          this.user.profile_image_url = this.compressed_image[0];
          this.compressed_image = [];
        }
      });
    }
  }

  recursiveCompress = (image: File, index: number, array: any) => {
    return this.compressor.compress(image).pipe(
      map((response) => {
        this.compressed_image.push(response);
        return {
          data: response,
          index: index + 1,
          array: array,
        };
      })
    );
  };

}

import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { User } from '../models/user';
import { ValidatorUtils } from '../utils/validator.utils';
import { Md5 } from 'md5-typescript';
import { CustomEncoder } from './custom-encoder';
import { Company } from '../models/company';
import { headersAuthFile } from '../models/headers';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  validator = new ValidatorUtils();

  getCompany(page: number, search: string): Observable<Company> {
    const options = {
      params: new HttpParams()
        .set(`page_size`, environment.pageSize)
        .set(`page`, page)
        .set(`name`, search),

      headers: headersAuthFile(),
    };

    return this.http.get<Company>(
      environment.path + 'core/get-companies-admin/',
      options
    ).pipe(retry(1));
  }

  getCompanyId(id: any): Observable<Company> {
    const options = {
      params: new HttpParams(),
      headers: headersAuthFile(),
    };

    return this.http
      .get<Company>(
        environment.path + `core/get-company-admin-by-id/?id=${id}`,
        options
      )
      .pipe(retry(1));
  }

  getListTariffCompanyId(id: any, date: string): Observable<any> {
    const options = {
      params: new HttpParams()
        .set(`company_id`, id)
        .set(`date`, date)
        .set(`page_size`, environment.pageSize),
      headers: headersAuthFile(),
    };

    return this.http
      .get<any>(environment.path + `core/list-tariff-from-company/`, options)
      .pipe(retry(1));
  }

  editCompany(company: Company): Observable<Company> {
    const body = new HttpParams({ encoder: new CustomEncoder() })
      .set(`name`, company.name)
      .set(`cnpj`, company.cnpj)
      .set(`days_to_pay_pix_qr_code`, company.days_to_pay_pix_qr_code)
      .set(`due_date`, company.due_date)
      .set(`logo`, encodeURI(company.logo))
      .set(`logo_dark`, encodeURI(company.logo_dark));

    return this.http
      .patch<Company>(
        environment.path + `core/update-company-admin/?id=${company.id}`,
        body.toString(),
        { headers: headersAuthFile() }
      )
      .pipe(retry(1));
  }

  getInvoiceByMonth(id: number, date: string) {
    const options = {
      headers: headersAuthFile(),
      params: new HttpParams().set(`company_id`, id).set(`date`, date),
    };

    return this.http
      .get<any>(environment.path + `core/get-invoice-by-month/`, options)
      .pipe(retry(1));
  }
}

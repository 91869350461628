import { Bank } from 'src/app/models/bank';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IClient } from '../models/client';
import { headersAuthFile } from '../models/headers';
import { IFilterExtract } from '../models/filter-extract';
import { IAccountReturn } from '../models/account';
import { CustomEncoder } from './custom-encoder';
import { ILimits } from '../models/limit';
// import { IAccountReturn } from '../../models/account';
// import { IBank } from '../../models/bank';
// import { IClient } from '../../models/client';
// import { IFilterExtract } from '../../models/filter-extract';
// import { headersAuthFile } from '../../models/headers';
// import { ILimits } from '../../models/limit';
// import { CustomEncoder } from '../config/custom-encoder';

@Injectable({
  providedIn: 'root',
})
export class BankService {
  constructor(private http: HttpClient) {}

  getBankInClient(client: IClient): Observable<any> {
    return this.http.get<IClient>(
      environment.path + `finance/list-liked-bank-in-clients/?id=${client.id}`,
      { headers: headersAuthFile() }
    ).pipe(retry(1));
  }

  getBankNotClient(client: IClient): Observable<any> {
    return this.http.get<IClient>(
      environment.path + `finance/list-free-bank-in-clients/?id=${client.id}`,
      { headers: headersAuthFile() }
    ).pipe(retry(1));
  }

  getBankStatement(
    filter_extract: IFilterExtract
  ): Observable<IAccountReturn[]> {
    const options = {
      headers: headersAuthFile(),
      params: new HttpParams().set(`id`, filter_extract.id),
    };

    if (
      filter_extract.filter_type != null &&
      filter_extract.filter_type != ''
    ) {
      options.params = options.params.append(
        `filter_days`,
        filter_extract.filter_days
      );
      options.params = options.params.append(
        `filter_type`,
        filter_extract.filter_type
      );
    }

    if (
      filter_extract.filter_start_date != null &&
      filter_extract.filter_start_date != '' &&
      filter_extract.filter_end_date != null &&
      filter_extract.filter_end_date != ''
    ) {
      options.params = options.params.append(
        `filter_start_date`,
        filter_extract.filter_start_date
      );
      options.params = options.params.append(
        `filter_end_date`,
        filter_extract.filter_end_date
      );
    }

    return this.http.get<any>(
      environment.path + `finance/list-bank-statement/`,
      options
    ).pipe(retry(1));
  }

  postBankClient(client: IClient, bank: Bank): Observable<any> {
    let body = new HttpParams({ encoder: new CustomEncoder() })
      .set(`bank`, bank.id)
      .set(`client_finance`, client.id);

    return this.http.post<any>(
      environment.path + `finance/create-bank-client-finance/`,
      body,
      { headers: headersAuthFile() }
    ).pipe(retry(1));
  }

  getClientNoPagination(): Observable<any> {
    return this.http.get<IClient[]>(
      environment.path + `finance/get-client-no-pagination-super-admin/`,
      { headers: headersAuthFile() }
    ).pipe(retry(1));
  }

  loadAccountAmount(id_bank: any): Observable<any> {
    return this.http.get<IClient>(
      environment.path + `finance/load-account-amount/?id=${id_bank}`,
      { headers: headersAuthFile() }
    ).pipe(retry(1));
  }

  deleteBank(client: IClient, bank: Bank): Observable<any> {
    return this.http.delete<any>(
      environment.path +
        `/finance/delete-bank-client-finance/?id_client=${client.id}&id_bank=${bank.id}`,
      {
        headers: headersAuthFile(),
      }
    ).pipe(retry(1));
  }

  acceptTransaction(id: any): Observable<any> {
    const body = new HttpParams();
    return this.http.post<any>(
      environment.path + `finance/accept-transaction/?id=${id}`,
      body,
      { headers: headersAuthFile() }
    ).pipe(retry(1));
  }

  canceledTransaction(id: any): Observable<any> {
    const body = new HttpParams();
    return this.http.post<any>(
      environment.path + `finance/canceled-transaction/?id=${id}`,
      body,
      { headers: headersAuthFile() }
    ).pipe(retry(1));
  }

  getLimitAccount(bank: Bank): Observable<ILimits> {
    return this.http.get<ILimits>(
      environment.path + `finance/load-limit-account-bank/?id=${bank.id}`,
      { headers: headersAuthFile() }
    ).pipe(retry(1));
  }

  getExtractCSV(filter_extract: IFilterExtract): Observable<any> {
    const options = {
      headers: headersAuthFile(),
      params: new HttpParams().set(`id`, filter_extract.id),
    };

    if (
      filter_extract.filter_type != null &&
      filter_extract.filter_type != ''
    ) {
      options.params = options.params.append(
        `filter_days`,
        filter_extract.filter_days
      );
      options.params = options.params.append(
        `filter_type`,
        filter_extract.filter_type
      );
    }

    if (
      filter_extract.filter_start_date != null &&
      filter_extract.filter_start_date != '' &&
      filter_extract.filter_end_date != null &&
      filter_extract.filter_end_date != ''
    ) {
      options.params = options.params.append(
        `filter_start_date`,
        filter_extract.filter_start_date
      );
      options.params = options.params.append(
        `filter_end_date`,
        filter_extract.filter_end_date
      );
    }

    return this.http.get<any>(
      environment.path + `finance/download-csv-bank-statement/`,
      { ...options, responseType: 'blob' as 'json', observe: 'response' }
    ).pipe(retry(1));
  }

  getExtractOFX(filter_extract: IFilterExtract): Observable<any> {
    const options = {
      headers: headersAuthFile(),
      params: new HttpParams().set(`id`, filter_extract.id),
    };

    if (
      filter_extract.filter_type != null &&
      filter_extract.filter_type != ''
    ) {
      options.params = options.params.append(
        `filter_days`,
        filter_extract.filter_days
      );
      options.params = options.params.append(
        `filter_type`,
        filter_extract.filter_type
      );
    }

    if (
      filter_extract.filter_start_date != null &&
      filter_extract.filter_start_date != '' &&
      filter_extract.filter_end_date != null &&
      filter_extract.filter_end_date != ''
    ) {
      options.params = options.params.append(
        `filter_start_date`,
        filter_extract.filter_start_date
      );
      options.params = options.params.append(
        `filter_end_date`,
        filter_extract.filter_end_date
      );
    }

    return this.http.get<any>(
      environment.path + `finance/download-ofx-bank-statement/`,
      { ...options, responseType: 'blob' as 'json', observe: 'response' }
    ).pipe(retry(1));
  }

  getExtractCNAB240(filter_extract: IFilterExtract): Observable<any> {
    const options = {
      headers: headersAuthFile(),
      params: new HttpParams().set(`id`, filter_extract.id),
    };

    if (
      filter_extract.filter_type != null &&
      filter_extract.filter_type != ''
    ) {
      options.params = options.params.append(
        `filter_days`,
        filter_extract.filter_days
      );
      options.params = options.params.append(
        `filter_type`,
        filter_extract.filter_type
      );
    }

    if (
      filter_extract.filter_start_date != null &&
      filter_extract.filter_start_date != '' &&
      filter_extract.filter_end_date != null &&
      filter_extract.filter_end_date != ''
    ) {
      options.params = options.params.append(
        `filter_start_date`,
        filter_extract.filter_start_date
      );
      options.params = options.params.append(
        `filter_end_date`,
        filter_extract.filter_end_date
      );
    }

    return this.http.get<any>(
      environment.path + `finance/download-ret-bank-statement/`,
      { ...options, responseType: 'blob' as 'json', observe: 'response' }
    ).pipe(retry(1));
  }
}

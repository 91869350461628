<main [ngClass]="{'darkMode': modeScreen.theme == 'dark', 'lightMode': modeScreen.theme == 'light' }">

  <header>
    <div class="card_row">
      <div routerLink="/companies">
        <svg class="icon_back" height="48" width="48">
          <path [style.fill]="modeScreen.color" d="M20 44 0 24 20 4 22.8 6.85 5.65 24 22.8 41.15Z" />
        </svg>
      </div>
      <div class="title" [style.color]="modeScreen.color"> Detalhes da Empresa </div>
    </div>

    <button mat-button (click)="editCompany()"
      [ngClass]="{'btn_white': modeScreen.theme == 'dark', 'btn_brown': modeScreen.theme == 'light' }">
      Salvar
    </button>
  </header>

  <div class="card_loading" *ngIf="loading">
    <span style="margin-left: 5px;" [style.color]="modeScreen.color" class="spinner-border spinner-border-sm"
      role="status" aria-hidden="true"></span>
  </div>

  <section *ngIf="!loading" class="card_info">

    <div class="column_info">
      <div class="row_info">
        <h3 [style.color]="modeScreen.color"> Id : </h3>
        <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
        <p [style.color]="modeScreen.color"> {{ company.id != null ? company.id : '-' }} </p>
      </div>

      <div class="row_info">
        <h3 [style.color]="modeScreen.color"> Data de criação : </h3>
        <div [style.borderColor]="modeScreen.color" class="space_pointers"></div>
        <p [style.color]="modeScreen.color"> {{ company.created_at | date: 'dd/MM/yyyy HH:mm' }} </p>
      </div>
    </div>
  </section>

  <div *ngIf="!loading">
    <section>
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" (opened)="panelOpenBank = true" (closed)="panelOpenBank = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Dados da empresa
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <section class="cards_inputs inputs_dark">

            <div class="card_input" *ngIf="!isEdit"
              style="display: flex; align-items: center; flex-wrap: wrap; gap: 10px;">
              <label class="font14" style="width: 100%;" [style.color]="modeScreen.color">Imagens (light/dark)</label>

              <div class="card_img">
                <img *ngIf="company.logo == null || company.logo == ''" class="circle bg_circle"
                  src="../../assets/images/person_black.svg" alt="Imagem de perfil">

                <img *ngIf="company.logo != null && company.logo != ''" class="circle" [src]=company.logo
                  alt="Imagem de perfil">

                <div (click)="fileImage.click()" class="update_img">
                  <span> <mat-icon>save_alt</mat-icon> </span>
                </div>
                <input type="file" #fileImage style="display: none;" accept="image/*"
                  (change)="onFileChanged($event, 'logo')">
              </div>

              <div class="card_img">
                <img *ngIf="company.logo_dark == null || company.logo_dark == ''" class="circle bg_circle"
                  src="../../assets/images/person_black.svg" alt="Imagem de perfil">

                <img *ngIf="company.logo_dark != null && company.logo_dark != ''" class="circle" [src]=company.logo_dark
                  alt="Imagem de perfil">

                <div (click)="fileImageDark.click()" class="update_img">
                  <span> <mat-icon>save_alt</mat-icon> </span>
                </div>
                <input type="file" #fileImageDark style="display: none;" accept="image/*"
                  (change)="onFileChanged($event, 'logo_dark')">
              </div>
            </div>


            <div class="card_input" *ngIf="!isEdit">
              <label class="font14" [style.color]="modeScreen.color">Nome</label>
              <input type="text" placeholder="Nome" [(ngModel)]="company.name"
                [ngStyle]="{'border-color': warningMap.get('name')? 'red' : ''}">
            </div>

            <div class="card_input" *ngIf="!isEdit">
              <label class="font14" [style.color]="modeScreen.color">CNPJ</label>
              <input type="text" placeholder="" [(ngModel)]="company.cnpj" mask="00.000.000/0000-00"
                [ngStyle]="{'border-color': warningMap.get('cnpj')? 'red' : ''}">
            </div>

            <div class="card_input" *ngIf="!isEdit">
              <label class="font14" [style.color]="modeScreen.color">Dias para pagar pix QRCode</label>
              <input type="text" placeholder="" [(ngModel)]="company.days_to_pay_pix_qr_code"
                [ngStyle]="{'border-color': warningMap.get('days_to_pay_pix_qr_code')? 'red' : ''}">
            </div>

            <div class="card_input" *ngIf="isEdit">
              <label class="font14" [style.color]="modeScreen.color">Data para calculo da fatura</label>
              <input type="text" placeholder="" value="31">
            </div>

            <div class="card_input" *ngIf="isEdit">
              <label class="font14" [style.color]="modeScreen.color">Data de Vencimento</label>
              <input type="text" placeholder="" [(ngModel)]="company.due_date"
                [ngStyle]="{'border-color': warningMap.get('due_date')? 'red' : ''}">
            </div>

            <div class="card_empty"></div>
            <div class="card_empty"></div>

          </section>

        </mat-expansion-panel>
      </mat-accordion>
    </section>

    <div class="card_date">
      <label class="font14" [style.color]="modeScreen.color">Filtrar por mês e ano</label>

      <mat-form-field appearance="fill" (click)="dp.open()">
        <input matInput [matDatepicker]="dp" readonly [(ngModel)]="date" [min]="minDate">
        <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp startView="multi-year" (monthSelected)="setMonthAndYear($event, dp)"
          panelClass="example-month-picker">
        </mat-datepicker>
      </mat-form-field>
    </div>

    <section>
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" (opened)="panelOpenBills = true" (closed)="panelOpenBills = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Faturas
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="card_table">
            <table aria-describedby="table" mat-table [dataSource]="dataSourceInvoices">

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Status
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.status != null && element.status != '' ? element.status : '-' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Valor
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ this.converters.convertMoney(element?.value) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="discount">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Desconto
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.discount | convertPercent }}
                </td>
              </ng-container>

              <ng-container matColumnDef="valueTotal">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Valor Total
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ this.converters.convertMoney(element?.total_value) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="create">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Criado em
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.created_at | date: 'dd/MM/yyyy HH:mm' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef class="font14">
                </th>
                <td mat-cell *matCellDef="let element">
                  <div class="buttons">
                    <mat-icon matTooltip="Visualizar" matTooltipPosition="above"
                      routerLink="/companies/{{id}}/invoice/{{element.id}}">
                      visibility</mat-icon>
                    <mat-icon matTooltip="Deletar" matTooltipPosition="above" (click)="deleteInvoice(element.id)">
                      delete</mat-icon>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="invoicesColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: invoicesColumns"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="11" style="text-align: center">
                  Nenhum resultado encontrado.
                </td>
              </tr>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </section>

    <section *ngIf="(isEdit && status_invoice !== 'FECHADO')">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" (opened)="panelOpenUser = true" (closed)="panelOpenUser = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Tarifas
            </mat-panel-title>
            <mat-panel-description>
              <button mat-button (click)="datailTariff('new')"
                [ngClass]="{'btn_white': modeScreen.theme == 'dark', 'btn_brown': modeScreen.theme == 'light' }">
                Adicionar </button>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="card_table">
            <table aria-describedby="table" mat-table [dataSource]="dataSourceTariff">
              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Tipo da tarifa
                </th>
                <td mat-cell *matCellDef="let element">
                  <span class="break_line" matTooltip="{{element.tariff_type.name}}" matTooltipPosition="right"> {{
                    element.discount != null && element.tariff_type.name != '' ?
                    element.tariff_type.name : '-'
                    }} </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Nome
                </th>
                <td mat-cell *matCellDef="let element">
                  <span class="break_line" matTooltip="{{element.tariff_type.description_tariff}}"
                    matTooltipPosition="right"> {{ element.discount != null && element.tariff_type.description_tariff !=
                    ''
                    ? element.tariff_type.description_tariff : '-'
                    }} </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Valor total
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ this.converters.convertMoney(element.tariff_type.value) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="discount">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Desconto
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.discount | convertMoney }}
                </td>
              </ng-container>

              <ng-container matColumnDef="start_date">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Data Inicial
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.start_date ? (element.start_date | date: 'dd/MM/yyyy') : '-' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="end_date">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Data Final
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.end_date ? (element.end_date | date: 'dd/MM/yyyy') : '-' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="create">
                <th mat-header-cell *matHeaderCellDef class="font14">
                  Criado em
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.created_at | date: 'dd/MM/yyyy' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef class="font14">
                </th>
                <td mat-cell *matCellDef="let element">
                  <div class="buttons">
                    <mat-icon matTooltip="Editar" matTooltipPosition="above"
                      (click)="datailTariff('edit', element)">create</mat-icon>
                    <mat-icon matTooltip="Deletar" matTooltipPosition="above"
                      (click)="deleteTariff(element)">delete</mat-icon>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="tariffColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: tariffColumns"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="11" style="text-align: center">
                  Nenhum resultado encontrado.
                </td>
              </tr>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </section>

  </div>
</main>
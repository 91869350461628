import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AuthGuard } from './guards/auth.guard';
import { AccessGroupsComponent } from './pages/access-groups/access-groups.component';
import { BaasComponent } from './pages/baas/baas.component';
import { DetailsBaasComponent } from './pages/baas/details-baas/details-baas.component';
import { BusinessGroupComponent } from './pages/business-group/business-group.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { DetailCompaniesComponent } from './pages/companies/detail-companies/detail-companies.component';
import { InvoiceDetailComponent } from './pages/companies/invoice-detail/invoice-detail.component';
import { DashboardEmployerComponent } from './pages/dashboard-employer/dashboard-employer.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { TariffsComponent } from './pages/tariffs/tariffs.component';
import { TransferComponent } from './pages/transfer/transfer.component';
import { TypesOfUsersComponent } from './pages/types-of-users/types-of-users.component';
import { DetailsUserComponent } from './pages/users/details-user/details-user.component';
import { UsersComponent } from './pages/users/users.component';
import { CurrentAccountComponent } from './pages/current-account/current-account.component';
import { LogsComponent } from './pages/logs/logs.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: '', component: NavbarComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'companies', component: CompaniesComponent },
      { path: 'companies/:id', component: DetailCompaniesComponent },
      { path: 'companies-financial/:id', component: DetailCompaniesComponent },
      { path: 'companies/:id/invoice/:invoice_id', component: InvoiceDetailComponent },
      { path: 'tariffs', component: TariffsComponent },
      { path: 'type-of-users', component: TypesOfUsersComponent },
      { path: 'users', component: UsersComponent },
      { path: 'users/:id', component: DetailsUserComponent },
      { path: 'users/new-user', component: DetailsUserComponent },
      { path: 'business-group', component: BusinessGroupComponent },
      { path: 'baas', component: BaasComponent },
      { path: 'baas/:id', component: DetailsBaasComponent },
      { path: 'baas/new-baas', component: DetailsBaasComponent },
      { path: 'access-groups', component: AccessGroupsComponent },
      { path: 'transfer', component: TransferComponent },
      { path: 'dashboard-employer', component: DashboardEmployerComponent },
      { path: 'current-account', component: CurrentAccountComponent },
      { path: 'logs', component: LogsComponent },
    ]
  },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<main [ngClass]="{'darkMode': modeScreen.theme == 'dark', 'lightMode': modeScreen.theme == 'light' }">
  <span class="text" [style.color]="modeScreen.color">Olá, {{ name_user && name_user }} </span>
  <p class="sub_text" [style.color]="modeScreen.color" [style.color]="modeScreen.color">Admin</p>

  <div class="card_row">
    <h1 [style.color]="modeScreen.color"> <span class="inco_back" routerLink="/users"> &lt; </span>{{ isEdit == true ?
      'Editar Usuário' : 'Criar Usuário' }} </h1>

    <div class="card_buttons">
      <button mat-button (click)="removeMfa()" *ngIf="isEdit && user.mfa_enable"
        [ngClass]="{'btn_white': modeScreen.theme == 'dark', 'btn_brown': modeScreen.theme == 'light' }">
        Remover MFA
      </button>

      <button mat-button (click)="changePassword()" *ngIf="isEdit"
        [ngClass]="{'btn_white': modeScreen.theme == 'dark', 'btn_brown': modeScreen.theme == 'light' }">
        Redefinir Senha
      </button>

      <button mat-button (click)="checkInput()"
        [ngClass]="{'btn_white': modeScreen.theme == 'dark', 'btn_brown': modeScreen.theme == 'light' }"> Salvar
        <span *ngIf="loading_save" style="margin-left: 5px;" [style.color]="modeScreen.color2"
          class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </button>
    </div>
  </div>

  <div class="card_loading" *ngIf="loading">
    <span style="margin-left: 5px;" [style.color]="modeScreen.color" class="spinner-border spinner-border-sm"
      role="status" aria-hidden="true"></span>
  </div>

  <section class="card_list" *ngIf="!loading">
    <div class="card_input_title">
      <span> Nome <span class="msg_erro" *ngIf="warningMap.get('name')"> {{ checkInputMessage(user.name,
          'name')
          }} </span> </span>
      <input matInput type="name" [(ngModel)]="user.name" placeholder="Nome"
        [ngStyle]="{'border-color': warningMap.get('name')? 'red' : ''}">
    </div>

    <div class="card_input_title">
      <span> E-mail <span class="msg_erro" *ngIf="warningMap.get('email')"> {{ checkInputMessage(user.email,
          'email') }} </span> </span>
      <input matInput type="email" [(ngModel)]="user.email" placeholder="E-mail"
        [ngStyle]="{'border-color': warningMap.get('email')? 'red' : ''}" autocomplete="false">
    </div>

    <mat-form-field class="card_input_title" *ngIf="list_filter">
      <span> Empresa <span class="msg_erro" *ngIf="warningMap.get('company')"> {{
          checkInputMessage(user.input_company,
          'company') }} </span> </span>

      <input matInput class="search" type="text" [(ngModel)]="user.input_company" [style.color]="modeScreen.color"
        [ngStyle]="{'border-color': warningMap.get('company')? 'red' : ''}" (keyup)="getCompany(user.input_company)"
        style="margin-right: 5px; margin-top: 10px;" [matAutocomplete]="autoCompany" readonly
        onfocus="this.removeAttribute('readonly');">

      <mat-autocomplete autoActiveFirstOption #autoCompany="matAutocomplete">
        <mat-option *ngFor="let obj of list_filter" [value]="obj.name" (click)="openScreen(obj)">
          {{ obj?.name ? obj.name : '-' }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field autocomplete="off" class="card_input_title" *ngIf="list_permissions">
      <span> Perfil <span class="msg_erro" *ngIf="warningMap.get('company')"> {{
          checkInputMessage(user.input_permission,
          'company') }} </span> </span>

      <input matInput class="search" type="text" [(ngModel)]="user.input_permission" [style.color]="modeScreen.color"
        [ngStyle]="{'border-color': warningMap.get('company')? 'red' : ''}" (keyup)="getPermissions()"
        style="margin-right: 5px; margin-top: 10px;" [matAutocomplete]="autoPermission" readonly
        onfocus="this.removeAttribute('readonly');">

      <mat-autocomplete autoActiveFirstOption #autoPermission="matAutocomplete">
        <mat-option *ngFor="let obj of list_permissions" [value]="obj.name" (click)="selectPermission(obj)">
          {{ obj?.name ? obj.name : '-' }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="card_input_title" *ngIf="!isEdit">
      <span> Senha <span class="msg_erro" *ngIf="warningMap.get('password')"> {{
          checkInputMessage(user.password,
          'password') }} </span> </span>
      <input matInput type="password" [(ngModel)]="user.password" placeholder="*****"
        [ngStyle]="{'border-color': warningMap.get('password')? 'red' : ''}" autocomplete="false">
    </div>

    <div class="card_input_title" *ngIf="!isEdit">
      <span> Confirmar Senha <span class="msg_erro" *ngIf="warningMap.get('confirm_password')"> {{
          checkInputMessage(user.confirm_password, 'confirm_password', 'password') }} </span> </span>
      <input matInput type="password" [(ngModel)]="user.confirm_password" placeholder="*****"
        [ngStyle]="{'border-color': warningMap.get('confirm_password')? 'red' : ''}" autocomplete="false">
    </div>

    <div class="card_empty"></div>

  </section>
</main>
